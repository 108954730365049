import styled from "styled-components";
export const PageHero = styled("div")`
  position: relative;
`;
export const Wrapper = styled("div")`
  width: 1440px;
  margin: 0 auto;
  height: 100%;
  position: relative;

  @media (max-width: 1440px) {
    width: 90%;
  }
`;
export const Parallax = styled("div")`
  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => props.image});
  width: 100%;
  height: 500px;
  padding-bottom: 40px;
`;
export const Caption = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 70%;
  justify-content: flex-end;
  position: absolute;
  top: -40px;
  color: #fff;
  & h1 {
    margin-bottom: 0;
    width: 70%;
    line-height: 5rem;

    @media (max-width: 1024px) {
      width: 80%;
      line-height: 3rem;
    }
  }
  @media (max-width: 1024px) {
    width: 90%;
  }
`;
export const PageBackground = styled("div")`
  position: relative;
  width: 100%;
  overflow: hidden;

  img {
    position: absolute;
  }

  & .header {
    margin-top: 0;
    color: #07146380;
    position: relative;
    text-align: center;
    &::after {
      position: absolute;
      content: "";
      height: 6px;
      width: 80px;
      bottom: -10px;
      left: 50%;
      background-color: #07146333;
      display: block;
      translate: -50% 0;
    }
  }
  @media (max-width: 1024px) {
    height: auto !important;
  }
`;
export const RowBetween = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin: 80px 0;

  & .wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fff;
    gap: 20px;
    width: 50%;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    padding: 20px 50px;
  }
  & .text {
    color: #071463;
    display: flex;
    flex-direction: column;
    gap: 20px;
    & h4 {
      color: #110e9e;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  & .social {
    & h4 {
      color: #110e9e;
      font-weight: 500;
      margin-bottom: 20px;
      margin-top: 0;
    }
    & a {
      text-decoration: none;
      color: #110e9e;
    }
  }
  & .row {
    display: flex;
    align-items: center;
    gap: 40px;
    color: #110e9e;
  }
  & .navigation {
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    & .item {
      display: flex;
      background: linear-gradient(to top, #110e9e 50%, #fff 50%);
      background-size: 100% 200%;
      background-position: top center;
      transition: background-position 0.3s ease-in-out;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
      justify-content: space-between;
      align-items: center;
      color: #110e9e;
      text-decoration: none;
      font-weight: 500;
      box-sizing: border-box;
      padding: 16px;
      cursor: pointer;

      &:hover {
        background-position: bottom center;
        color: #fff;
      }
    }
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 40px;
    & .wrapper {
      width: 100%;
    }
  }
  @media (max-width: 431px) {
    & .wrapper {
      flex-direction: column !important;
      align-items: center;
      gap: 50px;
    }
  }
`;
export const FlexCards = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin-top: 50px;
  margin-bottom: 50px;

  & .contactCard {
    width: 300px;
    height: 300px;
    box-sizing: border-box;
    padding: 20px;
    position: relative;
    & img {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
    }

    & .header {
      position: relative;
      display: flex;
      flex-direction: column;
      text-align: center;
      &::after {
        position: absolute;
        bottom: 0;
        content: "";
        display: block;
        width: 30px;
        height: 6px;
        background-color: #ffffff66;
      }
      & h4 {
        color: #ffffff;
        margin: 0;
      }
      & h5 {
        font-weight: 400;
        color: #ffffff;
        margin: 0 0 20px 0;
      }
    }
    & .content {
      display: flex;
      flex-direction: column;
      text-align: center;
      color: #fff;
    }
  }
`;
