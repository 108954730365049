import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../../resources/components/timeline.css";
import { ReactComponent as VipaLogo } from "../../resources/icons/vipacorp_large.svg";
import HistoryBG from "../../resources/images/about/history_bg.png";
import Blob from "../../resources/images/calltoaction/blob.svg";
import CircleLeft from "../../resources/images/calltoaction/circle_left.svg";
import CircleRight from "../../resources/images/calltoaction/circle_right.svg";
import Circle from "../../resources/images/hero/circle_dark.svg";
import { BackgroundWrapper, CenteredContent } from "../home/styles/home_styled";
import {
  AboutImageWrap,
  ImageText,
  NavigationBar,
  PageBackground,
  Wrapper,
} from "./styles/aboutStyles";

import CustomTimeline from "./components/timeline/customTimeline";

function History() {
  const small = useMediaQuery("(max-width:768px)");
  const tablet = useMediaQuery("(max-width:1280px)");
  const { t } = useTranslation();

  return (
    <>
      <NavigationBar>
        <Wrapper>
          <div className="navigationrow">
            <Link to={"/about"}>
              <ArrowBackIosIcon />
              {t("who")}
            </Link>
            <Link to={"/about/mission"}>
              {t("mission")}
              <ArrowForwardIosIcon />
            </Link>
          </div>
        </Wrapper>
      </NavigationBar>
      <PageBackground style={{ height: "80vh" }}>
        <AboutImageWrap className="history">
          <img src={HistoryBG} alt="" />
          <VipaLogo />
        </AboutImageWrap>
        <Wrapper>
          <ImageText className="history">
            <div className="textcontent">
              <h2 className="header">{t("history")}</h2>
              <p>{t("history_text1")}</p>
              <p>{t("history_text2")}</p>
            </div>
          </ImageText>
        </Wrapper>
      </PageBackground>
      <PageBackground style={{ minHeight: "65vh", backgroundColor: "#071463" }}>
        <img
          src={Circle}
          alt=""
          style={{
            position: "absolute",
            left: "-10%",
            top: 0,
            zIndex: "0",
            width: small ? "250px" : "500px",
          }}
        />
        <img
          src={Circle}
          alt=""
          style={{
            position: "absolute",
            right: "-5%",
            bottom: "5%",
            zIndex: "0",
            width: small ? "250px" : "500px",
          }}
        />
        <Wrapper>
          <h3 style={{ fontWeight: "500", color: "#fff" }}>A Nossa Timeline</h3>
          <div style={{ height: "50px" }} />
        </Wrapper>
        <Wrapper>
          <CustomTimeline />
        </Wrapper>
      </PageBackground>
      <PageBackground>
        <BackgroundWrapper hide={true} className="calltoaction">
          <img
            src={CircleLeft}
            alt=""
            style={{ top: "-50%", left: "0" }}
            className={"hide"}
          />
          <img
            src={Blob}
            alt=""
            style={{ top: "-10%", left: "10%" }}
            className={"hide"}
          />
          <img src={CircleRight} alt="" style={{ top: "0", right: "0" }} />
          <Wrapper>
            <CenteredContent className="calltoaction">
              <h3 style={{ fontWeight: 500, color: "#fff", fontSize: small ? "24px" : tablet ? "36px" : "42px" }}>
                {t("history_banner")}
              </h3>
            </CenteredContent>
          </Wrapper>
        </BackgroundWrapper>
      </PageBackground>
    </>
  );
}

export default History;
