import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../icons/vipacorp_small_white.svg";
import { useTranslation } from "react-i18next";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import jQuery from "jquery";
const NavigationWrap = styled("nav")`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  left: 0;
  height: 72px;
  background-color: ${(props) => (props.hovered ? "#fff" : "unset")};
  backdrop-filter: blur(5px);
  color: ${(props) => (props.hovered ? "#071463" : "#fff")};
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06);
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
  transition: background-color 0.2s ease-in-out;
`;
const Navigation = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1400px;
  margin: 0 auto;
  height: 100%;
  @media (max-width: 1440px) {
    width: 90vw;
  }
`;
const LinksWrap = styled("div")`
  display: flex;
  align-items: center;
  gap: 24px;
  height: 100%;
  @media (max-width: 1024px) {
    display: ${(props) => (props.mobile ? "flex" : "none")};
    position: absolute;
    top: 72px;
    left: 0;
    gap: unset;
    background-color: #fff;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 24px;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
  }
`;
const Burger = styled("button")`
  display: none;
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;

  @media (max-width: 1024px) {
    display: block;
  }
`;
const LogoWrap = styled("a")`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & svg {
    fill: ${(props) => (props.hovered ? "#071463" : "#fff")};
    transition: 0.2s ease-in-out;
  }
`;
const DropDownMenu = styled("div")`
  position: absolute;
  top: 72px;
  left: 0;
  width: 100vw;
  padding: 6px 24px 10px;
  margin-bottom: 0;
  place-items: start;
  opacity: 0;
  visibility: hidden;
  translate: 0 -24px;
  background-color: #fafafa;
  transition: 0.3s ease-in-out;

  & .grid {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: flex-start;
    gap: 100px;
    box-sizing: border-box;
    padding: 24px 0;

    & p {
      justify-self: flex-end;
      color: #07146380;
      position: relative;
      font-size: 32px;
      margin: 0;
      &::after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: #07146333;
        height: 6px;
        width: 50px;
      }
    }
  }
  & .sublinks {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & a {
      cursor: pointer;
      font-weight: 500;
      text-decoration: none;
      color: #071463;
    }
  }

  @media (max-width: 1024px) {
    translate: 24px 0;
    width: 75vw;
    height: 100vh;
    top: 0;
    left: unset;
    right: 0;
    z-index: 3;
    box-sizing: border-box;
    & .grid {
      grid-template-columns: unset;
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: flex-end;
      & h3 {
        text-align: right;
      }
    }
    & .sublinks {
      align-items: flex-end;
      text-align: right;
    }
  }
`;
const ItemLink = styled("a")`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 100%;
  z-index: 2;
  position: relative;
  font-weight: 500;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  &::after {
    content: "";
    height: 2px;
    width: 0;
    background-color: ${(props) => (props.hovered ? "#071463" : "#fff")};
    transition: width 0.3s ease-in-out;
    position: absolute;
    bottom: 20px;
    left: 0;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }
  @media (max-width: 1024px) {
    height: 50px;

    &::after {
      bottom: 0px;
    }
  }
`;
const DropDown = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;

  &:hover {
    & ${DropDownMenu} {
      opacity: 1;
      visibility: visible;
      translate: 0 0;
    }
  }
  @media (max-width: 1024px) {
    height: 50px;
  }
`;

const ClickableLink = styled("button")`
  display: flex;
  align-items: center;
  color: ${(props) => (props.hovered ? "#071463" : "#fff")};
  background-color: transparent;
  outline: 0;
  border: 0;
  height: 100%;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  padding: 0;
  @media (max-width: 1024px) {
    height: 50px;
  }
`;
const LangWrap = styled("button")`
  display: flex;
  align-items: center;
  color: ${(props) => (props.hovered ? "#071463" : "#fff")};
  background-color: transparent;
  outline: 0;
  border: 0;
  height: 100%;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  padding: 0;
  gap: 20px;
  @media (max-width: 1024px) {
    height: 50px;
  }
  & .langlink {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 100%;
    z-index: 2;
    position: relative;
    font-weight: 500;
    text-decoration: none;
    color: ${(props) => (props.hovered ? "#071463" : "#fff")};
    color: inherit;
    cursor: pointer;
    &.active {
      &::after {
        content: "";
        height: 2px;
        width: 100%;
        background-color: ${(props) => (props.hovered ? "#071463" : "#fff")};
        transition: width 0.3s ease-in-out;
        position: absolute;
        bottom: 20px;
        left: 0;

        @media (max-width: 1024px) {
          bottom: 0px;
        }
      }
    }
  }
`;
const A = styled.a`
  color: var(--secondary);
  font-weight: 500;
  text-decoration: none;
  position: absolute;
  left: -9999px;
  z-index: 999;
  padding: 1em;
  opacity: 0;
  background-color: black;
  color: white;
  &:focus {
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
  }
`;
function NavBar() {
  const [hovered, setHovered] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (
      jQuery(document.location).attr("pathname").length === 1 ||
      jQuery(document.location).attr("pathname").endsWith("about") ||
      jQuery(document.location).attr("pathname").endsWith("government") ||
      jQuery(document.location).attr("pathname").includes("people") ||
      jQuery(document.location).attr("pathname").includes("contacts")
    ) {
      setScrolled(true);
    }
    jQuery(window).scroll(function (event) {
      event.preventDefault();
      var scroll = jQuery(window).scrollTop();
      if (
        scroll > 50 ||
        jQuery(document.location).attr("pathname").includes("portfolio") ||
        jQuery(document.location).attr("pathname").includes("structure") ||
        jQuery(document.location).attr("pathname").includes("about/history") ||
        jQuery(document.location).attr("pathname").includes("about/mission") ||
        jQuery(document.location).attr("pathname").includes("privacy") ||
        jQuery(document.location).attr("pathname").includes("denuncias") ||
        jQuery(document.location).attr("pathname").includes("news") ||
        jQuery(document.location)
          .attr("pathname")
          .includes("about/contribution") ||
        jQuery(document.location)
          .attr("pathname")
          .includes("government/structure") ||
        jQuery(document.location)
          .attr("pathname")
          .includes("government/regulations")
      ) {
        setScrolled(false);
      } else {
        setScrolled(true);
      }
    });
  }, []);
  const initialLang = window.localStorage.getItem("i18nextLng");
  const { t, i18n } = useTranslation();
  const [activeLang, setActiveLang] = useState(
    initialLang === "pt-PT"
      ? window.localStorage.setItem("i18nextLng", "pt")
      : window.localStorage.getItem("i18nextLng")
  );
  const changeLanguage = (lng) => {
    setActiveLang(lng);
    i18n.changeLanguage(lng);
  };
  useEffect(() => {
    if (window.localStorage.getItem("i18nextLng") !== activeLang) {
      changeLanguage(window.localStorage.getItem("i18nextLng"));
    }
    // eslint-disable-next-line
  }, []);
  return (
    <NavigationWrap hovered={hovered || !scrolled}>
      <Navigation>
        <A href="/#content">{t("accessSkip")}</A>
        <LogoWrap
          hovered={hovered || !scrolled}
          alt="logo"
          title="Página inicial"
          href="/"
        >
          <Logo />
        </LogoWrap>
        <Burger onClick={() => setOpen(!open) + setHovered(!hovered)}>
          {!open ? (
            <MenuIcon htmlColor={open || !scrolled ? "#071463" : "#fff"} />
          ) : (
            <CloseIcon htmlColor={open || !scrolled ? "#071463" : "#fff"} />
          )}
        </Burger>
        <LinksWrap mobile={open}>
          <ItemLink href={"/"} hovered={hovered || !scrolled}>
            {t("home")}
          </ItemLink>
          <DropDown
            onMouseEnter={() => !open && setHovered(true)}
            onMouseLeave={() => !open && setHovered(false)}
          >
            <ClickableLink hovered={hovered || !scrolled}>
              {t("about")}
              <ExpandMoreIcon />
            </ClickableLink>
            <DropDownMenu>
              <div className="grid">
                <p>{t("about")}</p>
                <div className="sublinks">
                  <a href="/about">{t("who")}</a>
                  <a href="/about/history">{t("history")}</a>
                  <a href="/about/mission">{t("mission")}</a>
                  <a href="/about/contribution">{t("contribution")}</a>
                </div>
              </div>
            </DropDownMenu>
          </DropDown>
          <DropDown
            onMouseEnter={() => !open && setHovered(true)}
            onMouseLeave={() => !open && setHovered(false)}
          >
            <ClickableLink hovered={hovered || !scrolled}>
              {t("government")}
              <ExpandMoreIcon />
            </ClickableLink>
            <DropDownMenu>
              <div className="grid">
                <p>{t("government")}</p>
                <div className="sublinks">
                  <a href="/government">{t("identity")}</a>
                  <a href="/government/structure">{t("structure")}</a>
                  <a href="/government/regulations">{t("regulation")}</a>
                </div>
              </div>
            </DropDownMenu>
          </DropDown>
          <ItemLink href="/portfolio" hovered={hovered || !scrolled}>
            {t("portfolio")}
          </ItemLink>
          <ItemLink href="/people" hovered={hovered || !scrolled}>
            {t("people")}
          </ItemLink>
          <ItemLink href="/contacts" hovered={hovered || !scrolled}>
            {t("contacts")}
          </ItemLink>
          <LangWrap hovered={hovered || !scrolled}>
            <div
              onClick={() => changeLanguage("pt")}
              className={`${activeLang === "pt" && "active"} langlink`}
            >
              PT
            </div>
            <div
              onClick={() => changeLanguage("en")}
              className={`${activeLang === "en" && "active"} langlink`}
            >
              EN
            </div>
          </LangWrap>
        </LinksWrap>
      </Navigation>
    </NavigationWrap>
  );
}

export default NavBar;
