import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Caption, Wrapper } from "../../routes/home/styles/home_styled";
import HeroBG12 from "../images/hero/hero_bg12.png";
import HeroBG19 from "../images/hero/hero_bg19.png";
import HeroBG21 from "../images/hero/hero_bg21.png";
import HeroBG22 from "../images/hero/hero_bg22.png";
import HeroBG23 from "../images/hero/hero_bg23.png";
import HeroBG25 from "../images/hero/hero_bg25.png";
import HeroBG26 from "../images/hero/hero_bg26.png";
import HeroBG27 from "../images/hero/hero_bg27.png";

const divStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "cover",
  height: "100vh",
  padding: "0 10px",
  width: "100%",
};

const properties = {
  prevArrow: <></>,
  nextArrow: <></>,
};

const DURATION = 10000;

const ProgressBar = ({ fade }) => {
  const [duration, setDuration] = useState(0);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (duration >= DURATION) {
        setDuration(0);
        fade.current.goNext();
      } else {
        setDuration(duration + 100);
      }
    }, 100);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  return (
    <div
      style={{
        position: "absolute",
        width: `${(duration / DURATION) * 100}%`,
        height: "5px",
        backgroundColor: duration === 0 ? "transparent" : "#fff",
        bottom: 0,
        left: 0,
        zIndex: 200,
        transition: "all .1s linear",
      }}
    />
  );
};

function HeroSlider() {
  const { t } = useTranslation();
  const heroes = [
    {
      img: HeroBG25,
      company: "Vipa.pt",
      title: t("hero_vc"),
      text: t("hero_vc_sub"),
      link: "https://vipa.pt",
    },
    {
      img: HeroBG22,
      company: "SeeHealth",
      title: t("hero_sh"),
      text: t("hero_sh_sub"),
      link: "https://www.seehealth.pt",
    },
    {
      company: "Homes With Love",
      img: HeroBG19,
      title: t("hero_hwl"),
      text: t("hero_hwl_sub"),
      link: "https://www.hwl.pt",
    },
    {
      company: "VipaTelecom",
      img: HeroBG21,
      title: t("hero_telecom"),
      text: t("hero_telecom_sub"),
      link: "https://www.vipatelecom.com",
    },
    {
      company: "VCapital",
      img: HeroBG26,
      title: t("hero_vcap"),
      text: t("hero_vcap_sub"),
      link: "https://www.vcapital.pt",
    },
    {
      company: "Vipa International",
      img: HeroBG23,
      title: t("hero_international"),
      text: t("hero_international_sub"),
      link: "https://www.vipainternational.com",
    },
    {
      company: "NMDS",
      img: HeroBG27,
      title: t("hero_nmds"),
      text: t("hero_nmds_sub"),
      link: "https://www.nmds.pt",
    },
    {
      company: "Vipa",
      img: HeroBG12,
      title: t("hero"),
      text: t("hero_sub"),
      link: "",
    },
  ];
  const mobile = useMediaQuery("(max-width:768px)");
  const fade = useRef();

  return (
    <div style={{ position: "relative" }}>
      <Fade
        {...properties}
        autoplay={false}
        infinite
        transitionDuration={1000}
        pauseOnHover={false}
        ref={fade}
      >
        {heroes.map((hero, index) => (
          <div key={index} style={{ height: "100%", width: "100%" }}>
            <div style={{ ...divStyle, backgroundImage: `url(${hero.img})` }}>
              <Wrapper>
                <Caption style={{ width: mobile ? "95%" : "70%" }}>
                  <h1>{hero.company}</h1>
                  <h2
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      fontSize: "2.5rem",
                    }}
                  >
                    {hero.title}
                  </h2>
                  <p style={{ fontSize: mobile ? "1.125rem" : "1.315rem" }}>
                    {hero.text}
                  </p>
                  {hero.link && (
                    <Button
                      className="link-button"
                      href={hero.link}
                      target="_blank"
                      style={{ textTransform: "none" }}
                    >
                      {t("hero_btn")}
                      <ChevronRightIcon />
                    </Button>
                  )}
                </Caption>
              </Wrapper>
            </div>
          </div>
        ))}
      </Fade>
      <ProgressBar fade={fade} />
    </div>
  );
}

export default HeroSlider;
