import React from "react";
import Circle from "../../resources/images/hero/circle.svg";
import ContactsBG from "../../resources/images/contacts/contacts_hero.png";
import madeiraBG from "../../resources/images/contacts/madeira_bg.png";
import acoresBG from "../../resources/images/contacts/acores_bg.png";
import lisboaBG from "../../resources/images/contacts/lisboa_bg.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import CircleLeft from "../../resources/images/calltoaction/circle_left.svg";
import CircleRight from "../../resources/images/calltoaction/circle_right.svg";
import Blob from "../../resources/images/calltoaction/blob.svg";
import {
  Caption,
  FlexCards,
  PageBackground,
  PageHero,
  Parallax,
  RowBetween,
  Wrapper,
} from "./styles/contactsStyle";
import { BackgroundWrapper, CenteredContent } from "../home/styles/home_styled";
import { useTranslation } from "react-i18next";

function Contacts() {
  const { t } = useTranslation();
  return (
    <>
      <PageHero>
        <Parallax image={ContactsBG} />
        <Wrapper>
          <Caption>
            <h1>{t("contacts_hero")}</h1>
          </Caption>
        </Wrapper>
      </PageHero>
      <PageBackground>
        <img
          src={Circle}
          style={{ left: "-30%", top: "20%", width: "70%" }}
          alt=""
        />
        <img src={Circle} style={{ right: "-30%", top: "-200%" }} alt="" />
        <Wrapper>
          <RowBetween>
            <div className="wrapper">
              <div className="text">
                <h4>{t("contacts")}</h4>
                <div className="row">
                  <EmailOutlinedIcon htmlColor="#0714630D" fontSize="large" />
                  <a
                    href="mailto:info@vipacorporation.com"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#071463" }}
                  >
                    info@vipacorporation.com
                  </a>
                </div>
                <div className="row">
                  <LocalPhoneOutlinedIcon
                    htmlColor="#0714630D"
                    fontSize="large"
                  />
                  <a
                    href="tel:(+351) 291 600 300"
                    style={{ textDecoration: "none", color: "#071463" }}
                  >
                    (+351) 291 600 300
                  </a>
                </div>
              </div>
              <div className="social">
                <h4>{t("contacts_social")}</h4>
                <div className="row">
                  <a
                    href="https://www.facebook.com/vipacorporation"
                    target="_blank"
                    rel="noreferrer"
                    title="Rede Social Facebook"
                  >
                    <FacebookIcon fontSize="medium" />
                  </a>
                  <a
                    href="https://www.instagram.com/vipacorporation/"
                    target="_blank"
                    rel="noreferrer"
                    title="Rede Social Instagram"
                  >
                    <InstagramIcon fontSize="medium" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/vipacorp/"
                    target="_blank"
                    rel="noreferrer"
                    title="Rede Social LinkedIn"
                  >
                    <LinkedInIcon fontSize="medium" />
                  </a>
                </div>
              </div>
            </div>
          </RowBetween>
        </Wrapper>
        <Wrapper>
          <h2 className="header">{t("contacts_title")}</h2>
          <FlexCards>
            <div className="contactCard">
              <img src={madeiraBG} alt="" />
              <div className="header">
                <h4>Funchal</h4>
                <h5>Madeira</h5>
              </div>
              <div className="content">
                <p>Rua Dr. Fernão de Ornelas nº56, 4º AC</p>
                <p>9050-021, Funchal R.A.M.</p>
              </div>
            </div>
            <div className="contactCard">
              <img src={acoresBG} alt="" />
              <div className="header">
                <h4>Ponta Delgada</h4>
                <h5>{t("contacts_azores")}</h5>
              </div>
              <div className="content">
                <p> Avenida Infante D. Henrique nº71, porta 130</p>
                <p>9504-529, Ponta Delgada R.A.A.</p>
              </div>
            </div>
            <div className="contactCard">
              <img src={lisboaBG} alt="" />

              <div className="header">
                <h4>{t("contacts_lisbon")}</h4>
                <h5>{t("contacts_portugal")}</h5>
              </div>
              <div className="content">
                <p> Avenida Fontes Pereira de Melo nº16, Palácio Sotto Mayor</p>
                <p>1050-121, Lisboa</p>
              </div>
            </div>
          </FlexCards>
        </Wrapper>
      </PageBackground>
      <PageBackground>
        <BackgroundWrapper hide={true} className="calltoaction">
          <img
            src={CircleLeft}
            alt=""
            style={{ top: "-50%", left: "0" }}
            className={"hide"}
          />
          <img
            src={Blob}
            alt=""
            style={{ top: "-10%", left: "10%" }}
            className={"hide"}
          />
          <img src={CircleRight} alt="" style={{ top: "0", right: "0" }} />
          <Wrapper>
            <CenteredContent className="calltoaction">
              <h3 style={{ fontWeight: 500, color: "#fff" }}>
                {t("contacts_banner")}
              </h3>
            </CenteredContent>
          </Wrapper>
        </BackgroundWrapper>
      </PageBackground>
    </>
  );
}

export default Contacts;
