import styled from "styled-components";
import { Tab, Tabs } from "@mui/material";
export const PageHero = styled("div")`
  position: relative;
`;
export const Wrapper = styled("div")`
  width: 1440px;
  margin: 0 auto;
  height: 100%;
  position: relative;

  @media (max-width: 1440px) {
    width: 90%;
  }
  & .pdf_file {
    color: #110e9e;
    text-decoration: underline;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;

    & svg {
      color: #07146333;
    }
    &:hover {
      color: #ff7f37;
      & svg {
        color: #ff7f37;
      }
    }
  }
`;
export const Parallax = styled("div")`
  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => props.image});
  /* width: 100%; */
  height: 400px;
  padding-bottom: 40px;
  @media (max-width: 1024px) {
    height: 300px;
  }
`;
export const Caption = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: flex-end;
  position: absolute;
  top: -40px;
  color: #fff;
  & h1 {
    margin-bottom: 0;
    line-height: 5rem;

    @media (max-width: 1024px) {
      width: 80%;
      line-height: 3rem;
    }
  }
`;
export const PageBackground = styled("div")`
  position: relative;
  width: 100%;
  overflow: hidden;

  img {
    position: absolute;
  }

  & .header {
    margin-top: 0;
    color: #07146380;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      height: 6px;
      width: 80px;
      bottom: -10px;
      left: 0;
      background-color: #07146333;
      display: block;
    }
  }
  @media (max-width: 1024px) {
    height: auto !important;
  }
`;
export const RowBetween = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin: 80px 0;
  gap: 40px;

  & .text {
    color: #071463;
    background-color: #fff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    padding: 20px 50px;
    width: 60%;
  }
  & .navigation {
    width: 35%;
    max-width: 540px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    & .item {
      display: flex;
      background: linear-gradient(to top, #110e9e 50%, #fff 50%);
      background-size: 100% 200%;
      background-position: top center;
      transition: background-position 0.3s ease-in-out;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
      justify-content: space-between;
      align-items: center;
      color: #110e9e;
      text-decoration: none;
      font-weight: 500;
      box-sizing: border-box;
      padding: 16px;
      cursor: pointer;

      &:hover {
        background-position: bottom center;
        color: #fff;
      }
    }
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 40px;
    & .text {
      width: 100%;
    }
    & .navigation {
      width: 100%;
      max-width: unset;
    }
  }
`;
export const StructureWrapper = styled("div")`
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  & img {
    position: relative;
    object-fit: contain;
    width: 100%;
  }
  @media (max-width: 431px) {
    height: auto;
    padding-bottom: 40px;
    & img {
      height: 100%;
    }
  }
`;

export const StructureMobileWrapper = styled("div")`
  width: 100%;
  height: 100vh;
  max-width: 100%;
  overflow-x: scroll;
  height: fit-content;
`;
export const TabsWrapper = styled(Tabs)`
  display: flex;
  border-bottom: 1px solid #110e9e1a;
  & .MuiTabs-indicator {
    display: none;
  }
`;
export const TabItem = styled(Tab)`
  display: flex;
  align-items: left;
  justify-content: center;
  color: #071463 !important;
  font-weight: 600;
  text-transform: none !important;
  max-width: unset !important;

  &.Mui-selected {
    color: #ff7f37 !important;
  }

  @media (max-width: 768px) {
    max-width: 300px !important;
  }
`;
export const TabContent = styled("div")`
  width: 70%;
  & .iconwrap {
    display: flex;
    gap: 20px;
    margin: 40px 0;
    & img {
      position: relative;
      width: 130px;
      height: 130px;
    }
  }
  & h4 {
    color: #110e9e;
    font-weight: 500;
  }
  & p {
    color: #071463;
    text-align: justify;
  }
  & .pdf_file {
    margin-bottom: 10px;
    color: #110e9e;
    text-decoration: underline;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;

    & svg {
      color: #07146333;
    }
    &:hover {
      color: #ff7f37;
      & svg {
        color: #ff7f37;
      }
    }
  }

  @media (max-width: 431px) {
    width: 100%;
  }
`;
export const ChartLegend = styled("div")`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-center;
  align-self: center;
  white-space: nowrap;

  & .square {
    width: 24px;
    height: 24px;
    background-color: #071463;
    border-radius: 3px;
  }

  @media (max-width: 431px) {
    align-self: center;
  }
`;
export const Slider = styled("div")`
  position: relative;
  width: 100%;
  margin: 50px auto 0 auto;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  & img {
    width: unset;
    height: unset;
    object-fit: cover;
  }
`;
export const StructureHeader = styled("h2")`
  color: #ffffff80;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    height: 6px;
    width: 80px;
    bottom: -10px;
    left: 0;
    background-color: #ffffff33;
    display: block;
  }
`;
export const OverlaySlider = styled("div")`
  position: relative;
  width: 100%;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100px;
    height: 100%;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(7, 20, 99, 0) 100%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(7, 20, 99, 0) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(7, 20, 99, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#071463",GradientType=1);
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    width: 50px;
    height: 100%;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(
      -90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(7, 20, 99, 0) 100%
    );
    background: -webkit-linear-gradient(
      -90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(7, 20, 99, 0) 100%
    );
    background: linear-gradient(
      -90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(7, 20, 99, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#071463",GradientType=1);
  }
`;
