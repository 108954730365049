import { BrandComponent, LogoBox, TextColumn } from "./timelineStyles";

function CustomBrand({ imgSrc, brandName, brandSub, link }) {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <BrandComponent>
        <LogoBox>
          <img src={imgSrc} alt="" />
        </LogoBox>
        <TextColumn>
          <div
            style={{
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "10.64px",
              fontWeight: 700,
              lineHeight: "21.28px" /* 200% */,
            }}
          >
            {brandName}
          </div>
          <div
            style={{
              color: "#FFFFFF80",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "8.867px",
              fontWeight: 500,
              lineHeight: "10.64px" /* 200% */,
            }}
          >
            {brandSub}
          </div>
        </TextColumn>
      </BrandComponent>
    </a>
  );
}

export default CustomBrand;
