import { Tab, Tabs } from "@mui/material";
import styled from "styled-components";
import { Accordion } from "@mui/material";
export const PoliticsWrapper = styled("div")`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
  & .content {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    @media (min-width: 1024px) {
      flex-basis: 70%;
    }

    & h4 {
      color: #8389b1;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
`;
export const TabContent = styled("div")`
  & .iconwrap {
    display: flex;
    gap: 20px;
    margin: 40px 0;
    & img {
      position: relative;
      width: 130px;
      height: 130px;
    }
  }
  & h4 {
    color: #110e9e;
    font-weight: 500;
  }
  & p {
    color: #071463;
    text-align: justify;
  }
  & .pdf_file {
    margin-bottom: 10px;
    color: #110e9e;
    text-decoration: underline;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;

    & svg {
      color: #07146333;
    }
    &:hover {
      color: #ff7f37;
      & svg {
        color: #ff7f37;
      }
    }
  }

  @media (max-width: 431px) {
    width: 100%;
  }
`;
export const SideBar = styled(Tabs)`
  flex-basis: 100%;
  gap: 20px;
  @media (min-width: 1024px) {
    flex-basis: 30%;
  }
  & .MuiTabs-flexContainer {
    display: flex;
    flex-direction: row;
    align-items: left;
    text-align: left;
    flex-wrap: wrap;
    justify-content: center;
    @media (min-width: 1024px) {
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
  }
  & .MuiTab-root {
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
  }
  & .MuiTabs-indicator {
    display: none;
  }
  & .Mui-selected {
  }
`;
export const TabItem = styled(Tab)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  color: #071463 !important;
  font-weight: 600 !important;
  text-transform: none !important;
  max-width: unset !important;

  &.Mui-selected {
    color: #110e9e !important;
  }

  @media (max-width: 768px) {
    max-width: 300px !important;
  }
`;
export const CustomAccordion = styled(Accordion)`
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.04) !important;
  color: #110e9e !important;
  font-weight: 500;
  font-size: 24px;
  margin-top: 20px;
  &::before {
    display: none;
  }
  & .Mui-expanded {
    background-color: #110e9e;
    & p {
      color: #fff;
      font-weight: 500;
    }
    & .MuiAccordionSummary-expandIconWrapper {
      color: #fff;
    }
  }
  & .MuiAccordionDetails-root {
    & li {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
    }
    & p {
      font-size: 18px;
    }
  }

  & .MuiCollapse-wrapper {
    background-color: #110e9e;
    & p {
      color: #fff;
    }
  }
`;
export const FlexContainer = styled("div")`
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
`;
export const CustomInput = styled("div")`
  background-color: #fff;
  border-radius: 4px;
  min-height: 45px;
  border: 0.792793px solid #ededed;
  width: 100%;

  & input,
  select {
    outline: 0;
    border: 0;
    height: 45px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;

    &:focus {
      border: 0.792793px solid rgba(7, 20, 99, 0.5);
      box-shadow: 0px 0px 6px rgba(7, 20, 99, 0.5);
    }
    &::placeholder {
      color: #a0a8b1;
    }
  }
  & textarea {
    width: 100%;
    resize: none;
    border: 0;
    outline: 0;
    padding: 10px 20px;
    box-sizing: border-box;
    &:focus {
      border: 0.792793px solid rgba(7, 20, 99, 0.5);
      box-shadow: 0px 0px 6px rgba(7, 20, 99, 0.5);
    }
    &::placeholder {
      color: #a0a8b1;
    }
  }
`;
export const CustomButton = styled("button")`
  width: 80%;
  margin: 0 auto;
  background-color: #110e9e;
  min-height: 70px;
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin-top: 40px;
  cursor: pointer;
  @media (min-width: 1024px) {
    width: 40%;
  }
`;
export const MainHeader = styled("h3")`
  color: #07146380;
  text-align: center;
  position: relative;
  font-size: 64px;
  font-weight: 800;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: -20px;
    translate: -50% 0;
    background-color: #07146333;
    height: 10px;
    width: 100px;
  }
`;
export const CustomRadioButton = styled("div")`
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
  & input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    &:checked {
      & ~ .radio {
        background-color: #071463;

        &::after {
          display: block;
          left: 50%;
          top: 50%;
          translate: -50% -50%;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #fff;
        }
      }
    }
  }

  & .radio {
    position: relative;
    display: block;
    background-color: #fff;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(7, 20, 99, 0.2);
    border-radius: 50%;
    box-sizing: border-box;

    &::after {
      content: "";
      position: absolute;
      display: none;
    }
  }
`;
export const CustomCheckbox = styled("div")`
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
  & input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    &:checked {
      & ~ .checkbox {
        background-color: #071463;

        &::after {
          display: block;
          left: 4px;
          top: 2px;
          width: 4px;
          height: 7px;
          border: solid white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }

  & .checkbox {
    position: relative;
    display: block;
    background-color: #fff;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(7, 20, 99, 0.2);
    border-radius: 1px;
    box-sizing: border-box;

    &::after {
      content: "";
      position: absolute;
      display: none;
    }
  }
`;
