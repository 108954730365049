import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../icons/vipacorp.svg";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
const FooterBackground = styled("div")`
  background-color: #fff;
  text-align: center;
`;
const Wrapper = styled("div")`
  width: 1400px;
  margin: 0 auto;
  height: 100%;
  @media (max-width: 1440px) {
    width: 90%;
  }
`;
const Centered = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0 20px 0;
  color: #071463;
  font-weight: 500;

  & a {
    color: #071463;
    text-decoration: none;
  }
`;
const Row = styled("div")`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;

  & svg {
    font-size: 32px;
  }
  &.politic {
    margin: 20px 0;
    & a {
      font-weight: 400;
    }
  }
  @media (max-width: 428px) {
    &.social {
      flex-direction: row;
    }
    flex-direction: column;
    & h1 {
      line-height: 2rem;
    }
  }
`;
const Divider = styled("div")`
  width: 100%;
  height: 1px;
  background-color: #110e9e;
  opacity: 10%;

  &.vertical {
    height: 16px;
    width: 2px;
    background-color: #110e9e;
    opacity: 10%;
    @media (max-width: 428px) {
      display: none;
    }
  }
`;
function Footer() {
  const { t } = useTranslation();
  return (
    <>
      <FooterBackground>
        <Wrapper>
          <Centered>
            <Logo />
            <p>Creating bridges, building the future.</p>
            <Row className="social">
              <a
                alt="facebook"
                title="Rede Social Facebook"
                href="https://www.facebook.com/vipacorporation"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon />
              </a>
              <a
                alt="instagram"
                title="Rede Social Instagram"
                href="https://www.instagram.com/vipacorporation/"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon />
              </a>
              <a
                alt="linkedin"
                title="Rede Social Linkedin"
                href="https://www.linkedin.com/company/vipacorp/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon />
              </a>
            </Row>
            <Divider />
            <Row className="politic">
              <Link to={"/privacy?tab=privacy"}>{t("footer_privacy")}</Link>
              <Divider className="vertical" />
              <Link to={"/privacy?tab=terms"} p>
                {t("footer_terms")}
              </Link>
              <Divider className="vertical" />
              <a
                href="https://www.livroreclamacoes.pt/Inicio/"
                target="_blank"
                rel="noreferrer"
              >
                {t("footer_complaints")}
              </a>
              <Divider className="vertical" />
              <Link to={"/denuncias"} p>
                {t("footer_reports")}
              </Link>
            </Row>
            <Divider />
            <small style={{ marginTop: "20px" }}>
              © {moment().format("YYYY")} {t("footer_rights")}
            </small>
          </Centered>
        </Wrapper>
      </FooterBackground>
    </>
  );
}

export default Footer;
