import React from "react";
import { useTranslation } from "react-i18next";
import Blob from "../../resources/images/calltoaction/blob.svg";
import CircleLeft from "../../resources/images/calltoaction/circle_left.svg";
import CircleRight from "../../resources/images/calltoaction/circle_right.svg";
import Circle from "../../resources/images/hero/circle.svg";
import { CenteredContent } from "../home/styles/home_styled";
import { Card } from "./components/card";
import {
  BackgroundSecondaryWrapper,
  BackgroundWrapper,
  Caption,
  FlexCards,
  PortfolioWrapper,
  VideoWrapper,
  Wrapper,
} from "./styles/portfolioStyles";
import FileCopyIcon from "@mui/icons-material/FileCopy";

function Portfolio() {
  const { t } = useTranslation();

  return (
    <>
      <BackgroundWrapper
        className="team video"
        style={{ minHeight: "80vh", backgroundColor: "#071463" }}
      >
        <VideoWrapper>
          <video
            src={
              "https://vipacorp-docs.s3.eu-central-1.amazonaws.com/vipacorpstill.mp4"
            }
            autoPlay
            muted
            loop
          />
        </VideoWrapper>
        <Wrapper>
          <Caption>
            <h2
              style={{
                color: "#ffffff80",
              }}
            >
              {t("portfolio_hero")}
            </h2>
            <p style={{ color: "#fff" }}>{t("portfolio_hero_sub1")}</p>
            <p style={{ color: "#fff" }}>{t("portfolio_hero_sub2")}</p>
            <p style={{ color: "#fff" }}>{t("portfolio_hero_sub3")}</p>
          </Caption>
        </Wrapper>
      </BackgroundWrapper>
      <PortfolioWrapper>
        <Wrapper
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2 className="header">{t("portfolio_title")}</h2>
          <div>
            <FlexCards>
              <Card company={"vipaconnect"} />
              <Card company={"vipalab"} />
              <Card company={"vipasky"} />
              <Card company={"seehealth"} />
              <Card company={"biomask"} />
              <Card company={"vipaenergy"} />
              <Card company={"pneuzarco"} />
              <Card company={"ilhapneu"} />
              <Card company={"vipainternational"} />
              <Card company={"nmds"} />
              <Card company={"poder"} />
              <Card company={"vipatelecom"} />
              <Card company={"anthony"} />
              <Card company={"hwl"} />
              <Card company={"vcapital"} />
              <Card company={"bioreboot"} />
            </FlexCards>
          </div>
        </Wrapper>
      </PortfolioWrapper>
      <BackgroundWrapper style={{ overflow: "hidden", marginTop: 0 }}>
        <img
          src={Circle}
          alt=""
          style={{
            position: "absolute",
            top: "-30%",
            right: "-20%",
            height: "700px",
          }}
        />
        <Wrapper>
          <h2 className="header">{t("portfolio_title2")}</h2>
          <FlexCards>
            <Card company={"madeirasafe"} />
            <Card company={"govgo"} />
            <Card company={"vipainvoice"} />
            <Card company={"vipainfinity"} />
            <Card company={"inventario"} />
            <Card company={"eventplace"} />
          </FlexCards>
        </Wrapper>
      </BackgroundWrapper>
      <BackgroundSecondaryWrapper>
        <Wrapper
          style={{
            display: "flex",
            margin: "0 auto",
            flexDirection: "column",
          }}
        >
          <h2 className="header">{t("portfolio_title3")}</h2>
          <p
            style={{
              textAlign: "center",
              color: "#FFF",
              maxWidth: "1100px",
              margin: "0 auto",
            }}
          >
            {t("portfolio_r&d_txt")}
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "0 auto",
            }}
          >
            <FlexCards
              style={{
                width: "fit-content",
                marginBottom: "20px",
              }}
            >
              <Card company={"myehealth"} />
              <Card company={"seeapp"} />
              <Card company={"biomaskapp"} />
              <Card company={"safe"} />
            </FlexCards>
            <div
              style={{
                width: "100%",
              }}
            >
              <p style={{ color: "#FFFFFF" }}>{t("r&d_pdf_check")}</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <FileCopyIcon style={{ color: "#FFFFFF33" }} />
                <a
                  href="https://vipacorp-docs.s3.eu-central-1.amazonaws.com/projects.pdf"
                  rel="noreferrer"
                  target="_blank"
                >
                  <p
                    style={{
                      color: "#FFFFFF",
                      textDecoration: "underline",
                      margin: 0,
                    }}
                  >
                    {t("r&d_file_name")}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </Wrapper>
      </BackgroundSecondaryWrapper>
      <BackgroundWrapper
        hide={true}
        className="calltoaction"
        style={{ marginTop: "unset" }}
      >
        <img
          src={CircleLeft}
          alt=""
          style={{ top: "-50%", left: "0" }}
          className={"hide"}
        />
        <img
          src={Blob}
          alt=""
          style={{ top: "-10%", left: "10%" }}
          className={"hide"}
        />
        <img src={CircleRight} alt="" style={{ top: "0", right: "0" }} />
        <Wrapper>
          <CenteredContent className="calltoaction">
            <h3 style={{ fontWeight: 500, color: "#fff" }}>
              {t("portfolio_banner")}
            </h3>
          </CenteredContent>
        </Wrapper>
      </BackgroundWrapper>
    </>
  );
}

export default Portfolio;
