import { Button, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import {
  NewsCaption,
  NewsImages,
  Wrapper,
} from "../../routes/home/styles/home_styled";
// import { ReactComponent as AppleStore } from "../images/functionals/apple-store.svg";
import { ReactComponent as NextArrow } from "../images/functionals/next-arrow.svg";
// import { ReactComponent as PlayStore } from "../images/functionals/play-store.svg";
import { ReactComponent as PrevArrow } from "../images/functionals/prev-arrow.svg";
import CSMaritimoBg from "../images/news/maritimo-bg.svg";
import MaritimoImg from "../images/news/maritimo-img.png";
import PatrigestBg from "../images/news/patrigest-bg.svg";
import PatrigestImg3 from "../images/news/patrigest-img3.png";
import GovGoImage1 from "../images/news/GovGoImage1.png";
import GovGoImage2 from "../images/news/GovGoImage2.png";
import VipaInvoiceImage from "../images/news/VipaInvoiceImage.png";
import { ReactComponent as PatrigestLogo } from "../images/news/patrigest-logo.svg";
import { ReactComponent as GovGoLogo } from "../images/news/GovGoLogo.svg";
import { ReactComponent as VipaInvoiceLogo } from "../images/news/VipaInvoiceLogo.svg";
import { ChevronRight } from "@mui/icons-material";

const divStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  height: "100%",
};

const titleStyle = {
  maritimo: {
    color: "#26CA90",
    fontFamily: "Poppins",
    fontSize: "2.3rem",
    fontWeight: 800,
    textTransform: "uppercase",
    mobile: {
      color: "#26CA90",
      fontFamily: "Poppins",
      fontSize: "23px",
      fontWeight: 800,
      textTransform: "uppercase",
      textAlign: "center",
      lineHeight: "unset",
    },
  },
  patrigest: {
    color: "#FFFEFE",
    fontFamily: "Poppins",
    fontSize: "2.3rem",
    fontWeight: 600,
    maxWidth: "80%",
    mobile: {
      color: "#FFFEFE",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 600,
      maxWidth: "80%",
      textAlign: "center",
      lineHeight: "unset",
    },
  },
};

const subtitleStyle = {
  color: "#CCFFED",
  fontFamily: "Poppins",
  fontSize: "1.7rem",
  fontWeight: 800,
  textTransform: "uppercase",
  mobile: {
    color: "#CCFFED",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 800,
    textTransform: "uppercase",
    textAlign: "center",
  },
};

const textStyle = {
  maritimo: {
    color: "#FFF",
    fontFamily: "Figtree",
    fontSize: "1.5rem",
    fontWeight: 400,
    maxWidth: "80%",
    marginTop: "20px",
    mobile: {
      color: "#FFF",
      fontFamily: "Figtree",
      fontSize: "0.795rem",
      fontWeight: 400,
      maxWidth: "80%",
      marginTop: "20px",
      textAlign: "center",
    },
  },
  patrigest: {
    color: "#FFF",
    fontFamily: "Poppins",
    fontSize: "1.5rem",
    fontWeight: 300,
    marginTop: "30px",
    maxWidth: "80%",
    mobile: {
      color: "#FFF",
      fontFamily: "Poppins",
      fontSize: "0.795rem",
      fontWeight: 300,
      marginTop: "30px",
      maxWidth: "80%",
      textAlign: "center",
    },
  },
};

const sloganStyle = {
  color: "#CCFFED",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "18px",
  fontWeight: 800,
  textTransform: "uppercase",
  mobile: {
    color: "#CCFFED",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 800,
    textTransform: "uppercase",
  },
};

const buttonKnowMore = {
  color: "#4F81EC",
  backgroundColor: "#fff",
  width: "fit-content",
  borderRadius: "59px",
  padding: "12px 24px",
  fontFamily: "Figtree",
  fontSize: "16px",
  fontWeight: 700,
  marginTop: "20px",
  textTransform: "none",
  marginBottom: "15px",
};

const Patrigest = ({ newSlide }) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery("(max-width:768px)");
  return (
    <div
      style={{
        display: "flex",
        flexDirection: mobile ? "column" : "row",
        alignItems: mobile ? "" : "center",
        position: "relative",
      }}
    >
      {!mobile && (
        <PatrigestLogo
          style={{
            width: "220px",
            position: "absolute",
            right: 0,
            top: 0,
          }}
        />
      )}
      {!mobile && (
        <img
          alt="patrigest"
          src={PatrigestImg3}
          style={{
            width: "500px",
            position: "absolute",
            right: "10%",
            bottom: "-30%",
          }}
        />
      )}

      <NewsCaption>
        {mobile && <PatrigestLogo style={{ width: "100px" }} />}
        <h1 style={mobile ? titleStyle.patrigest.mobile : titleStyle.patrigest}>
          {newSlide.title}
        </h1>
        <h2 style={mobile ? subtitleStyle.mobile : subtitleStyle}>
          {newSlide.subtitle}
        </h2>
        <p style={mobile ? textStyle.patrigest.mobile : textStyle.patrigest}>
          {newSlide.text}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "17px",
          }}
        ></div>
        {!mobile ? (
          <Button
            style={{ ...buttonKnowMore }}
            href={newSlide.link}
            target="_blank"
          >
            {t("hero_btn")}
            <ChevronRight />
          </Button>
        ) : (
          ""
        )}
      </NewsCaption>
      <NewsImages>
        {mobile && newSlide.id === "patrigest" && (
          <img
            src={PatrigestImg3}
            alt="Govgo"
            style={{
              objectFit: "contain",
              width: "80%",
              maxHeight: "200px",
              position: "relative",
            }}
          />
        )}
        {mobile ? (
          <Button
            style={{ ...buttonKnowMore }}
            href={newSlide.link}
            target="_blank"
          >
            {t("hero_btn")}
            <ChevronRight />
          </Button>
        ) : (
          ""
        )}
      </NewsImages>
    </div>
  );
};

const Govgo = ({ newSlide }) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery("(max-width:768px)");
  const tablet = useMediaQuery("(max-width:1280px)");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: mobile ? "column" : "row",
        alignItems: mobile ? "" : "center",
        position: "relative",
      }}
    >
      {!mobile && (
        <GovGoLogo
          style={{
            width: "220px",
            position: "absolute",
            right: 0,
            top: 0,
          }}
        />
      )}
      {!mobile && (
        <img
          alt="govgo"
          src={tablet ? GovGoImage2 : GovGoImage1}
          style={{
            width: tablet ? "50%" : "760px",
            position: "absolute",
            right: "5%",
            bottom: "10%",
            zIndex: 1,
          }}
        />
      )}

      <NewsCaption style={{ zIndex: 10 }}>
        {mobile && <GovGoLogo style={{ width: "100px" }} />}
        <h1 style={mobile ? titleStyle.patrigest.mobile : titleStyle.patrigest}>
          {newSlide.title}
        </h1>
        <h2 style={mobile ? subtitleStyle.mobile : subtitleStyle}>
          {newSlide.subtitle}
        </h2>
        <p style={mobile ? textStyle.patrigest.mobile : textStyle.patrigest}>
          {newSlide.text}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "17px",
          }}
        ></div>
        {!mobile ? (
          <Button
            style={{ ...buttonKnowMore }}
            href={newSlide.link}
            target="_blank"
          >
            {t("hero_btn")}
            <ChevronRight />
          </Button>
        ) : (
          ""
        )}
      </NewsCaption>
      <NewsImages>
        {mobile && (
          <img
            src={GovGoImage1}
            alt="Govgo"
            style={{
              objectFit: "contain",
              width: "80%",
              position: "relative",
              maxHeight: "200px",
            }}
          />
        )}
        {mobile ? (
          <Button
            style={{ ...buttonKnowMore }}
            href={newSlide.link}
            target="_blank"
          >
            {t("hero_btn")}
            <ChevronRight />
          </Button>
        ) : (
          ""
        )}
      </NewsImages>
    </div>
  );
};

const CSMaritimo = ({ newSlide }) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery("(max-width:768px)");
  return (
    <div
      style={{
        display: "flex",
        flexDirection: mobile ? "column" : "row",
        alignItems: mobile ? "" : "center",
        position: "relative",
      }}
    >
      {!mobile && newSlide.id === "cs_maritimo" && (
        <img
          alt="maritimo"
          src={MaritimoImg}
          style={{
            width: "450px",
            position: "absolute",
            right: 0,
            bottom: "10%",
          }}
        />
      )}
      {!mobile && newSlide.id === "cs_maritimo" && (
        <div
          style={{
            width: "400px",
            position: "absolute",
            right: 0,
            bottom: "10%",
          }}
        >
          <div style={mobile ? sloganStyle.mobile : sloganStyle}>
            {t("cs_maritimo_slogan")}
          </div>
        </div>
      )}
      <NewsCaption>
        <h1 style={mobile ? titleStyle.maritimo.mobile : titleStyle.maritimo}>
          {newSlide.title}
        </h1>
        <h2
          style={{
            marginTop: 0,
            ...(mobile ? subtitleStyle.mobile : subtitleStyle),
          }}
        >
          {newSlide.subtitle}
        </h2>
        <p style={mobile ? textStyle.maritimo.mobile : textStyle.maritimo}>
          {newSlide.text}
        </p>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "17px",
          }}
        >
          {!mobile ? (
            <div style={mobile ? sloganStyle.mobile : sloganStyle}>
              {t("app_store")}
            </div>
          ) : (
            ""
          )}
          {!mobile ? (
            <div
              style={{
                display: "flex",
                gap: mobile ? "5px" : "20px",
                maxWidth: "250px",
              }}
            >
              <AppleStore />
              <PlayStore />
            </div>
          ) : (
            ""
          )}
        </div> */}
        {!mobile ? (
          <Button
            style={{ ...buttonKnowMore }}
            href={newSlide.link}
            target="_blank"
          >
            {t("hero_btn")}
            <ChevronRight />
          </Button>
        ) : (
          ""
        )}
      </NewsCaption>
      <NewsImages>
        {mobile && (
          <img
            src={MaritimoImg}
            alt="maritimo"
            style={{
              objectFit: "contain",
              width: "80%",
              maxHeight: "200px",
              position: "relative",
            }}
          />
        )}
        {mobile && (
          <div style={mobile ? sloganStyle.mobile : sloganStyle}>
            {t("cs_maritimo_slogan")}
          </div>
        )}
        {mobile ? (
          <Button
            style={{ ...buttonKnowMore }}
            href={newSlide.link}
            target="_blank"
          >
            {t("hero_btn")}
            <ChevronRight />
          </Button>
        ) : (
          ""
        )}
        {/* {mobile ? (
          <div style={mobile ? sloganStyle.mobile : sloganStyle}>
            {t("app_store")}
          </div>
        ) : (
          ""
        )}
        {mobile ? (
          <div
            style={{
              display: "flex",
              gap: mobile ? "5px" : "20px",
              maxWidth: "250px",
            }}
          >
            <AppleStore />
            <PlayStore />
          </div>
        ) : (
          ""
        )} */}
      </NewsImages>
    </div>
  );
};

const VipaInvoice = ({ newSlide }) => {
  const mobile = useMediaQuery("(max-width:768px)");
  const tablet = useMediaQuery("(max-width:1280px)");
  return (
    <div
      style={{
        display: "flex",
        flexDirection: mobile ? "column" : "row",
        alignItems: mobile ? "" : "center",
        position: "relative",
      }}
    >
      {!mobile && (
        <VipaInvoiceLogo
          style={{
            width: "220px",
            position: "absolute",
            right: 0,
            top: 0,
          }}
        />
      )}
      {!mobile && (
        <img
          alt="vipa invoice"
          src={VipaInvoiceImage}
          style={{
            width: tablet ? "55%" : "700px",
            position: "absolute",
            right: tablet ? "0%" : "5%",
            bottom: tablet ? "10%" : "-30%",
            zIndex: 1,
          }}
        />
      )}
      <NewsCaption>
        {mobile && (
          <VipaInvoiceLogo style={{ width: "180px", marginBottom: "15px" }} />
        )}
        <h1 style={mobile ? titleStyle.patrigest.mobile : titleStyle.patrigest}>
          {newSlide.title}
        </h1>
        <p style={mobile ? textStyle.patrigest.mobile : textStyle.patrigest}>
          {newSlide.subtitle} <br /> {newSlide.text}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "17px",
          }}
        ></div>
        {/* {!mobile ? (
          <Button
            style={{ ...buttonKnowMore }}
            href={newSlide.link}
            target="_blank"
          >
            {t("hero_btn")}
            <ChevronRight />
          </Button>
        ) : (
          ""
        )} */}
      </NewsCaption>
      <NewsImages>
        {mobile && (
          <img
            src={VipaInvoiceImage}
            alt="Govgo"
            style={{
              objectFit: "contain",
              width: "80%",
              maxHeight: "200px",
              position: "relative",
            }}
          />
        )}
        {/* {mobile ? (
          <Button
            style={{ ...buttonKnowMore }}
            href={newSlide.link}
            target="_blank"
          >
            {t("hero_btn")}
            <ChevronRight />
          </Button>
        ) : (
          ""
        )} */}
      </NewsImages>
    </div>
  );
};

function NewsSlider() {
  const { t } = useTranslation();
  const mobile = useMediaQuery("(max-width:768px)");
  const news = [
    {
      id: "cs_maritimo",
      title: t("cs_maritimo"),
      subtitle: t("cs_maritimo_sub"),
      text: t("cs_maritimo_text"),
      link: "https://www.futsafe.com",
    },
    {
      id: "patrigest",
      title: t("patrigest"),
      subtitle: "",
      text: t("patrigest_text"),
      link: "https://www.patrigest.pt",
    },
    {
      id: "govgo",
      title: t("govgo"),
      subtitle: "",
      text: t("govgo_text"),
      link: "https://www.govgo.pt",
    },
    {
      id: "invoice",
      title: t("invoice"),
      subtitle: t("invoice_text"),
      text: t("invoice_text2"),
      link: "",
    },
  ];

  const buttonStyle = {
    width: mobile ? "30px" : "100px",
    background: "none",
    border: "0px",
    zIndex: 1,
  };

  const properties = {
    prevArrow: (
      <button style={{ ...buttonStyle }}>
        <PrevArrow style={{ width: mobile ? "30px" : "53px" }} />
      </button>
    ),
    nextArrow: (
      <button style={{ ...buttonStyle }}>
        <NextArrow style={{ width: mobile ? "30px" : "53px" }} />
      </button>
    ),
  };
  return (
    <>
      <Slide {...properties}>
        {news.map((newSlide, index) => (
          <div
            key={index}
            style={{
              height: "100%",
              width: "100%",
              zIndex: 0,
              overflow: "hidden",
            }}
          >
            <div
              style={{
                ...divStyle,
                backgroundImage: mobile
                  ? ""
                  : newSlide.id === "cs_maritimo"
                  ? `url(${CSMaritimoBg})`
                  : newSlide.id === "patrigest"
                  ? `url(${PatrigestBg})`
                  : "",
              }}
            >
              <Wrapper
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {newSlide.id === "cs_maritimo" && (
                  <CSMaritimo newSlide={newSlide} />
                )}
                {newSlide.id === "patrigest" && (
                  <Patrigest newSlide={newSlide} />
                )}
                {newSlide.id === "govgo" && <Govgo newSlide={newSlide} />}
                {newSlide.id === "invoice" && (
                  <VipaInvoice newSlide={newSlide} />
                )}
              </Wrapper>
            </div>
          </div>
        ))}
      </Slide>
    </>
  );
}

export default NewsSlider;
