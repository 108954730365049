const news = [
  {
    title: "Sementeiras biodegradáveis valem €25 mil",
    descriptions: [
      "Projeto para substituir tabuleiros e sacos de plástico descartáveis na agricultura, desenvolvido na Madeira, conquista prémio Novo Verde Packaging Enterprise. Inovação no sector das embalagens e resíduos de embalagens é premiada anualmente, com o Expresso como media partner",
      "Thiago Gomes levou as mãos à cabeça, entusiasmado e admirado por ter acabado de ouvir o nome da Bio Reboot como vencedora do Novo Verde Packaging Enterprise Award. Em modo remoto, na ilha da Madeira, o CEO da startup agradeceu o “voto de confiança” dado pelo júri, que reunira pouco antes numa sala de O Clube-Monsanto Secret Spot, em Lisboa, elegendo o projeto assente na transformação de resíduos agrícolas. Com a confiança segue também um cheque de €25 mil.",
      "“Não é só o valor monetário, é também o valor emocional… Fortíssimo”, reagiu, ainda pelo meio de telefonemas de celebração pelo impulso a um projeto iniciado há três anos e que visa utilizar sementeiras biodegradáveis em substituição dos tabuleiros e sacos de plástico descartáveis na agricultura e que concorria com outros três candidatos, que também apresentaram as suas ideias ao júri de mais uma edição dos Packaging Enterprise Awards, que tem o Expresso como media partner e a Ernst & Young como parceiro.",
      "“Sonhávamos com este momento. Estamos numa região [Madeira] com imensas barreiras de acesso. O dinheiro vai ajudar-nos com as questões de patentes, na contratação de recursos e realização de mais testes. O reconhecimento ajudará nos contactos e parcerias”, sublinhou Thiago, que prevê um investimento total de €3 milhões e quer entrar no mercado em 2025.",
      "A Repete apresentou um sistema digital de embalagens reutilizáveis para take-away e recebeu uma menção honrosa do júri, composto por Ricardo Neto, presidente do conselho de administração da Novo Verde, Nuno Lacasta, presidente da Agência Portuguesa do Ambiente (APA), Fernanda Ferreira Dias, diretora-geral da Direção Geral das Atividades Económicas (DGAE), Gonçalo Lobo Xavier, diretor-geral da Associação Portuguesa de Empresas de Distribuição (APED), e Paulo Praça, presidente da Associação para a Gestão de Resíduos (ESGRA).",
      "O evento reuniu ainda representantes e especialistas em sustentabilidade, para conversas e apresentações sob o mote “Uma Nova Visão de Responsabilidade”, com as ambiciosas metas da Comissão Europeia como base: até 2030, todas as embalagens terão de ser reutilizáveis ou recicláveis, além de serem certificadas de acordo com o respetivo destino.",
      "Estiveram presentes Mafalda Mota, chefe de divisão de fluxos específicos e mercado de resíduos do departamento de Resíduos da APA, Carla Pinto, diretora de serviços/sustentabilidade empresarial da DGAE, Carla Velez, secretária-geral da ESGRA, Cristina Câmara, diretora de sustentabilidade da APED, Beatriz Guimarães, sustainability lead da Nestlé, Fernando Ventura, head of efficiency and innovation environmental projects da Jerónimo Martins, Paulo Correia, chief technology officer-R&D and Logoplaste Innovation Lab consulting, Nuno Soares, presidente do conselho de administração da Tratolixo, Nuno Aguiar, diretor técnico da Associação Portuguesa da Indústria de Plásticos (APIP), Davide Ricci, recycling analyst da Plastics Recyclers Europe, e José Eduardo Martins, partner da Abreu Advogados, que deixou o alerta: “Não há escala ou racionalidade para fazer reciclagem a sério em Portugal. É preciso estimular a concorrência, a eficiência, a confiança do consumidor…”",
    ],
    date: "21/11/2023",
    author: "Expresso",
    link: "https://expresso.pt/sustentabilidade-e-embalagem/2023-11-21-Sementeiras-biodegradaveis-valem-25-mil-09ed678c",
    img: require("./images/news/bioreboot.webp"),
  },
  {
    title: "Criando pontes, construindo o futuro com o C.S.Marítimo",
    descriptions: [
      "O Grupo Vipa está empolgado por partilhar que uniu sinergias com o Clube Sport Marítimo para moldar o futuro da tecnologia no futebol. Esta parceria estratégica promete elevar o clube a novos patamares, através da combinação do know-how de ambos para criar uma solução tecnológica que oferecerá uma experiência desportiva aos sócios do verde-rubro.",
    ],
    img: require("./images/news/csmaritimo_vipa.png"),
    date: "01/10/2023",
    author: "VIPA FACEBOOK",
    link: "https://www.facebook.com/story.php?story_fbid=pfbid096AeewaCeXHG4aVPPDRwmzAf1UejNf2525TzpcbPsvWeJe1mfwwMf8rA353mD3EMl&id=100045126561875",
  },
  {
    title:
      "Centro da banana e UMa na produção de máscaras faciais através da fibra do fruto",
    descriptions: [
      "Os secretários regionais da Agricultura e Desenvolvimento Regional e da Economia estão, hoje, no BAM (Centro da Banana da Madeira), localizado no Lugar de Baixo, onde assistem à apresentação do Biomask, um projeto que visa a criação de máscaras de proteção facial de utilização única, através de fibra da Madeira e com recurso à electospinning. Máscaras biodegradáveis. Um projeto da ARDITI.",
    ],
    img: require("./images/news/gesba.png"),
    date: "29/06/2023",
    author: "JM MADEIRA",
    link: "https://www.jm-madeira.pt/regiao/ver/211069/Centro_da_Banana_e_UMa_apostam_na_producao_de_mascaras_faciais_atraves_da_fibra_do_fruto",
  },
  {
    title:
      "SAFE - Football Entrance será implementado nas instalações do Marítimo",
    descriptions: [
      "A Vipa Connect avança com projeto piloto no Club Sport Marítimo.",
      "A empresa que atua no setor da tecnologia vai implementar um projeto nas instalações do Club Sport Marítimo para monitorizar o acesso dos adeptos ao estádio.",
      "O projeto SAFE - Football Entrance tem como objetivo monitorizar o acesso dos adeptos ao estádio de futebol perante as mais diversas e emergentes doenças infeciosas e, em simultâneo, maximizar a (...).",
    ],
    img: require("./images/news/safe.png"),
    date: "08/02/2023",
    author: "JM MADEIRA",
    link: "https://www.jm-madeira.pt/desporto/ver/198043/SAFE-Football_Entrance_sera_implementado_nas_instalacoes_do_Maritimo",
  },
  {
    title: "SeeHealth apresenta nova aplicação SeeApp – Saúde Escolas",
    descriptions: [
      "A empresa SH SeeHealth, Lda. em conjunto com a Universidade da Madeira (UMa) e o Instituto de Tecnologias Interativas/ LARSyS – Unidade de Investigação da ARDITI vai apresentar o projecto SeeApp – Saúde Escolas: Projecto de Monitorização em Saúde.",
      "O evento decorrerá no dia 18 de Maio pelas 09:00 horas nas instalações do Colégio dos Jesuítas, Rua dos Ferreiros, Funchal.",
    ],
    img: require("./images/news/seeapp.png"),
    date: "06/05/2022",
    author: "FUNCHAL NOTÍCIAS",
    link: "https://funchalnoticias.net/2022/05/06/seehealth-apresenta-nova-aplicacao-seeapp-saude-escolas/",
  },
  {
    title: "Humberto Vasconcelos amanhã na apresentação do projecto “Biomask”",
    descriptions: [
      "O secretário regional de Agricultura e Desenvolvimento Rural, Humberto Vasconcelos, estará amanhã presente na apresentação do projecto BioMask, que está a ser desenvolvido pela The Biomask Company, Universidade da Madeira e ARDITI, em parceria com o Centro da Banana da Madeira e Empresa do Sector da Banana (GESBA).      ",
    ],
    img: require("./images/news/humberto.png"),
    date: "28/06/2023",
    author: "JM MADEIRA",
    link: "https://funchalnoticias.net/2023/06/28/humberto-vasconcelos-amanha-na-apresentacao-do-projecto-biomask/",
  },
  {
    title: "GovGo apresenta todos os concursos públicos nacionais",
    descriptions: [
      "A plataforma disponibiliza a informação relativa a concursos públicos, que sejam publicados no Diário da República Eletrónico, bem como toda informação do Portal BASE, de forma simples e metódica.",
    ],
    img: require("./images/news/govgo.png"),
    date: "22/03/2023",
    author: "ECO SAPO",
    link: "https://eco.sapo.pt/2023/03/22/govgo-apresenta-todos-os-concursos-publicos-nacionais/",
  },
  {
    title: "Turmas piloto de quatro escolas usam aplicação SEEapp",
    descriptions: [
      "Quatro escolas da Região passaram a dispor, este ano, de uma aplicação que visa maximizar os benefícios educacionais, o bem-estar e a saúde dos alunos, professores e comunidade educativa.",
    ],
    img: require("./images/news/seeapp_schools.png"),
    date: "01/02/2023",
    author: "JM MADEIRA",
    link: "https://www.jm-madeira.pt/regiao/ver/197299/Turmas_piloto_de_quatro_escolas_usam_aplicacao_SeeApp",
  },
  {
    title: "Grupo Vipa adquire lote no Parque Empresarial de São Vicente",
    descriptions: [
      "O grupo regional que opera em diversos setores de mercado, desde o setor automóvel, à inovação e tecnologia, irá ocupar o Lote n.º 35 no Parque Empresarial de São Vicente.",
      "O grupo Vipa, celebrou o Contrato Promessa de Compra e Venda no transato dia 14 de outubro para a aquisição do Lote n.º 35, em São Vicente. A operação foi realizada entre Pedro Paixão - Diretor Executivo da Vipa Connect, e Gonçalo Pimenta (...)",
    ],
    img: require("./images/news/pedro.png"),
    date: "26/10/2022",
    author: "JM MADEIRA",
    link: "https://www.jm-madeira.pt/regiao/ver/188401/Grupo_Vipa_adquire_lote_no_Parque_Empresarial_de_Sao_Vicente",
  },
  {
    title: "The Biomask Company apresenta máscaras médicas biodegradáveis",
    descriptions: [
      "A The Biomask Company, Lda, em conjunto com a Universidade da Madeira (UMa) e o Instituto de Tecnologias Interativas/ LARSyS - Unidade de Investigação da ARDITI, apresentam no dia 29 de junho, pelsa 10h45, o projeto bioMASK, nas instalaões da BAM - Centro da Banana da Madeira, no Lugar de Baixo.",
    ],
    img: require("./images/news/biomask_conf.png"),
    date: "26/06/2023",
    author: "JM MADEIRA",
    link: "https://www.jm-madeira.pt/regiao/ver/210722/The_Biomask_Company_apresenta_mascaras_medicas_biodegradaveis",
  },
  {
    title:
      "Resultados dos projectos SEEApp e SAFE apresentados a 17 de Abril no Funchal",
    descriptions: [
      "No próximo dia 17 de Abril, das 9 às 13 horas, na Sala da Assembleia da Câmara Municipal do Funchal, vai decorrer a conferência 'Envolvimento dos Utilizadores nas Aplicações Móveis: Desafios'. Este evento insere-se no âmbito de uma parceria entre a Universidade da Madeira e a Câmara Municipal do Funchal e perspectiva a apresentação dos principais resultados obtidos nos projectos SEEApp e SAFE.",
    ],
    img: require("./images/news/projetos_cmf.png"),
    date: "12/04/2023",
    author: "DN MADEIRA",
    link: "https://www.dnoticias.pt/2023/4/12/355767-resultados-dos-projectos-seeapp-e-safe-apresentados-a-17-de-abril-no-funchal/",
  },
  {
    title: "Madeira quer monitorizar questões de saúde nas escolas (vídeo)",
    descriptions: [
      "Estão a ser desenvolvidas aplicações para monitorizar questões de saúde nas escolas e outra que pretende interagir com adeptos e sócios em eventos desportivos. É mais um passo para a digitalização nas escolas e uma aproximação dos pais ao contexto escolar dos alunos.",
    ],
    img: require("./images/news/seeapp_rtp_madeira.png"),
    date: "17/04/2023",
    author: "RTP MADEIRA",
    link: "https://www.rtp.pt/madeira/politica/madeira-quer-monitorizar-questoes-de-saude-nas-escolas-video_115722",
  },
  {
    title:
      "HWL - Homes With Love apoia Associação de Solideriadade Social Centro da Mãe",
    descriptions: [
      "A equipa da HWL - Homes With Love apoiou recentemente a Associação de Solidariedade Social Centro da Mãe, que tem como missão ajudar jovens grávidas/ mães e os seus filhos, que se encontrem em situações de risco.",
    ],
    img: require("./images/news/hwl_jm.png"),
    date: "29/08/2022",
    author: "JM MADEIRA",
    link: "https://www.jm-madeira.pt/regiao/ver/182514/HWL_-_Homes_With_Love_apoia_Associacao_de_Solidariedade_Social_Centro_da_Mae",
  },
  {
    title:
      "'Roadshow EA-IDEIA' chega à Madeira através da Marinha e do Fórum Oceânico",
    descriptions: [
      "'Roadshow EA-IDEIA' é o nome da iniciativa da Marinha que, em conjunto com o Fórum Oceano, Cluster do Mar Português, e com o apoio da ANACOM. Pela primeira vez na Madeira, tal como o DIÁRIO divulgou na edição impressa de ontem, quer promover a \"partilha recíproca de conhecimento e boas práticas na área da Inovação\".",
    ],
    img: require("./images/news/ea_idea_dn.png"),
    date: "30/05/2022",
    author: "DN MADEIRA",
    link: "https://www.dnoticias.pt/2022/5/30/313307-roadshow-ea-ideia-chega-a-madeira-atraves-da-marinha-e-do-forum-oceanico/",
  },
  {
    title: "Marinha Portuguesa e fórum oceano promovem inovação",
    descriptions: [
      "A Marinha Portuguesa realiza, anualmente, o Roadshow EA-IDEIA, que visa dinamizar a inovação e aprofundar a colaboração com a Academia e a Indústria, sendo que, este ano, esta iniciativa, realiza-se pela primeira vez na Madeira.",
      "Este certame, que acontece em parceria com o Fórum Oceano, Cluster do Mar Português, e com o apoio da ANACOM, pretende estabelecer parcerias e iniciativas de cooperação e promovendo a identificação de oportunidades de projetos de inovação aberta e colaborativa.",
    ],
    img: require("./images/news/ea_idea_jm.png"),
    date: "30/05/2022",
    author: "JM MADEIRA",
    link: "https://www.jm-madeira.pt/regiao/ver/174033/Marinha_Portuguesa_e_Forum_Oceano_promovem_Inovacao_",
  },
  {
    title: "Humberto Vasconcelos na apresentação do projeto Biomask",
    descriptions: [
      "O secretário regional de Agricultura e Desenvolvimento Rural, Humberto Vasconcelos, estará amanhã presente na apresentação do projeto BioMask, que está a ser desenvolvido pela The Biomask Company, Universidade da Madeira e ARDITI, em parceria com o Centro da Banana da Madeira e Empresa do Sector da Banana (GESBA).",
    ],
    img: require("./images/news/biomask_humberto.png"),
    date: "28/06/2023",
    author: "JM MADEIRA",
    link: "https://www.jm-madeira.pt/regiao/ver/210920/Humberto_Vasconcelos_na_apresentacao_do_projeto_BioMask",
  },
  {
    title: "Projeto SAFE: Safe Football Entrance",
    descriptions: [
      "Após longos meses de dedicação, esforço e trabalho em equipa, anunciamos que o projeto SAFE foi concluído com sucesso, através da entrega dos prémios aos nossos vencedores.",
      "O objetivo a que nos propusemos consistia em investigar e desenvolver uma plataforma de informação e comunicação com a capacidade para controlar e monitorizar o acesso dos (...)",
    ],
    img: require("./images/news/safe_proj.png"),
    date: "26/06/2023",
    author: "SAFE - FOOTBALL ENTRANCE FACEBOOK",
    link: "https://www.facebook.com/story.php?story_fbid=pfbid027XrDc7WRTJiGCBS5Pa2rWGYt7iH3R4ZQ6HsWgh9FwfJoL4kkGej6f9WkuLGdRVaZl&id=100088082079907",
  },
  {
    title: "Projeto de recuperação de uma casa de família antiga",
    descriptions: [
      "O diretor regional de Agricultura e Desenvolvimento Rural, Marco Caldeira, acompanhado pelo Presidente da Direção da ACAPORAMA - Associação de Casas do Povo da RAM, Sérgio Oliveira, visitou o projeto de recuperação de uma casa de família antiga para alojamento de turismo em espaço rural, do jovem promotor Pedro Paixão.",
      "Este empreendimento, situado em Santo António da Serra, Santa Cruz, é apoiado através de verbas provenientes do orçamento regional e de fundos comunitários, indo criar três novos postos de trabalho, em sintonia com a estratégia adotada de estímulo ao empreendedorismo rural.",
      "De realçar ainda que está prevista a aquisição de produtos agrícolas aos agricultores das zonas envolventes, o que constitui não só uma oportunidade para o escoamento das colheitas, como também um contributo para o reconhecimento e a valorização da produção local.",
    ],
    img: require("./images/news/saint_anthony.jpeg"),
    date: "05/02/2024",
    author: "DIREÇÃO REGIONAL DE AGRICULTURA E DESENVOLVIMENTO RURAL",
    link: "https://www.facebook.com/story.php?story_fbid=122124005990137713&id=61554131409467&mibextid=WC7FNe",
  },
  {
    title: "Projeto inovador desenvolvido na Madeira recebeu um prémio",
    descriptions: [
      "Trata-se da criação de sementeiras biodegradáveis, feitas com resíduos agrícolas e que substituem as atuais que são de plástico.",
    ],
    img: require("./images/news/bioreboot_award.webp"),
    date: "28/11/2023",
    author: "RTP MADEIRA",
    link: "https://madeira.rtp.pt/sociedade/projeto-inovador-desenvolvido-na-madeira-recebeu-um-premio-video/",
  },
  {
    title: "Miguel Albuquerque enaltece empresas do Grupo VIPA",
    descriptions: [
      "Miguel Albuquerque visitou nesta manhã de sexta-feira três das empresas do Grupo VIPA: a The Biomask Company, a SeeHealth e a VGPC, Lda. Todas do sector tecnológico e com a particularidade de estarem a desenvolver vários projetos com parceiros regionais, entre os quais o Governo Regional, a ARDITI e a Universidade da Madeira.",
    ],
    img: require("./images/news/vipa.jpg"),
    date: "17/05/2024",
    author: "Governo Regional da Madeira",
    link: "https://www.madeira.gov.pt/Governo-Regional-Madeira/ctl/Read/mid/4829/InformacaoId/220926/UnidadeOrganicaId/2/CatalogoId/0",
  },
  {
    title:
      "Gestão de património online: Vipa.pt disponibiliza a plataforma PatriGest",
    descriptions: [
      "Solução dá respostas à descentralização dos dados de bens ativos, à perda de informação e à ausência de ferramentas que acompanhem a desvalorização de bens.",
    ],
    date: "11/06/2024",
    author: "Florbela Silva",
    link: "https://presspoint.pt/gestao-de-patrimonio-online/",
    img: require("./images/news/gestao-patrimonio-online.jpg"),
  },
  {
    title: "Nova app propõe resolver caos nas levadas",
    descriptions: [
      "Para tentar resolver o caos que se vive nalgumas levadas e percursos de montanha da Madeira, uma empresa tecnológica madeirense colocou mãos à obra visando oferecer uma solução, em formato App, para gerir a circulação diária nesses pontos turísticos.",
    ],
    date: "24/06/2024",
    author: "Francisco José Cardoso",
    link: "https://vipa.pt/",
    img: require("./images/news/noticias_DN.png"),
  },
  {
    title: "Vipa.pt integra IA na plataforma govgo.pt",
    descriptions: [
      "Lançada na Madeira no segundo trimestre de 2024, a plataforma govgo.pt centraliza em tempo real a informação sobre concursos públicos, facilitando o acesso e a consulta por parte dos utilizadores.",
    ],
    date: "15/08/2024",
    author: "Hélder Medeiros",
    link: "https://digitalinside.pt/vipa-pt-integra-ia-na-plataforma-govgo-pt/",
    img: require("./images/news/new_govgo_1.png"),
  },
  {
    title: "Vipa.pt certificada em formação profissional pelo IQ",
    descriptions: [
      "A Vipa.pt, através do seu polo ‘Vipa Lab - Investigação e Formação Profissional’, foi recentemente certificada como entidade formadora pelo Instituto para a Qualificação (IQ, IP-RAM). A Vipa.pt está certificada na área de educação e formação em desenvolvimento pessoal e encontra-se em processo de certificação na área de Ciências Informáticas, com previsão de conclusão já este mês de julho, reforçando assim o seu papel na capacitação de indivíduos para o futuro digital.",
    ],
    date: "19/07/2024",
    author: "JM MADEIRA",
    link: "https://www.jm-madeira.pt/regiao/vipa-pt-certificada-em-formacao-profissional-pelo-iq-GC16361337",
    img: require("./images/news/vipapt-online.jpg"),
  },
  {
    title: "Vipa.pt investiu 459 mil euros em projetos de inovação",
    descriptions: [
      "Pedro Vieira Paixão, CEO da Vipa.pt, afirma que “somos reconhecidos pelo desempenho e compromisso na inovação e na criação de produtos, serviços e processos tecnológicos, tanto a nível regional como nacional”.",
    ],
    img: require("./images/news/inovacao-5-e1712654762850-18732d03.webp"),
    date: "21/05/2024",
    author: "O JORNAL ECONÓMICO",
    link: "https://jornaleconomico.sapo.pt/noticias/vipa-pt-investiu-459-mil-euros-em-projetos-de-inovacao/",
  },
  {
    title:
      "Empresa da Madeira reúne informação sobre concursos públicos numa única plataforma",
    descriptions: [
      "A Vipa apresenta-se como uma software house nacional, com origens na Região Autónoma da Madeira, que desenvolve software multiplataforma para diversas áreas de negócio. A plataforma chama-se Govgo e reúne informação sobre concursos públicos em Portugal.",
      "A empresa madeirense Vipa desenvolveu uma plataforma, a Govgo, que reúne num só local informação sobre todos os concursos públicos existentes em Portugal e permite também acompanhar todos os procedimentos relativos a esses mesmos concursos.",
      "“A solução responde a necessidades de cidadãos e empresas em detetar oportunidades de negócio através da pesquisa e acompanhamento dos procedimentos de contratação pública e dos contratos celebrados pelas entidades adjudicantes” disse o CEO da Vipa, Pedro Vieira Paixão.",
      "Através desta plataforma pretende-se atingir “transparência, equidade e rigor na prestação de um serviço de excelência”.",
      "A Vipa apresenta-se como uma software house nacional, com origens na Região Autónoma da Madeira, que desenvolve software multiplataforma para diversas áreas de negócio.",
      "A empresa sublinha que foi reconhecida pela Agência Nacional de Inovação (ANI) como uma empresa no sector da tecnologia dedicada à investigação e desenvolvimento (I&D), pelo seu “desempenho e compromisso na inovação e na criação de produtos, serviços e processos tecnológicos”, a que acresce o reconhecimento de idoneidade por parte de instituições como o Instituto Superior Técnico da Universidade de Lisboa (ITI-LARSyS), a Universidade da Madeira (UMa) e a Arditi – Agência Regional para o Desenvolvimento da Investigação, Tecnologia e Inovação.",
    ],
    img: require("./images/news/jornal_econ_govgo.webp"),
    date: "05/04/2024",
    author: "O JORNAL ECONÓMICO",
    link: "https://jornaleconomico.sapo.pt/noticias/empresa-da-madeira-reune-informacao-sobre-concursos-publicos-numa-unica-plataforma/?utm_source=facebook_sharebutton_appSAPOJornais&utm_medium=social&utm_campaign=social_sharebutton_appSAPOJornais",
  },
  {
    title: "Aplicação govgo reúne concursos públicos numa solução única",
    descriptions: [
      "O processo de rebranding da nova versão da govgo assenta no seu compromisso de transparência, equidade e rigor na prestação de um serviço de excelência.",
      "A solução govgo agrega todos os procedimentos e contratos publicados em Portugal numa única plataforma e apresenta, após um ano de fase piloto, uma navegação mais intuitiva e imagem contemporânea com o propósito de melhorar a experiência do utilizador.",
      "O processo de rebranding da nova versão da govgo assenta no seu compromisso de transparência, equidade e rigor na prestação de um serviço de excelência.",
      "Pedro Vieira Paixão, CEO da Vipa afirma que “a solução responde a necessidades de cidadãos e empresas em detetar oportunidades de negócio através da pesquisa e acompanhamento dos procedimentos de contratação pública e dos contratos celebrados pelas entidades adjudicantes”.",
      "A govgo disponível em https://www.govgo.pt/ dispõe num único sítio a informação centralizada relativa a todos os concursos públicos existentes em Portugal e permite o acompanhamento contínuo de todos os procedimentos, apresenta agora novas funcionalidades, como as notificações diárias de novos anúncios e a possibilidade de filtrar e criar uma lista de favoritos de acordo com áreas de interesse.",
      "A Vipa é uma software house nacional fundada na Região Autónoma da Madeira que desenvolve de software multiplataforma para diversas áreas de negócio. ",
      "Foi reconhecida pela Agência Nacional de Inovação (ANI) como empresa no setor da tecnologia dedicada à investigação e desenvolvimento (I&D), pelo seu desempenho e compromisso na inovação e na criação de produtos, serviços e processos tecnológicos e conta com reconhecimento de idoneidade do Instituto Superior Técnico da Universidade de Lisboa (ITI-LARSyS), da Universidade da Madeira e da Arditi – Agência Regional para o Desenvolvimento da Investigação, Tecnologia e Inovação.",
    ],
    img: require("./images/news/digital_inside_govgo.png"),
    date: "09/04/2024",
    author: "DIGITAL INSIDE",
    link: "https://digitalinside.pt/aplicacao-govgo-reune-concursos-publicos-numa-solucao-unica/",
  },
  {
    title:
      "Apresentação pública do projeto “Saúde Escolas: Projeto de Monitorização em Saúde [SEE_App]”",
    descriptions: [
      "Apresentação pública do projeto “Saúde Escolas: Projeto de Monitorização em Saúde [SEE_App]” no passado dia 18 de maio de 2022 no Colégio dos Jesuítas. Este projeto tem o desenvolvimento em parceria com SeeHealth, Lda, Universidade da Madeira (UMa) e o Instituto de Tecnologias Interativas/ LARSyS e a ARDITI - Agência Regional para o Desenvolvimento da Investigação, Tecnologia e Inovação.",
      "O evento teve a finalidade de promover a apresentação pública do projeto de forma prática acerca das funcionalidades associadas ao aplicativo móvel, para vigilância e resposta a surtos de doenças infecciosas e a vigilância em saúde previstas pelo sistema regional de saúde, de forma a agilizar a comunicação entre os agentes de saúde e a comunidade escolar.",
    ],
    img: require("./images/news/seeapp_presentation_facebook.jpeg"),
    date: "19/05/2022",
    author: "SEE APP - FACEBOOK",
    link: "https://www.facebook.com/permalink.php?story_fbid=pfbid02J7BZ27tU3xBVun5KCAxCy2pkKxsiQktUineat3tvSGLhPKay3bdV6qymrQMfa8qEl&id=103419235703022",
  },
  {
    title: "Revolução biodegradável nas sementeiras agrícolas",
    descriptions: [
      "Com a conquista do Prémio Inovação do Crédito Agrícola, a BioReboot abre caminho para novos investimentos e parcerias, visando a comercialização das BioGrowth Pods até ao final de 2025.",
      "As BioGrowth Pods são fruto de uma investigação, que decorre há cerca de três anos, em conjunto com produtores em contexto de viveiros que fornecem orientações sobre as principais dificuldades que encontram tanto na utilização de produtos de plástico descartável como de produtos alternativos ao plástico. Na prática, o projeto assenta no desenvolvimento de sementeiras biodegradáveis feitas de um biocompósito de fibras naturais obtido a partir de resíduos agrícolas da Região Autónoma da Madeira, como uma alternativa sustentável às bandejas e sacos de plásticos descartáveis utilizados na agricultura.",
      "“Criámos um produto que visa colmatar as necessidades apresentadas pelos produtores e que oferece melhor disponibilidade nutricional, retenção de água e taxa de degradabilidade quando comparadas às alternativas atualmente disponíveis no mercado”, explica Thiago Gomes, CEO BioReboot.",
      "Um prémio que abre a porta a mais investidores",
      "Este projeto venceu o Prémio Inovação do Crédito Agrícola, na categoria Valorização de Recursos Endógenos, uma vez que transforma resíduos agrícolas com recursos valiosos, diminui a emissão de gases com efeito de estufa, fruto da fermentação destes resíduos em campo e introduz no mercado produtos alternativos ao plástico descartável. Para além do reconhecimento do potencial e qualidade do trabalho desenvolvido pela equipa, o prémio permite estar em contacto com uma entidade que trabalha diretamente com o setor que identifica como o seu principal mercado. “A exposição gerada, tanto pela competição como pelo resultado, já começou a gerar networking à BioReboot”, referiu Thiago Gomes. Este contexto poderá atrair novos investidores. “Estamos a preparar-nos para iniciar rondas de investimento e a avaliar o potencial do biocompósito que desenvolvemos em outras áreas”, avançou o investigador. Todo o investimento feito até à data teve origem em capitais próprios dos cinco elementos do grupo. “Prevemos a necessidade de captar cerca de 3 milhões de euros para a implementação de uma unidade de produção em escala das sementeiras e estar aptos a fornecer o mercado de Portugal e, posteriormente, da Europa”, planeia o CEO da BioReboot.",
      "Comercialização até ao final de 2025",
      "As BioGrowth Pods estão na fase de protótipo e já foram aplicadas em field trial, com resultados positivos, potenciando a degradação acelerada, para promover boa formação das raízes, a eliminação da necessidade de transplante do plástico ao solo e a retenção de humidade, para boa gestão de recursos hídricos. De acordo com Thiago Gomes, o próximo passo será um novo field trial, que será implementado em 2024, para validar os resultados obtidos. “Temos produtores parceiros que se disponibilizaram a testar as nossas sementeiras nas suas produções, o que nos irá ajudar a terminar os ajustes ao produto final, para lançamento de mercado”, explicou o investigador.",
      "A equipa tem o objetivo de lançar as BioGrowth Pods no mercado até ao final de 2025. As parcerias estabelecidas com entidades ligadas ao setor agrícola, juntamente com a implementação da unidade de produção em escala serão essenciais ao lançamento destes e outros produtos ligados ao setor agrícola.",
    ],
    img: require("./images/news/bioreboot_award_jornalnogocios.png"),
    date: "14/12/2023",
    author: "JORNAL DE NEGÓCIOS",
    link: "https://www.jornaldenegocios.pt/negocios-em-rede/premio-inovacao-ca/2023/detalhe/revolucao-biodegradavel-nas-sementeiras-agricolas",
  },
  {
    title: "BioGrowth Pods",
    descriptions: [
      "Sementeiras biodegradáveis feitas de um biocompósito de fibras naturais obtido a partir de resíduos agrícolas da Região Autónoma da Madeira, como uma alternativa sustentável às bandejas e sacos de plásticos descartáveis utilizados na Agricultura.",
      "As BioGrowth Pods possuem melhor disponibilidade nutricional, retenção de água e taxa de degradabilidade quando comparadas às alternativas atualmente disponíveis no mercado. Será estabelecido um modelo de negócio de Economia Circular partindo da Ilha da Madeira, recolhendo biomassa de resíduos agrícolas, transformando-a em biocompósito e fabricando produtos diferenciados.",
      "De que forma este projecto valoriza recursos endógenos?",
      "Transforma resíduos agrícolas com recursos valiosos, diminui a emissão de gases com efeito de estufa, fruto da fermentação destes resíduos em campo e introduz no mercado produtos alternativos ao plástico descartável.",
    ],
    img: require("./images/news/bioreboot_pods_inovation.jpeg"),
    date: "09/11/2023",
    author: "PRÉMIO INOVAÇÃO",
    link: "https://www.premioinovacao.pt/projectos-finalistas-2023/biogrowth-pods/",
  },
];

export default news;
