import styled from "styled-components";

export const Caption = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 50%;
  justify-content: flex-end;
  position: absolute;
  top: -40px;
  color: #fff;
  & h1 {
    margin-bottom: 0;
  }
  @media (max-width: 1024px) {
    width: 80%;
  }
`;
export const ExpandTrigger = styled("div")`
  width: 100%;
  height: 25%;
  position: absolute;
  bottom: 5%;

  @media (min-width: 769px) and(max-width: 1024px) {
    bottom: 15%;
  }

  @media (min-width: 432px) and (max-width: 768px) {
    bottom: 25%;
    height: 20%;
  }
  @media (max-width: 431px) {
    bottom: 0%;
    height: 1px;
  }
`;
export const ExpandableBackground = styled("div")`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-1000px);
  transition: transform 0.5s ease-in-out;
  &.active {
    transform: translateY(0);
    background-color: #071463;
    transition: 0.5s ease-in-out;
  }
`;
export const ColumnTitle = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0;
  height: 100%;
  color: #07146380;

  & h2 {
    transform: translateY(-150px);
    transition: 0.5s ease-in-out;
    text-align: center;

    @media (max-width: 431px) {
      transform: translateY(0);
    }
  }
  &.active {
    box-sizing: border-box;
    color: #ffffff80;

    & h2 {
      transform: translateY(0);
      transition: 0.5s ease-in-out;
      & b {
        color: #fff;
      }
    }
  }

  & .rowcontent {
    color: #ffffff80;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 431px) {
      display: none;
    }
  }
  & .textwrapper {
    min-width: calc(100% / 3);
    &#bottom {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }

  & .text-lines {
    position: relative;
    text-align: right;
    display: flex;
    justify-content: center;
    gap: 10px;
    & p {
      margin: 0;
    }
    & .column {
      display: flex;
      flex-direction: column;
      gap: 1px;
      margin-top: 10px;
      & .horizontal {
        position: relative;
        content: "";
        width: 60px;
        height: 1px;
        border-bottom: 2px dashed #ffffff80;
        @media (max-width: 1280px) {
          width: 50px;
        }
      }
      & .vertical {
        position: relative;
        content: "";
        width: 0px;
        height: 120px;
        border-left: 2px dashed #ffffff80;
        align-self: flex-end;
        @media (max-width: 1280px) {
          height: 60px;
        }
      }
    }
    &#left {
      max-width: 320px;
      margin-left: auto;

      @media (min-width: 432px) and (max-width: 768px) {
        max-width: 200px;
      }
      @media (max-width: 431px) {
        max-width: calc(100% / 2 - 50px);
      }
    }
    &#right {
      text-align: left;
      max-width: 600px;
      & .vertical {
        position: relative;
        content: "";
        width: 1px;
        height: 120px;
        border-left: 2px dashed #ffffff80;
        @media (max-width: 1280px) {
          height: 60px;
        }
      }
      & .horizontal {
        position: relative;
        content: "";
        width: 60px;
        height: 1px;
        border-bottom: 2px dashed #ffffff80;
        aling-self: end;
        @media (max-width: 1280px) {
          width: 45px;
        }
      }
      @media (min-width: 432px) and (max-width: 768px) {
        max-width: 250px;
      }
      @media (max-width: 431px) {
        max-width: calc(100% / 2 - 50px);
      }
    }
    &#bottom {
      text-align: left;
      max-width: 600px;

      & p {
        align-self: flex-end;
      }
      & .column {
        margin-bottom: 20px;
      }
      & .vertical {
        position: relative;
        content: "";
        width: 1px;
        height: 60px;
        border-left: 2px dashed #ffffff80;
        align-self: flex-start;
        @media (max-width: 1280px) {
          height: 30px;
        }
      }
      & .horizontal {
        position: relative;
        content: "";
        width: 240px;
        height: 1px;
        border-bottom: 2px dashed #ffffff80;
        @media (max-width: 1280px) {
          width: 120px;
        }
      }
    }
  }
`;
export const ImageTextFloat = styled("div")`
  position: absolute;
  left: 0;
  top: 50%;
  translate: 0 -50%;
  /* width: 40vw; */
  max-width: 1000px;
  background-color: #fff;
  z-index: 1;
  padding: 24px 40px 24px 50px;
  box-sizing: border-box;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
  & .textcontent {
    position: relative;
    margin-left: 20px;
    & p {
      text-align: justify;
    }
    & h4 {
      font-size: 48px;
      margin-top: 0;
      color: #07146380;
      position: relative;
      &::after {
        content: "";
        display: block;
        height: 6px;
        width: 70px;
        background-color: #07146333;
        position: absolute;
        left: 0;
        bottom: -20px;
      }
    }
  }
  &.right {
    left: unset;
    right: 0;
    padding: 24px 50px 24px 40px;
    & .textcontent {
      margin-left: unset;
      margin-right: calc(100% - 1440px / 2);
      & h4 {
        position: relative;
        margin-bottom: 50px;
        &::after {
          content: "";
          display: block;
          height: 6px;
          width: 70px;
          background-color: #07146333;
          position: absolute;
          left: 0;
          bottom: -20px;
        }
      }
    }
    &.centered {
      top: 50%;
      & h5 {
        font-weight: 500;
      }
    }
  }
  @media (min-width: 769px) and (max-width: 1450px) {
    & .textcontent {
      margin: unset;
    }
    &.right {
      & .textcontent {
        margin: unset;
      }
    }
  }
  @media (max-width: 768px) {
    height: unset;
    position: relative;
    top: unset;
    left: unset;
    width: 100%;
    padding: 0;
    translate: unset;
    & .textcontent {
      width: 90%;
      margin: 0 auto;
      padding: 20px 0;
    }
    &.right {
      width: 100%;
      padding: 0;
      translate: unset;
      & .textcontent {
        width: 90%;
        margin: 0 auto;
        padding: 20px 0;
      }
    }
  }
`;
export const ImageFloat = styled("div")`
  position: absolute;
  right: 0;
  top: 0;
  width: 60%;
  height: 100%;
  background-color: #fff;
  z-index: 0;
  &.left {
    right: unset;
    left: 0;
    width: 70%;
  }
  &.gradient {
    &::after {
      content: "";
      position: absolute;
      right: 0;
      background-color: #07146380;
      width: 45%;
      height: 100%;

      @media (max-width: 768px) {
        height: 100%;
        width: 100%;
      }
    }
  }
  &.gradient-left {
    &::after {
      content: "";
      position: absolute;
      left: 0;
      background-color: #07146380;
      width: 45%;
      height: 100%;

      @media (max-width: 768px) {
        height: 100%;
        width: 100%;
      }
    }
  }
  &.gradient_fade {
    &::after {
      content: "";
      position: absolute;
      right: 0;
      background: rgb(7, 20, 99);
      background: -moz-linear-gradient(
        90deg,
        rgba(7, 20, 99, 0.5) 0%,
        rgba(7, 20, 99, 1) 100%
      );
      background: -webkit-linear-gradient(
        90deg,
        rgba(7, 20, 99, 0.5) 0%,
        rgba(7, 20, 99, 1) 100%
      );
      background: linear-gradient(
        90deg,
        rgba(7, 20, 99, 0.5) 0%,
        rgba(7, 20, 99, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#071463",endColorstr="#071463",GradientType=1);
      width: 45%;
      height: 100%;
      @media (max-width: 768px) {
        height: 98%;
      }
    }
  }
  & img {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1024px) {
    position: absolute;
    width: 100%;

    &.left {
      width: 100%;
    }
  }
`;
