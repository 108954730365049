import styled from "styled-components";

// Timeline components
export const TimelineRow = styled("div")`
  height: 450px;
  position: relative;
  display: flex;
  gap: 50px;
  overflow-x: hidden;
  cursor: grab;
  scroll-behavior: smooth;

  &:active {
    cursor: grabbing;
  }

  @media (max-width: 431px) {
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const EventsLine = styled("div")`
  height: 2px;
  width: 2350px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 20px;
  z-index: 0;
`;

export const EventsContainer = styled("div")`
  display: flex;
`;

// Event components

export const EventComponent = styled("div")`
  height: 398px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  z-index: 1;
  & h3 {
    color: #fff;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
  }
`;

export const YearDots = styled("div")`
  display: flex;
  align-items: center;
`;

export const LineDots = styled("div")`
  border: 1px dashed #ffffff80;
`;

export const EventCircle = styled("div")`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #fff;
  border: 4px solid #708abb;
`;

export const BrandBox = styled("div")`
  display: flex;
  align-items: start;
  gap: 14px;
`;

// Brand components

export const BrandComponent = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  height: fit-content;
  width: 120px;
`;

export const TextColumn = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

export const LogoBox = styled("div")`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 94px;
  height: 89px;
  border-radius: 2.217px;
  box-shadow: 0px 0px 7.08435px 0px rgba(0, 0, 0, 0.06);

  & img {
    max-width: 60px;
    max-height: 60px;
  }
`;
