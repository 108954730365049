import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BiomaskLogo from "../../../resources/images/portfolio/biomask-logo.svg";
import SafeLogo from "../../../resources/images/portfolio/safe-logo.svg";
import SeeAppLogo from "../../../resources/images/portfolio/see-app-logo.png";
import { ProjectCard } from "../styles/home_styled";

export function Project({ project }) {
  const [brand, setBrand] = useState(null);
  const [brandname, setBrandName] = useState(null);
  const [slogan, setSlogan] = useState(null);
  const [text, setText] = useState(null);
  const [link, setLink] = useState(null);
  const [colorbg, setColorbg] = useState(null);
  const { t, i18n } = useTranslation();
  const tablet = useMediaQuery("(max-width:1280px)");

  function HandleBrand(brand) {
    switch (brand) {
      case "biomask":
        setColorbg("#FAD23B");
        setBrandName("BioMASK");
        setText(t("biomask_project_text"));
        setLink("https://www.thebiomaskcompany.com");
        break;
      case "safe":
        setColorbg("#26CA90");
        setBrandName("SAFE Football Entrance");
        setText(t("safe_project_text"));
        setLink("https://www.futsafe.com");
        break;

      case "seehealth":
        setColorbg("#A5CEDB");
        setBrandName("See App");
        setSlogan("De Olho na Saúde Escolar");
        setText(t("see_health_text"));
        setLink("https://www.seehealth.pt");
        break;
      default:
        break;
    }
  }
  function HandleLogo(brand) {
    switch (brand) {
      case "biomask":
        return <img alt="biomask" src={BiomaskLogo} style={{ width: "90%" }} />;
      case "safe":
        return <img alt="safe" src={SafeLogo} style={{ width: "90%" }} />;
      case "seehealth":
        return <img alt="seeapp" src={SeeAppLogo} style={{ width: "90%" }} />;
      default:
        break;
    }
  }

  useEffect(() => {
    setBrand(project);
    HandleBrand(brand);
    // eslint-disable-next-line
  }, [project, brand]);
  useEffect(() => {
    HandleBrand(brand);
    // eslint-disable-next-line
  }, [i18n.language, brand]);
  return (
    <ProjectCard
      style={{ cursor: link && "pointer" }}
      onClick={() => {
        window.open(link, "_blank");
      }}
      color={colorbg}
    >
      <div className="face face1">
        <div className="content">{HandleLogo(project)}</div>
        <div className="face face2">
          <div className="content">
            <div className="header">
              <h4 style={{ fontSize: tablet && "18px" }}>{brandname}</h4>
              {slogan && (
                <h5 style={{ fontSize: tablet && "16px" }}>{slogan}</h5>
              )}
              <div
                style={{
                  width: "20px",
                  height: "5px",
                  background: "#ffffffaa",
                }}
              />
            </div>
            <p style={{ fontSize: tablet && "14px" }}>{text}</p>
          </div>
        </div>
      </div>
    </ProjectCard>
  );
}
