import React from "react";
import {
  AnimationDiv,
  BackgroundWrapper,
  Card,
  CardWrapper,
  CenteredContent,
  HeroWrap,
  NewsWrapper,
  Wrapper,
} from "./styles/home_styled";

// import TeamBG from "../../resources/images/team/bg_corp.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, Divider, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HeroSlider from "../../resources/components/heroslider";
import NewsSlider from "../../resources/components/newsslider";
import VCapitalDecoration from "../../resources/images/about/vcapital-decoration.svg";
import { ReactComponent as VCapitalLogo } from "../../resources/images/about/vcapital-logo.svg";
import { ReactComponent as Eolic2 } from "../../resources/images/animations/eolic2.svg";
import Blob from "../../resources/images/calltoaction/blob.svg";
import CircleLeft from "../../resources/images/calltoaction/circle_left.svg";
import CircleRight from "../../resources/images/calltoaction/circle_right.svg";
import JoinVipaLeftCircle from "../../resources/images/calltoaction/JoinVipaLeftCircle.svg";
import JoinVipaRightCircle from "../../resources/images/calltoaction/JoinVipaRightCircle.svg";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Circle from "../../resources/images/hero/circle.svg";
import { ReactComponent as Block } from "../../resources/images/values/block.svg";
import { ReactComponent as Group } from "../../resources/images/values/group.svg";
import { ReactComponent as Mountain } from "../../resources/images/values/mountain.svg";
import { ReactComponent as Sustain } from "../../resources/images/values/sustain.svg";
import { Project } from "./components/projectCard";
import { ReactComponent as Portugal2020 } from "../../resources/images/partners/portugal-2020.svg";
import { ReactComponent as EuropeanUnion } from "../../resources/images/partners/europe-union.svg";
import { ReactComponent as MadeiraGov } from "../../resources/images/partners/madeira-gov.svg";
import { ReactComponent as Madeira1420 } from "../../resources/images/partners/madeira-14-20.svg";
import { ReactComponent as AgenciaNacional } from "../../resources/images/calltoaction/AgenciaNacionalDeInovacao.svg";
import { ReactComponent as Arditi } from "../../resources/images/calltoaction/Arditi.svg";
import { ReactComponent as Universidade } from "../../resources/images/calltoaction/UniversidadeDaMadeira.svg";
import News from "./news";

const AnimatedLargeFan = ({ style, old }) => {
  return (
    <AnimationDiv style={style}>
      <Eolic2 />
    </AnimationDiv>
  );
};

function Home() {
  const { t } = useTranslation();
  const mobile = useMediaQuery("(max-width:768px)");
  return (
    <>
      <HeroWrap>
        <HeroSlider />
      </HeroWrap>
      <BackgroundWrapper hide={true} id="content" role="main">
        <img src={Circle} alt="circle" style={{ top: "-50%" }} />
        <Wrapper>
          <CenteredContent>
            <h2>{t("value")}</h2>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <p>{t("value_sub")}</p>
              <p>{t("value_sub2")}</p>
            </div>
          </CenteredContent>
          <CardWrapper style={{ flexWrap: "wrap" }}>
            <Card to={"/about"} className="color_1">
              <h3>{t("value_card1")}</h3>
              <p>{t("value_card1_sub")}</p> {/* TODO: alterar contraste */}
              <div className="linkicon">
                <ChevronRightIcon className="icon" />
              </div>
              <Group />
            </Card>

            <Card to={"/about/mission"} className="color_2">
              <h3>{t("value_card2")}</h3>
              <p>{t("value_card2_sub")}</p> {/* TODO: alterar contraste */}
              <div className="linkicon">
                <ChevronRightIcon className="icon" />
              </div>
              <Mountain />
            </Card>
            <Card to={"/portfolio"} className="color_3">
              <h3>{t("value_card3")}</h3> {/* TODO: alterar contraste */}
              <p>{t("value_card3_sub")}</p> {/* TODO: alterar contraste */}
              <div className="linkicon">
                <ChevronRightIcon className="icon" />
              </div>
              <Block />
            </Card>
            <Card to={"/about/contribution"} className="color_4">
              <h3>{t("value_card4")}</h3> {/* TODO: alterar contraste */}
              <p>{t("value_card4_sub")}</p> {/* TODO: alterar contraste */}
              <div className="linkicon">
                <ChevronRightIcon className="icon" />
              </div>
              <Sustain />
            </Card>
          </CardWrapper>
        </Wrapper>
      </BackgroundWrapper>
      <BackgroundWrapper
        style={{
          height: "fit-content",
          background:
            "linear-gradient(90deg, rgba(7,20,99,1) 0%, rgba(10,12,128,1) 31%, rgba(19,45,204,1) 100%)",
          position: "relative",
        }}
      >
        <Wrapper style={{ padding: "10px 0px" }}>
          <h2 style={{ color: "#FFF", margin: 0, background: "none" }}>
            {t("news")}
          </h2>{" "}
          <Divider
            sx={{
              width: "5%",
              borderWidth: mobile ? "5px" : "7px",
              backgroundColor: "#FFFFFF33",
            }}
          />
        </Wrapper>
        <NewsWrapper>
          <NewsSlider style={{ zIndex: -1 }} />
        </NewsWrapper>
      </BackgroundWrapper>
      {/* <BackgroundWrapper
        hide={true}
        className="team"
        style={{ height: "50vh" }}
      >
        <div style={{ width: "100%", position: "relative", height: "50vh" }}>
          <BgDecorDark1
            style={{ position: "absolute", left: 0, top: -50, zIndex: 0 }}
          />
          <BgDecorDark2 style={{ position: "absolute", right: 0, zIndex: 0 }} />
          <Wrapper style={{ zIndex: 1 }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                height: "100%",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "80%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h2 style={{ color: "#071463" }}>{t("team")}</h2>
                <p style={{ color: "#071463" }}>{t("team_sub")}</p>
              </div>
            </div>
          </Wrapper>
        </div>
      </BackgroundWrapper> */}
      <News />
      <BackgroundWrapper style={{ height: "fit-content" }}>
        <Wrapper
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CenteredContent>
            <h2 style={{ textAlign: "center", color: "#07146380" }}>
              {t("vipa_projects")}
            </h2>
            <p>{t("vipa_projects_text")}</p>
          </CenteredContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "0 auto",
            }}
          >
            <CardWrapper style={{ paddingBottom: 0 }}>
              <Project project={"seehealth"} />
              <Project project={"biomask"} />
              <Project project={"safe"} />
            </CardWrapper>
            <CardWrapper
              style={{
                // flexDirection: "column",
                padding: 0,
                // alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  width: "100%",
                  marginTop: "40px",
                  marginBottom: "50px",
                }}
              >
                <p>{t("r&d_pdf_check")}</p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <FileCopyIcon style={{ color: "#07146333" }} />
                  <a
                    href="https://vipacorp-docs.s3.eu-central-1.amazonaws.com/projects.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <p
                      style={{
                        color: "#110E9E",
                        textDecoration: "underline",
                        margin: 0,
                      }}
                    >
                      {t("r&d_file_name")}
                    </p>
                  </a>
                </div>
              </div>
            </CardWrapper>
          </div>
        </Wrapper>
        <Wrapper>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: "50px",
              alignItems: "center",
              marginBottom: "50px",
            }}
          >
            <p
              style={{
                textTransform: "uppercase",
                fontFamily: "Figtree",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              {t("vipa_partners")}
            </p>
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: mobile ? "50px" : "80px",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: mobile ? "column" : "row",
              }}
            >
              <Portugal2020 />
              <EuropeanUnion />
              <MadeiraGov />
              <Madeira1420 />
            </div>
          </div>
        </Wrapper>
      </BackgroundWrapper>
      <BackgroundWrapper
        style={{
          height: mobile ? "70vh" : "50vh",
          display: "flex",
          justifyContent: "center",
          position: "relative",
          backgroundColor: "#FAFCFF",
          zIndex: 0,
        }}
      >
        <img
          alt="vcapital decoration"
          style={{
            position: "absoute",
            bottom: 0,
            objectFit: "contain",
            width: "100%",
            zIndex: 1,
          }}
          src={VCapitalDecoration}
        />
        {!mobile && (
          <>
            <AnimatedLargeFan
              old
              style={{
                position: "absolute",
                zIndex: 0,
                left: "50%",
                bottom: "20%",
              }}
            />
            <AnimatedLargeFan
              style={{
                position: "absolute",
                zIndex: 0,
                left: "55%",
                bottom: "27%",
              }}
            />
            <AnimatedLargeFan
              style={{
                position: "absolute",
                zIndex: 0,
                left: "60%",
                bottom: "35%",
              }}
            />
          </>
        )}

        <Wrapper
          style={{
            height: "100%",
            display: "flex",
            flexDirection: mobile ? "column-reverse" : "row",
            justifyContent: mobile ? "center" : "space-between",
            alignItems: "center",
            zIndex: "3",
            padding: mobile && "20px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: mobile ? "100%" : "50%",
              justifyContent: "center",
              alignItems: mobile && "center",
              height: "100%",
            }}
          >
            <h2
              style={{
                fontSize: "2rem",
                textTransform: "uppercase",
                color: "#337E84EE",
                margin: 0,
                textAlign: mobile && "center",
              }}
            >
              {t("sustainable_investments")}
            </h2>
            <p style={{ color: "#40868B", textAlign: mobile && "center" }}>
              {t("sustainable_investments_sub")}
            </p>
            <Button
              style={{
                backgroundColor: "#FF9A7E",
                color: "#FFF",
                fontFamily: "Poppins",
                fontSize: "16px",
                maxWidth: "200px",
              }}
              href="https://www.vcapital.pt"
              target="_blank"
            >
              {t("hero_btn")}
            </Button>
          </div>
          <VCapitalLogo
            style={{ width: mobile ? "135px" : "150px", marginTop: "auto" }}
          />
        </Wrapper>
      </BackgroundWrapper>

      {/* <BackgroundWrapper hide={true} className="calltoaction">
        <Wrapper>
          <CenteredContent className="calltoaction">
            <h3 style={{ fontWeight: 500, color: "#fff" }}>
              {t("home_banner")}
            </h3>
          </CenteredContent>
        </Wrapper>
      </BackgroundWrapper> */}
      <BackgroundWrapper
        className="calltoaction"
        style={{ padding: "20px 0", height: "fit-content" }}
      >
        <img
          src={CircleLeft}
          alt="circleleft"
          style={{ top: "-50%", left: "0" }}
          className={"hide"}
        />
        <img
          src={Blob}
          alt="blob"
          style={{ top: "-10%", left: "10%" }}
          className={"hide"}
        />
        <img
          src={CircleRight}
          alt="circleright"
          style={{ top: "0", right: "0" }}
        />
        <Wrapper>
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "40px",
            }}
          >
            <CenteredContent>
              <h2 style={{ textAlign: "center", color: "#FFFFFF80" }}>
                {t("acknowledgements")}
              </h2>
              <p
                style={{
                  color: "#FFFFFFCC",
                  fontSize: mobile ? "16px" : "20px",
                }}
              >
                {t("acknowledgements_text")}
              </p>
            </CenteredContent>
            <Wrapper>
              <div
                style={{
                  display: "flex",
                  alignItems: mobile ? "center" : "end",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  flexDirection: mobile ? "column" : "row",
                  gap: "60px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: mobile ? "12px" : "24px",
                    textAlign: "center",
                  }}
                >
                  <AgenciaNacional style={{ width: mobile && "80%" }} />
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#FFFFFF80",
                      maxWidth: mobile && "232px",
                    }}
                  >
                    {t("technology_companies")}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: mobile ? "32px" : "64px",
                    textAlign: "center",
                  }}
                >
                  <Universidade style={{ width: mobile && "80%" }} />
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#FFFFFF80",
                      maxWidth: mobile && "232px",
                    }}
                  >
                    {t("rnd_companies")}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: mobile ? "35px" : "70px",
                    textAlign: "center",
                  }}
                >
                  <Arditi style={{ width: mobile && "80%" }} />
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#FFFFFF80",
                      maxWidth: mobile && "232px",
                    }}
                  >
                    {t("rnd_companies")}
                  </p>
                </div>
              </div>
            </Wrapper>
          </div>
        </Wrapper>
      </BackgroundWrapper>
      <BackgroundWrapper
        className="calltoaction"
        style={{
          backgroundColor: "#FFF",
          paddingTop: "50px",
          height: "auto",
          position: "relative",
        }}
      >
        <CenteredContent style={{ position: "relative" }}>
          <img
            src={JoinVipaLeftCircle}
            alt="circleleft"
            style={{ top: "10%", left: "-10%" }}
            className={"hide"}
          />
          <img
            src={JoinVipaRightCircle}
            alt="circleright"
            style={{ top: "0", right: "0" }}
          />
          <h2 style={{ color: "#07146380", marginTop: 0 }}>
            {t("home_calltoaction")}
          </h2>
          <p style={{ color: "#0D1086", maxWidth: "600px" }}>
            {t("home_calltoaction_sub")}
          </p>
          <Link
            style={{ marginBottom: 0 }}
            to={"/contacts"}
            className="link-button"
          >
            {t("home_calltoaction_btn")}
            <ChevronRightIcon />
          </Link>
        </CenteredContent>
        <div
          style={{
            width: "90%",
            background: "#8389B14D",
            height: "1px",
            margin: "50px auto 0 auto",
          }}
        />
      </BackgroundWrapper>
    </>
  );
}

export default Home;
