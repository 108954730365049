import { Link } from "react-router-dom";
import styled from "styled-components";

export const AnimationDiv = styled("div")`
  & #large_fan {
    & #fan {
      & #fan_center {
        transform-origin: 44px 44px;
        -webkit-animation: rotate-center 3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          infinite both;
        animation: rotate-center 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3s
          infinite both;
      }
    }
  }
  &.left {
    & #large_fan {
      & #fan {
        & #fan_center {
          transform-origin: 44px 44px;
          -webkit-animation: rotate-center 8.5s
            cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;
          animation: rotate-center 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 8.5s
            infinite both;
        }
      }
    }
  }
  &.middle {
    & #large_fan {
      & #fan {
        & #fan_center {
          transform-origin: 44px 44px;
          -webkit-animation: rotate-center 2.5s
            cubic-bezier(0.94, 0.46, 0.45, 0.25) infinite both reverse;
          animation: rotate-center 3s cubic-bezier(0.94, 0.46, 0.45, 0.25) 2.5s
            infinite reverse;
        }
      }
    }
  }

  @keyframes rotate-center {
    0% {
      -webkit-transfoorm: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rtate(360deg);
    }
  }
`;

export const HeroWrap = styled("div")`
  width: 100%;
  height: fit-content;
  position: relative;
  background-color: #071463;
  & #slider {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  & .imageSlider {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
export const Wrapper = styled("div")`
  width: 1400px;
  margin: 0 auto;
  position: relative;
  height: 100%;

  @media (max-width: 1440px) {
    width: 90vw;
  }
`;

export const NewsWrapper = styled("div")`
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: 100%;

  @media (max-width: 1440px) {
    width: 90vw;
  }
`;
export const NewsImages = styled("div")`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 432px) and (max-width: 1024px) {
    width: 100%;
    padding-bottom: 15px;
  }
  @media (max-width: 431px) {
    width: 100%;
    padding-bottom: 15px;
  }
`;

export const NewsCaption = styled("div")`
  width: 60%;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px;
  & h1 {
    margin-bottom: 0;
    line-height: 3rem;
  }
  & h2 {
    margin-bottom: 0;
    line-height: 3rem;
  }
  & p {
    width: 700px;
  }
  & .link-button {
    display: flex;
    padding: 12px 16px;
    box-sizing: border-box;
    border: 2px solid #fff;
    text-decoration: none;
    color: #fff;
    border-radius: 30px;
    cursor: pointer;
    position: relative;
    background: linear-gradient(to left, transparent 50%, #fff 50%);
    background-size: 200% 100%;
    background-position: right center;
    transition: background-position 0.3s ease-in-out;
    &:hover {
      background-position: left center;
      color: #071463;
    }
  }
  @media (max-width: 431px) {
    width: 100%;
    align-items: center;
    /* & h1 {
      line-height: 2rem;
    } */
    & p {
      width: 100%;
    }
  }
  @media (min-width: 432px) and (max-width: 1024px) {
    width: 100%;
    align-items: center;
    /* & h1 {
      line-height: 2rem;
    } */
    & p {
      width: 100%;
    }
  }
`;
export const Caption = styled("div")`
  position: absolute;
  left: 0;
  top: 50%;
  width: 55%;
  translate: 0 -50%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & h1 {
    margin-bottom: 0;
    line-height: 5rem;
  }
  & .link-button {
    display: flex;
    padding: 12px 16px;
    box-sizing: border-box;
    border: 2px solid #fff;
    text-decoration: none;
    color: #fff;
    border-radius: 30px;
    cursor: pointer;
    position: relative;
    background: linear-gradient(to left, transparent 50%, #fff 50%);
    background-size: 200% 100%;
    background-position: right center;
    transition: background-position 0.3s ease-in-out;
    &:hover {
      background-position: left center;
      color: #071463;
    }
  }
  @media (max-width: 431px) {
    width: 90%;
    /* & h1 {
      line-height: 2rem;
    } */
  }
  @media (min-width: 432px) and (max-width: 1024px) {
    width: 70%;
    /* & h1 {
      line-height: 2rem;
    } */
  }
`;
export const BackgroundWrapper = styled("div")`
  width: 100%;
  height: auto;
  position: relative;
  overflow: ${(props) => (props.hide ? "hidden" : "unset")};

  & img {
    position: absolute;
  }
  &.team {
    background-color: #fff;
    height: 70vh;
    padding-bottom: 50px;
    & video {
      mask-image: linear-gradient(
        90deg,
        rgba(7, 20, 99, 0) 0%,
        rgba(7, 20, 99, 1) 100%
      );
      position: absolute;
      object-fit: cover;
      height: 100%;
      right: 0;
      @media (max-width: 1024px) {
        opacity: 0.5;
      }
    }

    & ${Caption} {
      position: relative;
      width: 50%;
      top: 50%;
      translate: 0 -50%;

      & h2 {
        color: #ffffff80;
        position: relative;
        &::after {
          height: 6px;
          width: 100px;
          content: "";
          display: block;
          position: absolute;
          background-color: #ffffff33;
          bottom: -20px;
          left: 0;
        }
      }
      & p {
        margin-bottom: 20px;
      }
      @media (max-width: 431px) {
        width: 90%;
        & h2 {
          /* line-height: 2rem; */
          color: #ffffff;
        }
      }
      @media (min-width: 432px) and (max-width: 1024px) {
        width: 70%;
      }
    }

    @media (max-width: 431px) {
      & img {
        height: 70%;
        opacity: 0.5;
      }
    }
    @media (min-width: 432px) and (max-width: 1024px) {
      & img {
        height: 80%;
        opacity: 0.5;
      }
    }
  }
  &.calltoaction {
    background-color: #110e9e;
    /* height: 50vh; */

    & img {
      @media (max-width: 1024px) {
        &.hide {
          display: none;
        }
      }
    }
  }
`;
export const CenteredContent = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;
  margin: 0 auto;
  text-align: center;

  & h2 {
    color: #07146380;
    margin-bottom: 20px;
  }
  & p {
    color: #071463;
    margin: 0;
    font-weight: 500;
  }
  & .link-button {
    display: flex;
    margin: 50px 0;
    padding: 12px 16px;
    box-sizing: border-box;
    text-decoration: none;
    border-radius: 30px;
    color: #fff;
    cursor: pointer;
    position: relative;
    background: linear-gradient(to left, #071463 50%, #fff 50%);
    background-size: 200% 100%;
    background-position: right center;
    transition: background-position 0.3s ease-in-out;
    &:hover {
      background-position: left center;
      color: #071463;
    }
  }
  &.calltoaction {
    width: 70%;
    height: 100%;
    justify-content: center;
  }
  @media (max-width: 1024px) {
    width: 90%;
  }
`;
export const CardWrapper = styled("div")`
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  justify-content: center;
  gap: 20px;
  padding-top: 50px;
  padding-bottom: 100px;
  width: fit-content;
  @media (max-width: 431px) {
    flex-direction: column;
  }
  @media (min-width: 432px) and (max-width: 1024px) {
    flex-direction: row;
  }
`;
export const Card = styled(Link)`
  width: calc(100% / 4 - 20px);
  height: 300px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  transition: background-color 0.3s ease-in;
  text-decoration: none;
  & h3 {
    margin: 0;
    position: relative;
    z-index: 1;
    font-size: 1.777rem;
    &::after {
      content: "";
      display: block;
      height: 5px;
      width: 24px;
      position: absolute;
      bottom: -20px;
      left: 0;
      z-index: 1;
    }
  }
  & p {
    margin-top: 40px;
    color: #fff;
    display: none;
  }
  & svg {
    z-index: -1;
    transition: fill 0.3s ease-in-out;
  }
  & .linkicon {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: none;
    z-index: 2;
    & .icon {
      position: relative;
      fill: #fff;
      font-size: 32px;
      display: block;
    }
  }
  &.color_1 {
    & h3 {
      color: #110e9e;
      &::after {
        background-color: #a09fd8;
      }
    }
    & svg {
      position: absolute;
      bottom: 0;
      fill: #2926a8;
      opacity: 0.1;
    }
  }

  &.color_2 {
    & h3 {
      color: #7790ed;
      &::after {
        background-color: #c9d3f8;
      }
    }
    & svg {
      position: absolute;
      bottom: 0;
      right: 0;
      fill: #859bef;
      opacity: 0.1;
    }
  }

  &.color_3 {
    & h3 {
      color: #6dc1e8;
      &::after {
        background-color: #c5e6f6;
      }
    }
    & svg {
      position: absolute;
      bottom: 0;
      left: 0;
      fill: #7cc7ea;
      opacity: 0.1;
    }
  }

  &.color_4 {
    & h3 {
      color: #5fd4bb;
      &::after {
        background-color: #bfeee4;
      }
    }
    & svg {
      position: absolute;
      bottom: 0;
      right: 0;
      fill: #6fd8c2;
      opacity: 0.1;
    }
  }
  &:hover {
    & .linkicon {
      display: block;
    }
    & h3 {
      color: #fff;
    }
    & p {
      display: block;
    }
    &.color_1 {
      background-color: #110e9e;
      & svg {
        opacity: 1;
      }
    }
    &.color_2 {
      background-color: #7790ed;
      & svg {
        opacity: 1;
      }
    }
    &.color_3 {
      background-color: #6dc1e8;
      & svg {
        opacity: 1;
      }
    }
    &.color_4 {
      background-color: #5fd4bb;
      & svg {
        opacity: 1;
      }
    }
  }
  @media (max-width: 431px) {
    width: 100%;
    & .linkicon {
      display: block;
    }
    & p {
      opacity: 1;
    }
    &.color_1 {
      background-color: #110e9e;
      & svg {
        opacity: 1;
      }
      & h3 {
        color: #fff;
      }
    }
    &.color_2 {
      background-color: #7790ed;
      & svg {
        opacity: 1;
      }
      & h3 {
        color: #fff;
      }
    }
    &.color_3 {
      background-color: #6dc1e8;
      & svg {
        opacity: 1;
      }
      & h3 {
        color: #fff;
      }
    }
    &.color_4 {
      background-color: #5fd4bb;
      & svg {
        opacity: 1;
      }
      & h3 {
        color: #fff;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    width: calc(100% / 2 - 20px);
  }
  @media (min-width: 432px) and (max-width: 1024px) {
    width: calc(100% / 2 - 20px);
    & .linkicon {
      display: block;
    }

    & p {
      opacity: 1;
    }
    &.color_1 {
      & h3 {
        color: #fff;
      }
      background-color: #110e9e;
      & svg {
        opacity: 1;
      }
    }
    &.color_2 {
      & h3 {
        color: #fff;
      }
      background-color: #7790ed;
      & svg {
        opacity: 1;
      }
    }
    &.color_3 {
      & h3 {
        color: #fff;
      }
      background-color: #6dc1e8;
      & svg {
        opacity: 1;
      }
    }
    &.color_4 {
      & h3 {
        color: #fff;
      }
      background-color: #5fd4bb;
      & svg {
        opacity: 1;
      }
    }
  }
`;

export const ProjectCard = styled("div")`
  position: relative;
  display: flex;
  overflow: hidden;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
  width: calc(100% / 3 - 20px);
  aspect-ratio: 1;
  @media (max-width: 431px) {
    width: 100%;
  }
  & .face {
    transition: 0.5s ease-in-out;
    height: 100%;
    width: 100%;
    &.face1 {
      position: relative;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      & .content {
        opacity: 1;
        transition: 0.5s;
        text-align: center;
        overflow: hidden;

        & h3 {
          color: #07146380;
          font-size: 50px;
        }

        & img {
          position: relative;
          object-fit: cover;
          width: 100%;
        }
      }
    }
    &.face2 {
      position: absolute;
      background: ${(props) => props.color};
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      z-index: 0;
      height: 0px;
      overflow: hidden;
      transform: translateY(500px);
      opacity: 0;

      & .content {
        box-sizing: border-box;
        padding: 20px;
        transition: 0.5s;
        text-align: left;
        height: 100%;
        display: flex;
        flex-direction: column;

        & .header {
          text-align: left;
          & h4 {
            font-size: 1.25rem;
            font-weight: 600;
            margin: 0;
            color: #fff;
          }
          & h5 {
            font-size: 1.125rem;
            font-weight: 500;
            margin: 0;
            color: #ffffff80;
          }

          &::after {
            width: 24px;
            left: 11px;
            bottom: -10px;
          }
        }
        & p {
          margin-top: 20px;
          color: #fff;
        }

        & a {
          text-decoration: none;
          color: #fff;
          font-weight: 600;
          text-align: center;
          margin-top: auto;
        }
        & .link {
          color: #ffffff80;
          font-weight: 600;
          text-align: center;
          margin-top: auto;
        }
      }
    }
  }
  &:hover {
    & .face.face2 {
      transform: translateY(0);
      opacity: 1;
      height: 100%;
    }
  }
`;
