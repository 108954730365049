import React from "react";
import {
  Caption,
  ChartLegend,
  PageBackground,
  PageHero,
  Parallax,
  RowBetween,
  Wrapper,
} from "./styles/governmentStyles";
import GovBG from "../../resources/images/government/govhero.png";
import Circle from "../../resources/images/hero/circle.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ResponsivePie } from "@nivo/pie";
import { ReactComponent as Logo } from "../../resources/icons/vipacorp.svg";

function Government() {
  const piedata = [
    {
      id: "Pedro Vieira Paixão",
      label: "Pedro Vieira Paixão",
      value: 1,
      color: "#071463",
    },
  ];
  const MyResponsivePie = ({ data /* see data tab */ }) => (
    <ResponsivePie
      data={piedata}
      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
      sortByValue={true}
      innerRadius={0.85}
      valueFormat=" >-.1%"
      activeOuterRadiusOffset={8}
      colors={{ datum: "data.color" }}
      enableArcLinkLabels={false}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      enableArcLabels={false}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
    />
  );
  const mobile = useMediaQuery("(max-width:1024px)");
  const { t } = useTranslation();
  return (
    <>
      <PageHero>
        <Parallax image={GovBG} />
        <Wrapper>
          <Caption>
            <h1>{t("identity")}</h1>
            <p>{t("government_updated")}</p>
          </Caption>
        </Wrapper>
      </PageHero>
      <PageBackground>
        <img
          src={Circle}
          style={{ left: "-30%", top: "20%", width: "70%" }}
          alt=""
        />
        <img src={Circle} style={{ right: "-50%", top: "-100%" }} alt="" />
        <Wrapper>
          <RowBetween>
            <div className="text">
              <h4 style={{ color: "#071463", fontWeight: "500" }}>
                {t("government_card_title")}
              </h4>
              <p>VIPAPAR - SGPS, Lda.</p>
              <p>
                Avenida Fontes Pereira de Melo nº16, Palácio Sotto Mayor
                <br />
                1050-121, Lisboa
              </p>
              <p>NIPC: 515 381 632</p>
              <p>{t("government_capital")}</p>
            </div>
            <div className="navigation">
              <Link
                to={"structure"}
                className="item"
                onClick={() => this.forceUpdate}
              >
                {t("structure")} <ArrowForwardIosIcon />
              </Link>
              <Link
                to={"regulations"}
                className="item"
                onClick={() => this.forceUpdate}
              >
                {t("regulation")} <ArrowForwardIosIcon />
              </Link>
            </div>
          </RowBetween>
          <RowBetween>
            <div style={!mobile ? { width: "50%" } : { width: "100%" }}>
              <h4 style={{ color: "#110E9E", fontWeight: "500" }}>
                {t("government_title2")}
              </h4>
              <p style={{ color: "#071463" }}>
                {t("government_text1")}{" "}
                <span style={{ fontWeight: "600" }}>
                  {t("government_text_founder")}
                </span>{" "}
                {t("government_text2")}{" "}
                <span style={{ fontWeight: "600" }}>
                  {t("government_text_director")}
                </span>{" "}
                {t("government_text3")}
              </p>
            </div>
            <div
              style={
                !mobile
                  ? {
                      width: "50%",
                      height: "300px",
                      display: "flex",
                      position: "relative",
                      flexDirection: "column",
                    }
                  : {
                      width: "100%",
                      height: "300px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      position: "relative",
                    }
              }
            >
              <Logo
                style={
                  !mobile
                    ? {
                        position: "absolute",
                        top: "50%",
                        right: "50%",
                        transform: "translate(50%, -50%)",
                      }
                    : {
                        position: "absolute",
                        top: "40%",
                        right: "50%",
                        transform: "translate(50%, -50%)",
                      }
                }
              />
              <MyResponsivePie />
              <ChartLegend>
                <div className="square" />
                <p>Pedro Vieira Paixão</p>
              </ChartLegend>
            </div>
          </RowBetween>
        </Wrapper>
      </PageBackground>
      {/*  */}
    </>
  );
}

export default Government;
