import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import Checkbox from "@mui/material/Checkbox";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const ModalTitle = styled.p`
  position: absolute;
  top: 10px;
  left: 15px;
  margin-top: 0;
  font-size: 1.5rem;
  font-weight: 600;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: #fff;
  padding: 25px;
  border-radius: 4px;
  max-width: 550px;
  width: 100%;
  text-align: justify;
  .custom-button {
    margin-top: 10px;
    padding: 12px 20px;
    box-sizing: border-box;
    text-decoration: none;
    border-radius: 30px;
    color: #fff;
    cursor: pointer;
    position: relative;
    background: linear-gradient(to left, #110e9e 50%, #071463 50%);
    background-size: 200% 100%;
    background-position: right center;
    transition: background-position 0.3s ease-in-out;
    &:hover {
      background-position: left center;
    }
  }
`;

const CookieOptions = styled.div`
  display: flex;
  align-items: start;
`;

function CookiesConsent() {
  const [showConsentBanner, setShowConsentBanner] = useState(false);
  const [showCookieOptions, setShowCookieOptions] = useState(false);
  const [consentMarketing, setConsentMarketing] = useState(true);

  const httpClient = (body) => {
    fetch("https://admin.vipa.pt/api/cookie-store", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  };

  const handleAcceptCookie = () => {
    document.getElementsByTagName("body");

    let iframe = document.createElement("iframe");
    iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-W8LR2KQQ";
    iframe.height = "0";
    iframe.width = "0";
    iframe.style = "display: none; visibility: hidden";

    let noscript = document.createElement("noscript");
    noscript.appendChild(iframe);
    document.body.insertBefore(noscript, document.body.firstChild);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    var f = document.getElementsByTagName("script")[0],
      j = document.createElement("script");
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=GTM-W8LR2KQQ";
    f.parentNode.insertBefore(j, f);
  };

  const isConsent = getCookieConsentValue();
  useEffect(() => {
    if (isConsent === undefined) {
      setShowConsentBanner(true);
    } else if (isConsent) {
      handleAcceptCookie();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {showConsentBanner && (
        <ModalOverlay>
          <ModalContent>
            <ModalTitle>Consentimento de Cookies</ModalTitle>
            <CookieConsent
              onAccept={() => {
                const body = {
                  marketing: consentMarketing,
                };
                httpClient(body);
                setShowConsentBanner(false);
              }}
              onDecline={() => {
                const body = {
                  marketing: false,
                };
                httpClient(body);
                setShowConsentBanner(false);
              }}
              style={{
                marginTop: "30px",
              }}
              buttonText="Aceitar as Cookies"
              declineButtonText="Rejeitar Cookies não essenciais"
              enableDeclineButton
              disableStyles={true}
              buttonStyle={{
                marginLeft: "10px",
              }}
              ariaAcceptLabel="Aceitar as Cookies"
              ariaDeclineLabel="Rejeitar Cookies não essenciais"
              buttonClasses="custom-button"
              declineButtonClasses="custom-button"
            >
              Nós usamos cookies para melhorar a sua experência de navegação,
              guardar as suas preferências, personalizar o seu conteúdo e para
              analisar o tráfego do nosso website.
            </CookieConsent>
            <button
              onClick={() => {
                setShowCookieOptions(!showCookieOptions);
              }}
              className="custom-button"
            >
              Personalizar Cookies
            </button>
            {showCookieOptions && (
              <>
                <CookieOptions
                  style={{
                    marginTop: "15px",
                  }}
                >
                  <Checkbox
                    disabled
                    checked
                    sx={{
                      color: "#59a9ff",
                      "&.Mui-checked": {
                        color: "#59a9ff",
                      },
                    }}
                  />
                  <div style={{ paddingTop: "6px" }}>
                    <p style={{ margin: 0, fontSize: "large" }}>Necessárias</p>
                    <p style={{ margin: 0, fontSize: "small" }}>
                      Estas cookies são necessárias para o funcionamento do
                      website.
                    </p>
                  </div>
                </CookieOptions>
                <CookieOptions
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Checkbox
                    onChange={() => {
                      setConsentMarketing(!consentMarketing);
                    }}
                    checked={consentMarketing}
                  />
                  <div style={{ paddingTop: "6px" }}>
                    <p style={{ margin: 0, fontSize: "large" }}>Marketing</p>
                    <p style={{ margin: 0, fontSize: "small" }}>
                      Estas cookies servem para analisar e otimizar campanhas
                      publicitárias.
                    </p>
                  </div>
                </CookieOptions>
              </>
            )}
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
}

export default CookiesConsent;
