import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMediaQuery } from "@mui/material";
import "animate.css";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as StructureIMG } from "../../resources/images/government/circle-brands.svg";
import { ReactComponent as VipaEllipse } from "../../resources/images/government/vipa-ellipse.svg";
import { ReactComponent as BackgroundDecoration1 } from "../../resources/images/government/background-decoration.svg";
import { ReactComponent as BackgroundDecoration2 } from "../../resources/images/government/background-decoration-2.svg";
import { NavigationBar } from "../about/styles/aboutStyles";
import {
  PageBackground,
  StructureHeader,
  StructureMobileWrapper,
  StructureWrapper,
  Wrapper,
} from "./styles/governmentStyles";
import styled, { keyframes } from "styled-components";
import { ReactComponent as LineBrands } from "../../resources/images/government/line-brands.svg";

const pulseAnimation = keyframes`
  0% { opacity: 30%; transform: scale(0);}
  100% { opacity: 0%;  transform: scale(1);}
`;

const RippleEffectContainer = styled.div`
  height: 340px;
  position: relative;
  width: 340px;

  position: absolute;
  z-index: 2;
  top: 49%;
  right: 49%;
  transform: translate(50%, -50%);

  & > div {
    animation: ${pulseAnimation} 3s infinite ease-out;
    background-color: white;
    border-radius: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }
`;

const ClickableArea = styled.div`
  background-color: transparent;

  position: absolute;
  z-index: 1;
  cursor: pointer;
`;

const CircleLinkToBrand = ({ positionTop, positionRight, link }) => {
  const mobile = useMediaQuery("(max-width:768px)");
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <ClickableArea
        target={"_blank"}
        style={{
          top: positionTop,
          right: positionRight,
          height: mobile ? "15%" : "8.5%",
          width: mobile ? "21%" : "6.4%",
          borderRadius: "50%",
        }}
      />
    </a>
  );
};

function Structure() {
  const mobile = useMediaQuery("(max-width:768px)");
  const { t } = useTranslation();
  return (
    <>
      {/* <NavigationBar> */}
      <NavigationBar>
        <Wrapper>
          <div className="navigationrow">
            <Link to={"/government"}>
              <ArrowBackIosIcon />
              {t("identity")}
            </Link>
            <Link to={"/government/regulations"}>
              {t("regulation")}
              <ArrowForwardIosIcon />
            </Link>
          </div>
        </Wrapper>
      </NavigationBar>
      <PageBackground style={{ backgroundColor: "#071463" }}>
        {!mobile && (
          <>
            <BackgroundDecoration1 style={{ position: "absolute", right: 0 }} />
            <BackgroundDecoration2
              style={{ position: "absolute", left: 0, bottom: "30%" }}
            />
          </>
        )}
        <Wrapper>
          <StructureHeader>{t("government_structure_title")}</StructureHeader>
          {mobile ? (
            <StructureMobileWrapper style={{ position: "relative", zIndex: 0 }}>
              <LineBrands />
              <CircleLinkToBrand
                link={"https://vipa.pt"}
                positionTop={"29%"}
                positionRight={"64%"}
              />
              {/* <CircleLinkToBrand link={""} positionTop={"40%"} positionRight={"30%"}/> */}
              <CircleLinkToBrand
                link={"https://www.seehealth.pt"}
                positionTop={"29%"}
                positionRight={"-3%"}
              />
              <CircleLinkToBrand
                link={"https://www.thebiomaskcompany.com"}
                positionTop={"40.2%"}
                positionRight={"-36.1%"}
              />
              <CircleLinkToBrand
                link={"https://www.vipaenergy.com"}
                positionTop={"29%"}
                positionRight={"-69.3%"}
              />
              <CircleLinkToBrand
                link={"https://www.vcapital.pt"}
                positionTop={"40.2%"}
                positionRight={"-103%"}
              />
              <CircleLinkToBrand
                link={"https://www.pneuzarco.pt"}
                positionTop={"29%"}
                positionRight={"-136.5%"}
              />
              <CircleLinkToBrand
                link={"https://www.ilhapneu.pt"}
                positionTop={"40.2%"}
                positionRight={"-169.2%"}
              />
              <CircleLinkToBrand
                link={"https://www.vipainternational.com"}
                positionTop={"28.5%"}
                positionRight={"-203%"}
              />
              <CircleLinkToBrand
                link={"https://www.nmds.pt"}
                positionTop={"40.5%"}
                positionRight={"-235.9%"}
              />
              <CircleLinkToBrand
                link={"https://www.poderenegocios.pt/"}
                positionTop={"29%"}
                positionRight={"-269.5%"}
              />
              <CircleLinkToBrand
                link={"https://www.vipatelecom.com"}
                positionTop={"40.5%"}
                positionRight={"-303%"}
              />
              {/* <CircleLinkToBrand link={""} positionTop={"29%"} positionRight={"-336%"}/>        */}
              <CircleLinkToBrand
                link={"https://www.hwl.pt"}
                positionTop={"40.5%"}
                positionRight={"-369.5%"}
              />
            </StructureMobileWrapper>
          ) : (
            <StructureWrapper style={{ position: "relative" }}>
              <StructureIMG style={{ zIndex: 0 }} width={"100%"} />
              <RippleEffectContainer>
                <VipaEllipse
                  style={{
                    width: "100px",
                    position: "absolute",
                    zIndex: 10,
                    top: "50%",
                    right: "50%",
                    transform: "translate(50%, -50%)",
                  }}
                />
                <div
                  style={{
                    animationDelay: "1s",
                    animationDuration: "2s",
                    background: "white",
                  }}
                ></div>
                <div
                  style={{
                    animationDelay: "1.5s",
                    animationDuration: "2s",
                    background: "white",
                  }}
                ></div>
                <div
                  style={{
                    animationDelay: "4s",
                    animationDuration: "2s",
                    background: "white",
                  }}
                ></div>
              </RippleEffectContainer>
            </StructureWrapper>
          )}
        </Wrapper>
      </PageBackground>
      {/* </NavigationBar> */}
    </>
  );
}

export default Structure;
