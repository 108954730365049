import emailjs from "@emailjs/browser";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Circle from "../../resources/images/hero/circle.svg";
import { PageBackground, Wrapper } from "../government/styles/governmentStyles";
import {
  CustomAccordion,
  CustomButton,
  CustomCheckbox,
  CustomInput,
  CustomRadioButton,
  FlexContainer,
  MainHeader
} from "./styles/privacy_styles";

function Denuncias() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation();

  const form = useRef();

  const sendEmail = (data) => {
    emailjs
      .send(
        'service_3pp99ft',
        'template_8c2etu4',
        data,
        'EFVSzw2nBKrOWLmQ8'
      )
      .then(
        (result) => {
          toast("Email sent successfully");
          form.current.reset();
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <PageBackground style={{ minHeight: "70vh" }}>
      <img
        src={Circle}
        style={{ left: "-30%", top: "20%", width: "70%" }}
        alt=""
      />
      <img src={Circle} style={{ right: "-10%", top: "-50%" }} alt="" />
      <Wrapper style={{ marginTop: "100px" }}>
        <MainHeader>{t("reports_title")}</MainHeader>
        <p style={{ textAlign: "center", color: "#071463", fontSize: "18px" }}>
          {t("reports_content")}
          <br />
          <br />
          {t("reports_hint")}
        </p>
        <CustomAccordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <p>{t("reports_accord_title")}</p>
          </AccordionSummary>
          <AccordionDetails>
            <ol>
              <li>{t("reports_item_1")}</li>
              <li>{t("reports_item_2")}</li>
              <li>{t("reports_item_3")}</li>
            </ol>
          </AccordionDetails>
        </CustomAccordion>
        <h4
          style={{
            textAlign: "center",
            fontWeight: "500",
            color: "#110E9E",
            fontSize: "36px",
          }}
        >
          {t("reports_form_title")}
        </h4>

        <form
          style={{ display: "flex", flexDirection: "column" }}
          ref={form}
          onSubmit={handleSubmit(sendEmail)}
        >
          <div style={{ textAlign: "left", color: "#A0A8B1" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <p style={{ color: "#656565" }}>
                {t("reports_form_anonymous")}
                <span style={{ color: "#ED1D24" }}>*</span>
              </p>
              {errors.anonymous && (
                <span style={{ color: "#ED1D24", fontSize: "14px" }}>
                  {t("required_error")}
                </span>
              )}
            </div>

            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <CustomRadioButton>
                <input
                  type="radio"
                  id="sim"
                  name="anonymous"
                  value="true"
                  {...register("anonymous", { required: true })}
                />
                <div className="radio" />
              </CustomRadioButton>
              <label for="sim">{t("reports_form_yes")}</label>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <CustomRadioButton>
                <input
                  type="radio"
                  id="nao"
                  name="anonymous"
                  value="false"
                  {...register("anonymous", { required: true })}
                />
                <div className="radio" />
              </CustomRadioButton>
              <label for="nao">{t("reports_form_no")}</label>
            </div>
          </div>
          <br></br>
          {watch("anonymous") === "false" && (
            <>
              <p style={{ color: "#656565" }}>{t("reports_form_name")}</p>
              <CustomInput>
                <input
                  placeholder={t("reports_form_name_placeholder")}
                  name="from_name"
                  type={"text"}
                  {...register("from_name", { required: false })}
                />
              </CustomInput>
              <FlexContainer>
                <div style={{ width: "100%" }}>
                  <p style={{ color: "#656565" }}>{t("reports_form_email")}</p>
                  <CustomInput>
                    <input
                      placeholder={t("reports_form_email_placeholder")}
                      name="from_email"
                      type={"email"}
                      {...register("from_email", { required: false })}
                    />
                  </CustomInput>
                </div>
                <div style={{ width: "100%" }}>
                  <p style={{ color: "#656565" }}>{t("reports_form_phone")}</p>
                  <CustomInput>
                    <input
                      placeholder="000 000 000"
                      type={"phone"}
                      name="phone_number"
                      {...register("phone_number", { required: false })}
                    />
                  </CustomInput>
                </div>
              </FlexContainer>
            </>
          )}
          <FlexContainer>
            <div style={{ width: "100%" }}>
              <p style={{ color: "#656565" }}>
                {t("reports_form_subject")}
                <span style={{ color: "#ED1D24" }}>*</span>
              </p>
              <CustomInput>
                {/* <input
                  placeholder={t("reports_form_select")}
                  type={"text"}
                  name="report_subject"
                  {...register("report_subject", { required: true })}
                /> */}
                <select
                  id="select-input"
                  name="report_subject"
                  {...register("report_subject", { required: true })}
                >
                  <option value="">{t("reports_form_select")}</option>
                  <option value="colaborador">
                    {t("reports_form_subject_option_1")}
                  </option>
                  <option value="ex_colaborador">
                    {t("reports_form_subject_option_2")}
                  </option>
                  <option value="candidato">
                    {t("reports_form_subject_option_3")}
                  </option>
                  <option value="cliente">
                    {t("reports_form_subject_option_4")}
                  </option>
                </select>
              </CustomInput>
            </div>
            <div style={{ width: "100%" }}>
              <p style={{ color: "#656565" }}>
                {t("reports_form_relation")}
                <span style={{ color: "#ED1D24" }}>*</span>
              </p>
              <CustomInput>
                <select
                  id="select-input"
                  name="relationship_origin"
                  {...register("relationship_origin", { required: true })}
                >
                  <option value="">{t("reports_form_select")}</option>
                  <option value="colaborador">
                    {t("reports_form_select_option_1")}
                  </option>
                  <option value="ex_colaborador">
                    {t("reports_form_select_option_2")}
                  </option>
                  <option value="candidato">
                    {t("reports_form_select_option_3")}
                  </option>
                  <option value="cliente">
                    {t("reports_form_select_option_4")}
                  </option>
                  <option value="parceiro">
                    {t("reports_form_select_option_5")}
                  </option>
                  <option value="fornecedor">
                    {t("reports_form_select_option_6")}
                  </option>
                  <option value="Outro">
                    {t("reports_form_select_option_7")}
                  </option>
                </select>
                {/* <input
                  placeholder={t("reports_form_select")}
                  type={"date"}
                  name="relationship_date"
                  {...register("relationship_date", { required: true })}
                /> */}
              </CustomInput>
            </div>
          </FlexContainer>
          <FlexContainer>
            <div style={{ width: "100%" }}>
              <p style={{ color: "#656565" }}>
                {t("reports_form_company")}
                <span style={{ color: "#ED1D24" }}>*</span>
              </p>
              <CustomInput>
                <input
                  placeholder={t("reports_form_select")}
                  type={"text"}
                  name="report_company"
                  {...register("report_company", { required: true })}
                />
              </CustomInput>
            </div>
            <div style={{ width: "100%" }}>
              <p style={{ color: "#656565" }}>{t("reports_form_occurrence")}</p>
              <CustomInput>
                <input
                  placeholder={t("reports_form_select")}
                  type={"date"}
                  name="report_date"
                  {...register("report_date", { required: true })}
                />
              </CustomInput>
            </div>
          </FlexContainer>
          <div>
            <p style={{ color: "#656565" }}>
              {" "}
              {t("reports_form_description")}{" "}
              <span style={{ color: "#ED1D24" }}>*</span>
            </p>
            <CustomInput>
              <textarea
                placeholder={t("reports_form_write")}
                rows={"5"}
                name="report_description"
                {...register("report_description", { required: true })}
              />
            </CustomInput>
          </div>
          <div
            style={{
              margin: "40px 0",
              background: "#E2E8F0",
              width: " 100%",
              height: "1px",
            }}
          />
          <div
            style={{
              background: "#07146305",
              padding: "20px",
              borderRadius: "4px",
              border: "1px solid #E2E8F0",
              textAlign: "left",
              color: "#656565",
            }}
          >
            <div
              style={{ display: "flex", gap: "10px", alignItems: "flex-start" }}
            >
              <CustomCheckbox>
                <input
                  type="checkbox"
                  id="accept"
                  name="accept"
                  value="accept"
                  {...register("accept", { required: true })}
                />
                <div className="checkbox" />
              </CustomCheckbox>
              <label for="accept">{t("reports_form_confirm_1")}</label>
            </div>
            {errors.accept && (
              <span style={{ color: "#ED1D24", fontSize: "14px" }}>
                {t("required_error")}
              </span>
            )}
            <br />
            <div
              style={{ display: "flex", gap: "10px", alignItems: "flex-start" }}
            >
              <CustomCheckbox>
                <input
                  type="checkbox"
                  id="data"
                  name="data"
                  value="data"
                  {...register("data", { required: true })}
                />
                <div className="checkbox" />
              </CustomCheckbox>
              <label for="data">{t("reports_form_confirm_2")}</label>
            </div>
            {errors.data && (
              <span style={{ color: "#ED1D24", fontSize: "14px" }}>
                {t("required_error")}
              </span>
            )}
            <br></br>
          </div>
          <CustomButton type="submit"> {t("reports_form_button")}</CustomButton>
          <ToastContainer
            position="bottom-center"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
            theme="dark"
          />
        </form>
      </Wrapper>
    </PageBackground>
  );
}

export default Denuncias;
