import React from "react";
import { BackgroundWrapper, Wrapper } from "./styles/home_styled";
import { useMediaQuery, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import NewsCard from "./components/newsCard";
import news from "../../resources/news";
import moment from "moment";

const buttonStyle = {
  width: "fit-content",
  padding: "0px 24px",
  borderRadius: "59px",
  border: "2px solid #110E9E",
  backgroundColor: "transparent",
  fontFamily: "Figtree",
  fontSize: "20px",
  fontWeight: 700,
  color: "#110E9E",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#EBEFF2",
  },
};

function News() {
  const { t } = useTranslation();
  const mobile = useMediaQuery("(max-width:768px)");
  const tablet = useMediaQuery("(max-width:1024px)");

  return (
    <BackgroundWrapper
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
        backgroundColor: "#FAFCFF",
        zIndex: 0,
        paddingTop: "40px",
      }}
    >
      <Wrapper>
        <h2
          style={{
            textTransform: "uppercase",
            color: "rgba(7, 20, 99, 0.50)",
            margin: 0,
            textAlign: mobile && "center",
          }}
        >
          {t("news_home")}
        </h2>
        <h4
          style={{
            color: "#071463",
            fontWeight: 500,
            marginTop: 0,
          }}
        >
          {t("news_home_subtitle")}
        </h4>
        <div
          style={{
            width: "100%",
            padding: !mobile && "0px 10px",
          }}
        >
          <div
            style={{
              background: "rgba(7, 20, 99, 0.20)",
              height: "10px",
              width: "100%",
              maxWidth: "100px",
            }}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: tablet ? "column" : "row",
              marginTop: "20px",
              justifyContent: "space-between",
              alignItems: tablet && "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                width: tablet ? "100%" : "55%",
              }}
            >
              {[...news]
                .sort((a, b) => {
                  if (
                    moment(a.date, "DD/MM/YYYY").isBefore(
                      moment(b.date, "DD/MM/YYYY")
                    )
                  )
                    return 1;
                  return -1;
                })
                .filter((_, i) => i < 3)
                .map((el, i) => {
                  return (
                    <>
                      {i > 0 && (
                        <div
                          style={{
                            height: "1px",
                            background: "#D3D3D3",
                          }}
                        />
                      )}
                      <NewsCard
                        title={el.title}
                        descriptions={el.descriptions}
                        img={el.img}
                        date={el.date}
                        link={el.link}
                        author={el.author}
                        big
                      />
                    </>
                  );
                })}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                width: tablet ? "100%" : "40%",
                marginTop: tablet && "20px",
              }}
            >
              <div>
                <div
                  style={{
                    height: "1px",
                    background: "#D3D3D3",
                  }}
                />
                <h2
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 900,
                    margin: "5px 0",
                  }}
                >
                  VEJA TAMBÉM
                </h2>
                <div
                  style={{
                    height: "1px",
                    background: "#D3D3D3",
                  }}
                />
              </div>
              {[...news]
                .sort((a, b) => {
                  if (
                    moment(a.date, "DD/MM/YYYY").isBefore(
                      moment(b.date, "DD/MM/YYYY")
                    )
                  )
                    return 1;
                  return -1;
                })
                .filter((_, i) => i >= 3 && i < 7)
                .map((el, i) => {
                  return (
                    <>
                      {i > 0 && (
                        <div
                          style={{
                            height: "1px",
                            background: "#D3D3D3",
                          }}
                        />
                      )}
                      <NewsCard
                        title={el.title}
                        descriptions={el.descriptions}
                        img={el.img}
                        date={el.date}
                        link={el.link}
                        author={el.author}
                      />
                    </>
                  );
                })}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              jusstifyContent: "center",
              margin: "90px 0px",
              width: "100%",
            }}
          >
            <Button href="/news" style={{ ...buttonStyle }}>
              Ver mais
            </Button>
          </div>
        </div>
      </Wrapper>
    </BackgroundWrapper>
  );
}

export default News;
