import {
  BrandBox,
  EventCircle,
  EventComponent,
  LineDots,
  YearDots,
} from "./timelineStyles";

function CustomEvent({ year, reverseCol, brands, centered }) {
  return (
    <EventComponent
      style={{ flexDirection: reverseCol ? "column-reverse" : "column", alignItems: centered ? "center" : "start" }}
    >
      <YearDots
        style={{ flexDirection: reverseCol ? "column-reverse" : "column" }}
      >
        <h3>{year}</h3>
        <LineDots style={{ height: reverseCol ? "72px" : "120px"}} />
        <EventCircle />
      </YearDots>
      <BrandBox>{brands}</BrandBox>
    </EventComponent>
  );
}

export default CustomEvent;
