import { useMediaQuery } from "@mui/material";
import React from "react";

function NewsCard({ title, descriptions, img, date, author, big, link }) {
  const mobile = useMediaQuery("(max-width:768px)");
  const tablet = useMediaQuery("(max-width:1024px)");

  return (
    <div
      onClick={() => {
        window.open(link, "_blank");
      }}
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        maxWidth: tablet ? "100vw" : big ? "60vw" : "40vw",
        position: "relative",
        height: "100%",
        width: "100%",
        cursor: "pointer",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          flexGrow: 1,
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h4
            style={{
              fontSize: big ? "1.15rem" : "1.1rem",
              fontWeight: 900,
              color: "#242424",
              margin: 0,
            }}
          >
            {title}
          </h4>
          <p
            style={{
              color: "#626262",
              fontSize: big ? "1rem" : "0.875rem",
              fontWeight: 300,
              wordBreak: "break-word",
              whiteSpace: "normal",
              margin: 0,
              display: "-webkit-box",
              WebkitLineClamp: big ? 6 : 4,
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
            }}
          >
            {descriptions.map((description, i) => {
              return (
                <p
                  key={i}
                  style={{
                    margin: 0,
                  }}
                >
                  {description}
                </p>
              );
            })}
          </p>
        </div>
        {mobile && (
          <img
            src={img}
            alt="news header"
            style={{
              position: "relative",
              maxWidth: "100%",
              objectFit: "contain",
            }}
          />
        )}
        <p
          style={{
            color: "#8E8E8E",
            fontWeight: 400,
            margin: 0,
            fontSize: big ? "0.875rem" : "0.795rem",
          }}
        >
          {date} | POR <span style={{ fontWeight: 600 }}>{author}</span>
        </p>
      </div>
      {!mobile && (
        <img
          src={img}
          alt="news header"
          style={{
            position: "relative",
            maxWidth: "45%",
            objectFit: "contain",
          }}
        />
      )}
    </div>
  );
}

export default NewsCard;
