import React from "react";
import { Link } from "react-router-dom";
import {
  AboutImageWrap,
  ImageText,
  MissionAccordion,
  MissionGrid,
  NavigationBar,
  PageBackground,
  Wrapper,
} from "./styles/aboutStyles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MissionBG from "../../resources/images/about/mission_bg.png";
import {
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
} from "@mui/material";
import CircleLeft from "../../resources/images/calltoaction/circle_left.svg";
import CircleRight from "../../resources/images/calltoaction/circle_right.svg";
import Blob from "../../resources/images/calltoaction/blob.svg";
import { BackgroundWrapper, CenteredContent } from "../home/styles/home_styled";

import { ReactComponent as AmbitionIcon } from "../../resources/icons/mission/ambition_icon.svg";
import { ReactComponent as HonestyIcon } from "../../resources/icons/mission/honesty_icon.svg";
import { ReactComponent as InnovationIcon } from "../../resources/icons/mission/innovation_icon.svg";
import { ReactComponent as LoyaltyIcon } from "../../resources/icons/mission/loyalty_icon.svg";
import { ReactComponent as PeopleIcon } from "../../resources/icons/mission/people_icon.svg";
import { ReactComponent as RigorIcon } from "../../resources/icons/mission/rigor_icon.svg";
import { ReactComponent as SocialIcon } from "../../resources/icons/mission/social_icon.svg";
import { ReactComponent as TransparencyIcon } from "../../resources/icons/mission/transparency_icon.svg";
import { useTranslation } from "react-i18next";
function Mission() {
  const [expanded, setExpanded] = React.useState(false);
  const { t } = useTranslation();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const small = useMediaQuery("(max-width:1024px)");
  return (
    <>
      <NavigationBar>
        <Wrapper>
          <div className="navigationrow">
            <Link to={"/about/history"}>
              <ArrowBackIosIcon />
              {t("history")}
            </Link>
            <Link to={"/about/contribution"}>
              {t("contribution")}
              <ArrowForwardIosIcon />
            </Link>
          </div>
        </Wrapper>
      </NavigationBar>
      <PageBackground style={{ minHeight: "60vh" }}>
        <Wrapper>
          <ImageText>
            <div className="textcontent" style={{ marginLeft: 0 }}>
              <h2 className="header"> {t("mission")}</h2>
              <h4 style={{ fontWeight: "500", color: "#110E9E" }}>
                {t("mission_mission")}
              </h4>
              <p style={{ color: "#110E9E" }}>{t("mission_mission_text")}</p>
              <h4 style={{ fontWeight: "500", color: "#110E9E" }}>
                {t("mission_vision")}
              </h4>
              <p style={{ color: "#110E9E" }}>{t("mission_vision_text")}</p>
            </div>
            <div style={small ? { display: "none" } : { width: "45%" }}></div>
          </ImageText>
        </Wrapper>
        <AboutImageWrap className="right" style={{ zIndex: 0 }}>
          <img src={MissionBG} alt="" />
        </AboutImageWrap>
      </PageBackground>
      <PageBackground style={{ zIndex: 1 }}>
        <Wrapper>
          <h4 style={{ fontWeight: "500", color: "#110E9E" }}>
            {t("mission_values")}
          </h4>
          <MissionGrid>
            <div className="column">
              <MissionAccordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="svgwrapper">
                    <TransparencyIcon id="different" />
                  </div>

                  <p>{t("mission_tab1")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="text">{t("mission_tab1_text")}</p>
                </AccordionDetails>
              </MissionAccordion>
              <MissionAccordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="svgwrapper">
                    <RigorIcon />
                  </div>
                  <p> {t("mission_tab2")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="text">{t("mission_tab2_text")}</p>
                </AccordionDetails>
              </MissionAccordion>
              <MissionAccordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="svgwrapper">
                    <AmbitionIcon />
                  </div>

                  <p> {t("mission_tab3")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="text">{t("mission_tab3_text")}</p>
                </AccordionDetails>
              </MissionAccordion>
              <MissionAccordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="svgwrapper">
                    <InnovationIcon />
                  </div>

                  <p> {t("mission_tab4")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="text">{t("mission_tab4_text")}</p>
                </AccordionDetails>
              </MissionAccordion>
            </div>
            <div className="column">
              <MissionAccordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="svgwrapper">
                    <LoyaltyIcon />
                  </div>

                  <p> {t("mission_tab5")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="text">{t("mission_tab5_text")}</p>
                </AccordionDetails>
              </MissionAccordion>
              <MissionAccordion
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="svgwrapper">
                    <HonestyIcon />
                  </div>

                  <p> {t("mission_tab6")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="text">{t("mission_tab6_text")}</p>
                </AccordionDetails>
              </MissionAccordion>
              <MissionAccordion
                expanded={expanded === "panel7"}
                onChange={handleChange("panel7")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="svgwrapper">
                    <SocialIcon />
                  </div>

                  <p> {t("mission_tab7")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="text">{t("mission_tab7_text")}</p>
                </AccordionDetails>
              </MissionAccordion>
              <MissionAccordion
                expanded={expanded === "panel8"}
                onChange={handleChange("panel8")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="svgwrapper">
                    <PeopleIcon />
                  </div>
                  <p> {t("mission_tab8")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="text">{t("mission_tab8_text")}</p>
                </AccordionDetails>
              </MissionAccordion>
            </div>
          </MissionGrid>
        </Wrapper>
      </PageBackground>
      <PageBackground>
        <BackgroundWrapper hide={true} className="calltoaction">
          <img
            src={CircleLeft}
            alt=""
            style={{ top: "-50%", left: "0" }}
            className={"hide"}
          />
          <img
            src={Blob}
            alt=""
            style={{ top: "-10%", left: "10%" }}
            className={"hide"}
          />
          <img src={CircleRight} alt="" style={{ top: "0", right: "0" }} />
          <Wrapper>
            <CenteredContent className="calltoaction">
              <h3 style={{ fontWeight: 500, color: "#fff" }}>
                {t("mission_banner")}
              </h3>
            </CenteredContent>
          </Wrapper>
        </BackgroundWrapper>
      </PageBackground>
    </>
  );
}

export default Mission;
