import styled from "styled-components";
import { Tabs, Tab } from "@mui/material";

export const Caption = styled("div")`
  position: absolute;
  left: 0;
  top: 50%;
  width: 50%;
  translate: 0 -50%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & h1 {
    margin-bottom: 0;
    line-height: 5rem;
  }
  & .link-button {
    display: flex;
    padding: 12px 16px;
    box-sizing: border-box;
    border: 2px solid #fff;
    border-radius: 30px;
    cursor: pointer;
    position: relative;
    background: linear-gradient(to left, transparent 50%, #fff 50%);
    background-size: 200% 100%;
    background-position: right center;
    transition: background-position 0.3s ease-in-out;
    &:hover {
      background-position: left center;
      color: #071463;
    }
  }
  @media (max-width: 428px) {
    width: 90%;
    & h1 {
      line-height: 2rem;
    }
  }
  @media (min-width: 430px) and (max-width: 1024px) {
    width: 70%;
    & h1 {
      line-height: 2rem;
    }
  }
`;
export const BackgroundWrapper = styled("div")`
  width: 100%;
  height: auto;
  position: relative;
  overflow: ${(props) => (props.hide ? "hidden" : "unset")};
  margin-top: 72px;
  & img {
    position: absolute;
  }
  &.team {
    height: 70vh;
    background-color: #fff;
    &.video {
      background-color: #071463;
      & ${Caption} {
        & h2 {
          color: #fff;
          &::after {
            background-color: #ffffff33;
          }
        }
        & p {
          color: #fff;
        }
      }
    }
    & img {
      object-fit: cover;
      height: 100%;
      right: 0;
    }

    & ${Caption} {
      position: relative;
      width: 50%;
      top: 50%;
      translate: 0 -50%;

      & h2 {
        color: #07146380;
        position: relative;
        &::after {
          height: 6px;
          width: 100px;
          content: "";
          display: block;
          position: absolute;
          background-color: #07146333;
          bottom: -20px;
          left: 0;
        }
      }
      & p {
        margin-bottom: 20px;
        color: #071463;
      }
      @media (max-width: 428px) {
        position: relative;
        width: 100%;
        top: unset;
        translate: unset;

        & h2 {
          line-height: 2rem;
        }
      }
      @media (min-width: 430px) and (max-width: 1024px) {
        width: 100%;
        position: relative;
        top: unset;
        translate: unset;
      }
    }

    @media (max-width: 428px) {
      height: auto;
      & img {
        height: 70%;
        opacity: 0.5;
      }
    }
    @media (min-width: 430px) and (max-width: 1024px) {
      height: auto;
      & img {
        height: 80%;
        opacity: 0.5;
      }
    }
  }
  &.calltoaction {
    background-color: #110e9e;
    /* height: 50vh; */

    & img {
      @media (max-width: 1024px) {
        &.hide {
          display: none;
        }
      }
    }
  }
  & .header {
    color: #07146380;
    position: relative;
    text-align: left;
    &::after {
      height: 6px;
      width: 100px;
      content: "";
      display: block;
      position: absolute;
      background-color: #07146333;
      bottom: -20px;
      left: 0;
    }
  }
`;
export const VideoWrapper = styled("div")`
  width: 60%;
  height: 100%;
  position: absolute;
  right: 0;
  mask-image: linear-gradient(
    90deg,
    rgba(7, 20, 99, 0) 0%,
    rgba(7, 20, 99, 1) 100%
  );
  & video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: grayscale(55%) sepia(20%) saturate(110%);
    -webkit-filter: grayscale(55%) sepia(20%) saturate(110%);
    -moz-filter: grayscale(55%) sepia(20%) saturate(110%);
  }
  @media (max-width: 1024px) {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    mask-image: unset;
  }
`;
export const Wrapper = styled("div")`
  width: 1400px;
  margin: 0 auto;
  position: relative;
  height: 100%;

  @media (max-width: 1440px) {
    width: 90vw;
  }
`;

export const PortfolioWrapper = styled("div")`
  width: 100%;
  height: 100%;
  background-color: #071463;
  padding: 50px 0;

  & .header {
    color: #ffffff80;
    position: relative;
    text-align: center;
    &::after {
      height: 6px;
      width: 100px;
      content: "";
      display: block;
      position: absolute;
      background-color: #ffffff33;
      bottom: -20px;
      left: 50%;
      translate: -50% 0;
    }
  }
`;
export const PortfolioTabs = styled(Tabs)`
  margin: 20px 0;
  &.MuiTabs-root {
    position: sticky;
    top: 72px;
    padding-top: 20px;
    background-color: #071463;
    z-index: 2;
  }
  & .MuiTabScrollButton-root {
    color: #fff;
  }
  display: flex;
  & .MuiTabs-indicator {
    display: none;
  }
`;
export const PortfolioTab = styled(Tab)`
  min-width: 150px !important;
  max-width: 200px !important;
  @media (max-width: 431px) {
    width: 60%;
  }
  &.Mui-selected {
    &.color_1 {
      color: #93a7f1;
      opacity: 1;
    }
    &.color_2 {
      color: #8bcded;
      opacity: 1;
    }
    &.color_3 {
      color: #80dcc9;
      opacity: 1;
    }
    &.color_4 {
      color: #c4f1ba;
      opacity: 1;
    }
    &.color_5 {
      color: #f0f0a7;
      opacity: 1;
    }
    &.color_6 {
      color: #fbc65f;
      opacity: 1;
    }
    &.color_7 {
      color: #ffa680;
      opacity: 1;
    }
    &.color_8 {
      color: #f3a1b7;
      opacity: 1;
    }
    &.color_9 {
      color: #dd6065;
      opacity: 1;
    }
  }

  &.color_1 {
    color: #93a7f1;
    opacity: 0.5;
  }
  &.color_2 {
    color: #8bcded;
    opacity: 0.5;
  }
  &.color_3 {
    color: #80dcc9;
    opacity: 0.5;
  }
  &.color_4 {
    color: #c4f1ba;
    opacity: 0.5;
  }
  &.color_5 {
    color: #f0f0a7;
    opacity: 0.5;
  }
  &.color_6 {
    color: #fbc65f;
    opacity: 0.5;
  }
  &.color_7 {
    color: #ffa680;
    opacity: 0.5;
  }
  &.color_8 {
    color: #f3a1b7;
    opacity: 0.5;
  }
  &.color_9 {
    color: #dd6065;
    opacity: 0.5;
  }
`;
export const PortfolioContent = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 20px;
`;
export const PortfolioCard = styled("div")`
  position: relative;
  overflow: hidden;
   box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
  & img {
    position: relative;
  }
  & .face {
    width: 300px;
    height: 330px;
    transition: 0.5s ease-in-out;
    

    &.face1 {
      position: relative;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);

      & .content {
        opacity: 1;
        transition: 0.5s;
        text-align: center;
        overflow: hidden;

        & h3 {
          color: #07146380;
          font-size: 50px;
        }

        & img {
          position: relative;
          object-fit: cover;
          width: 100%;
        }
      }
    }

    &.face2 {
      position: absolute;
      background: ${(props) => props.color};
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      z-index: 0;
      height: 0px;
      overflow: hidden;
      transform: translateY(200px);
      opacity: 0;

      & .content {
        box-sizing: border-box;
        padding: 20px;
        transition: 0.5s;
        text-align: left;
        height: 100%;
        display: flex;
        flex-direction: column;

        & .header {
          text-align: left;
          & h4 {
            font-size: 1.25rem;
            font-weight: 600;
            margin: 0;
            color: #fff;
          }
          & h5 {
            font-size: 1.125rem;
            font-weight: 500;
            margin: 0;
            color: #ffffff80;
          }

          &::after {
            width: 24px;
            left: 11px;
            bottom: -10px;
          }
        }
        & p {
          margin-top: 20px;
          color: #fff;
        }

        & a {
          text-decoration: none;
          color: #fff;
          font-weight: 600;
          text-align: center;
          margin-top: auto;
        }
        & .link {
          color: #ffffff80;
          font-weight: 600;
          text-align: center;
          margin-top: auto;
        }
      }
    }
  }
  &:hover {
    & .face.face2 {
      transform: translateY(0);
      opacity: 1;
      height: 330px;
    }
  }
`;
export const FlexCards = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin: 40px 0;
`;

export const BackgroundSecondaryWrapper = styled("div")`
  width: 100%;
  height: auto;
  position: relative;
  background-color: #071463;
  padding: 50px 0 100px 0;
  & img {
    position: absolute;
  }

  & .header {
    color: #ffffff80;
    position: relative;
    text-align: center;
    &::after {
      height: 6px;
      width: 100px;
      content: "";
      display: block;
      position: absolute;
      background-color: #ffffff33;
      bottom: -20px;
      left: 50%;
      translate: -50% 0;
    }
  }
`;
