import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Circle from "../../resources/images/hero/circle.svg";
import { Link, useSearchParams } from "react-router-dom";
import { NavigationBar } from "../about/styles/aboutStyles";
import {
  PageBackground,
  TabContent,
  TabItem,
  TabsWrapper,
  Wrapper,
} from "./styles/governmentStyles";
import { useTranslation } from "react-i18next";
import VipaConnectLogo from "../../resources/icons/portfolio/vipaconnect_icon.svg";
import SeehealthLogo from "../../resources/icons/portfolio/seehealth_icon.svg";
import { useMediaQuery } from "@mui/material";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <TabContent
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </TabContent>
  );
}
function Regulations() {
  const [value, setValue] = useState(0);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    i18n: { language: activeLang },
  } = useTranslation();

  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tablet = useMediaQuery("(max-width:1280px)");

  useEffect(() => {
    if (searchParams.get("tab") === "privacy") {
      setValue(1);
    } else if (searchParams.get("tab") === "conduct") {
      setValue(0);
    }
  }, [searchParams]);
  return (
    <>
      <NavigationBar>
        <Wrapper>
          <div className="navigationrow">
            <Link to={"/government/structure"}>
              <ArrowBackIosIcon />
              {t("structure")}
            </Link>
            <Link to={"/government"}>
              {t("identity")}
              <ArrowForwardIosIcon />
            </Link>
          </div>
        </Wrapper>
      </NavigationBar>
      <PageBackground style={{ minHeight: "70vh" }}>
        <img
          src={Circle}
          style={{ left: "-30%", top: "20%", width: "70%" }}
          alt=""
        />
        <img src={Circle} style={{ right: "-30%", top: "-200%" }} alt="" />
        <Wrapper>
          <h2 className="header"> {t("regulation")}</h2>
          <TabsWrapper
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={tablet && true}
            aria-label="scrollable prevent tabs example"
          >
            <TabItem label={t("regulation_tab2")} />
            <TabItem label={t("regulation_tab1")} />
            <TabItem label={t("regulation_tab3")} />
            <TabItem label={t("regulation_tab4")} />

            {/* <TabItem label={t("regulation_tab3")} {...a11yProps(2)} /> */}
          </TabsWrapper>
          <TabPanel value={value} index={0}>
            <h4>{t("regulation_tab2")}</h4>
            <p>{t("regulation_tab2_text")}</p>
            <p className="text_small"> {t("regulation_pdf")}</p>
            <a
              href={
                activeLang === "pt"
                  ? `https://vipacorp-docs.s3.eu-central-1.amazonaws.com/conduta.pdf`
                  : `https://vipacorp-docs.s3.eu-central-1.amazonaws.com/conduta_en.pdf`
              }
              target="_blank"
              className="pdf_file"
              rel="noreferrer noopener"
            >
              <FileCopyIcon />
              {t("regulation_tab2")}
            </a>
            <h4 style={{ marginTop: "50px" }}>{t("regulation_tab2_2")}</h4>
            <p>{t("regulation_tab2_text2")}</p>
            <p>{t("regulation_tab2_text3")}</p>
            <p className="text_small"> {t("regulation_pdf")}</p>
            <a
              href={
                activeLang === "pt"
                  ? `https://vipacorp-docs.s3.eu-central-1.amazonaws.com/conduta_assedio.pdf`
                  : `https://vipacorp-docs.s3.eu-central-1.amazonaws.com/conduta_assedio_en.pdf`
              }
              target="_blank"
              className="pdf_file"
              rel="noreferrer noopener"
            >
              <FileCopyIcon />
              {t("regulation_tab2_2")}
            </a>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <h4>{t("regulation_tab1")}</h4>
            <p>{t("regulation_tab1_text")}</p>
            <p className="text_small">{t("regulation_pdf")}</p>
            <a
              href={
                activeLang === "pt"
                  ? `https://vipacorp-docs.s3.eu-central-1.amazonaws.com/prevencao_branqueamento.pdf`
                  : `https://vipacorp-docs.s3.eu-central-1.amazonaws.com/prevencao_branqueamento_en.pdf`
              }
              target="_blank"
              className="pdf_file"
              rel="noreferrer noopener"
            >
              <FileCopyIcon />
              {t("regulation_tab1")}
            </a>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <h4>{t("regulation_tab3")}</h4>
            <p>{t("regulation_tab3_text")}</p>
            <div className="iconwrap">
              <img src={VipaConnectLogo} alt="" />
              <img src={SeehealthLogo} alt="" />
            </div>
            <p className="text_small"> {t("regulation_pdf")}</p>
            <a
              href={
                activeLang === "pt"
                  ? "https://vipacorp-docs.s3.eu-central-1.amazonaws.com/vipaconnectgender.pdf"
                  : "https://vipacorp-docs.s3.eu-central-1.amazonaws.com/vipaconnectgender_en.pdf"
              }
              target="_blank"
              rel="noreferrer"
              className="pdf_file"
            >
              <FileCopyIcon />
              {t("regulation_connect")}
            </a>
            <a
              href={
                activeLang === "pt"
                  ? "https://vipacorp-docs.s3.eu-central-1.amazonaws.com/seehealthgender.pdf"
                  : "https://vipacorp-docs.s3.eu-central-1.amazonaws.com/seehealthgender_en.pdf"
              }
              target="_blank"
              rel="noreferrer"
              className="pdf_file"
            >
              <FileCopyIcon />
              {t("regulation_seehealth")}
            </a>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <h4>{t("regulation_tab4")}</h4>
            <p>{t("regulation_tab4_text")}</p>
            <p className="text_small"> {t("regulation_pdf")}</p>
            <a
              href={
                activeLang === "pt"
                  ? `https://vipacorp-docs.s3.eu-central-1.amazonaws.com/Politica_de_Selecao_e_Avaliacao_Auditoria.pdf`
                  : `https://vipacorp-docs.s3.eu-central-1.amazonaws.com/Politica_de_Selecao_e_Avaliacao_Auditoria_en.pdf`
              }
              target="_blank"
              className="pdf_file"
              rel="noreferrer noopener"
            >
              <FileCopyIcon />
              {t("regulation_tab4")}
            </a>
          </TabPanel>
        </Wrapper>
      </PageBackground>
    </>
  );
}

export default Regulations;
