import React from "react";
import { Link } from "react-router-dom";
import {
  CardAbout,
  CardWrapperAbout,
  ImageFloat,
  ImageTextFloat,
  NavigationBar,
  PageBackground,
  Wrapper,
} from "./styles/aboutStyles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MissionBG from "../../resources/images/about/contribution_bg.png";
import PoliticBG from "../../resources/images/about/politic_bg.png";
import CircleLeft from "../../resources/images/calltoaction/circle_left.svg";
import CircleRight from "../../resources/images/calltoaction/circle_right.svg";
import CircleDivider from "../../resources/images/about/circle_divider.svg";
import Blob from "../../resources/images/calltoaction/blob.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as Group } from "../../resources/images/values/group.svg";
import { ReactComponent as Person } from "../../resources/images/values/person.svg";
import { ReactComponent as Files } from "../../resources/images/values/files.svg";
import { BackgroundWrapper, CenteredContent } from "../home/styles/home_styled";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

function Contribution() {
  const small = useMediaQuery("(max-width:1024px)");
  const { t } = useTranslation();
  return (
    <>
      <NavigationBar>
        <Wrapper>
          <div className="navigationrow">
            <Link to={"/about/mission"}>
              <ArrowBackIosIcon />
              {t("mission")}
            </Link>
            <Link to={"/about"}>
              {t("who")}
              <ArrowForwardIosIcon />
            </Link>
          </div>
        </Wrapper>
      </NavigationBar>
      <PageBackground>
        <Wrapper>
          <h2 className="header">{t("contribution")}</h2>
        </Wrapper>
      </PageBackground>
      <PageBackground style={{ height: "80vh" }}>
        {small && (
          <ImageFloat>
            <img src={MissionBG} alt="" />
          </ImageFloat>
        )}
        <ImageTextFloat>
          <div className="textcontent">
            <h4 style={{ fontWeight: "500", color: "#110E9E" }}>
              {t("contribution_hero")}
            </h4>
            <p style={{ color: "#110E9E" }}>{t("contribution_hero_text")}</p>
          </div>
        </ImageTextFloat>
        {!small && (
          <ImageFloat>
            <img src={MissionBG} alt="" />
          </ImageFloat>
        )}
      </PageBackground>
      <div
        style={{
          position: "relative",
          height: "100px",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <img
          src={CircleDivider}
          alt=""
          style={{ position: "absolute", right: 0, top: 0 }}
        />
      </div>
      <PageBackground style={{ height: "80vh", backgroundColor: "#071463" }}>
        <ImageFloat className="left">
          <img src={PoliticBG} alt="" />
        </ImageFloat>
        <ImageTextFloat className="right">
          <div className="textcontent">
            <h4 style={{ fontWeight: "500", color: "#110E9E" }}>
              {t("politic_title")}
            </h4>
            <p style={{ color: "#110E9E" }}>{t("politic_text")}</p>
          </div>
        </ImageTextFloat>
      </PageBackground>
      <PageBackground>
        <Wrapper>
          <h5 style={{ fontWeight: 500, color: "#071463", marginBottom: "0" }}>
            {t("consult")}
          </h5>
          <CardWrapperAbout>
            <CardAbout to={"/people"} className="color_2">
              <h4>{t("value_card5")}</h4>
              <p>{t("value_card5_sub")}</p>
              <div className={"linkicon"}>
                <ChevronRightIcon className="icon" />
              </div>
              <Person />
            </CardAbout>
            <CardAbout to={"/about"} className="color_1">
              <h4>{t("value_card1")}</h4>
              <p>{t("value_card1_sub")}</p>
              <div className={"linkicon"}>
                <ChevronRightIcon className="icon" />
              </div>
              <Group />
            </CardAbout>
            <CardAbout to={"/government/regulations"} className="color_3">
              <h4>{t("value_card6")}</h4>
              <p>{t("value_card6_sub")}</p>
              <div className={"linkicon"}>
                <ChevronRightIcon className="icon" />
              </div>
              <Files />
            </CardAbout>
          </CardWrapperAbout>
        </Wrapper>
      </PageBackground>
      <PageBackground>
        <BackgroundWrapper hide={true} className="calltoaction">
          <img
            src={CircleLeft}
            alt=""
            style={{ top: "-50%", left: "0" }}
            className={"hide"}
          />
          <img
            src={Blob}
            alt=""
            style={{ top: "-10%", left: "10%" }}
            className={"hide"}
          />
          <img src={CircleRight} alt="" style={{ top: "0", right: "0" }} />
          <Wrapper>
            <CenteredContent className="calltoaction">
              <h3 style={{ fontWeight: 500, color: "#fff" }}>
                {t("contribution_banner")}
              </h3>
            </CenteredContent>
          </Wrapper>
        </BackgroundWrapper>
      </PageBackground>
    </>
  );
}

export default Contribution;
