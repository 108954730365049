import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Circle from "../../resources/images/hero/circle.svg";
import { PageBackground, Wrapper } from "../government/styles/governmentStyles";
import {
  CustomAccordion,
  PoliticsWrapper,
  SideBar,
  TabContent,
  TabItem,
} from "./styles/privacy_styles";

function PrivacyPolicies() {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (searchParams.get("tab") === "terms") {
      setValue(1);
    } else if (searchParams.get("tab") === "privacy") {
      setValue(0);
    }
  }, [searchParams]);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <TabContent
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </TabContent>
    );
  }
  return (
    <PageBackground style={{ minHeight: "70vh" }}>
      <img
        src={Circle}
        style={{ left: "-30%", top: "20%", width: "70%" }}
        alt=""
      />
      <img src={Circle} style={{ right: "-10%", top: "-50%" }} alt="" />
      <Wrapper>
        <PoliticsWrapper>
          <SideBar
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
            aria-label="scrollable prevent tabs example"
          >
            <TabItem label={t("policy_title")} />
            <TabItem label={t("terms_title")} />
            <a
              href={"https://www.livroreclamacoes.pt/Inicio/"}
              target={"_blank"}
              rel="noreferrer"
              style={{
                padding: "12px 0 12px 16px",
                textDecoration: "none",
                fontWeight: 600,
                fontSize: "0.875rem",
                color: "#071463",
              }}
            >
              {t("footer_complaints")}
            </a>
            {/* <Link
            to={"/denuncias"}
            style={{
              padding: "12px 0 12px 16px",
              textDecoration: "none",
              fontWeight: 600,
              fontSize: "0.875rem",
              color: "#071463",
            }}
          >
            {t("reports_title")}
          </Link> */}

            {/* <TabItem label={t("regulation_tab3")} {...a11yProps(2)} /> */}
          </SideBar>
          <div className="content">
            <TabPanel value={value} index={0}>
              <h3 style={{ color: "#8389B1", marginBottom: 0 }}>
                {t("policy_title")}
              </h3>
              <p>{t("policy_validation")}</p>
              <CustomAccordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{t("policy_structure_title")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>
                    {t("policy_structure")} (
                    <a
                      href="www.vipacorporation.com"
                      target="_blank"
                      style={{ color: "#fff" }}
                    >
                      www.vipacorporation.com
                    </a>
                    ) .
                  </p>
                </AccordionDetails>
              </CustomAccordion>
              <CustomAccordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{t("policy_data_title")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{t("policy_data")}</p>
                  <ul>
                    <li>{t("policy_data_sub_title")}</li>
                    <p>{t("policy_data_sub")}</p>
                  </ul>
                </AccordionDetails>
              </CustomAccordion>
              <CustomAccordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{t("policy_legal_title")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{t("policy_legal")}</p>
                </AccordionDetails>
              </CustomAccordion>
              <CustomAccordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{t("policy_alternative_title")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{t("policy_alternative")}</p>
                </AccordionDetails>
              </CustomAccordion>
              <CustomAccordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{t("policy_content_title")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{t("policy_content_1")}</p>
                  <p>{t("policy_content_2")}</p>
                </AccordionDetails>
              </CustomAccordion>
              <CustomAccordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{t("policy_title")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{t("policy_content")}</p>
                </AccordionDetails>
              </CustomAccordion>
              <h4>{t("cookies_title")}</h4>
              <CustomAccordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{t("cookies_definition_title")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{t("cookies_definition")}</p>
                </AccordionDetails>
              </CustomAccordion>
              <CustomAccordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{t("cookies_type_title")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>
                    {t("cookies_type_static_id") +
                      t("cookies_type_static_content")}
                  </p>
                  <p>
                    {t("cookies_type_statistics_id") +
                      t("cookies_type_statistics_content")}
                  </p>
                  <p>
                    {t("cookies_type_analytics_id") +
                      t("cookies_type_analytics_content")}
                  </p>
                </AccordionDetails>
              </CustomAccordion>
              <CustomAccordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{t("cookies_duration_title")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{t("cookies_duration_content")}</p>
                  <p>
                    {t("cookies_duration_type_session_id")}
                    {t("cookies_duration_type_session_content")}
                  </p>
                  <p>
                    {t("cookies_duration_type_permanent_id")}
                    {t("cookies_duration_type_permanent_content")}
                  </p>
                  <p>
                    {t("cookies_duration_type_service_id")}
                    {t("cookies_duration_type_service_content")}
                  </p>
                </AccordionDetails>
              </CustomAccordion>
              <CustomAccordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{t("cookies_thirdparty_title")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{t("cookies_thirdparty_content")}</p>
                  <p>{t("cookies_thirdparty_google")}</p>
                  <p>{t("cookies_thirdparty_youtube")}</p>
                  <p>{t("cookies_thirdparty_facebook")}</p>
                </AccordionDetails>
              </CustomAccordion>
              <CustomAccordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{t("cookies_block_title")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{t("cookies_block_content_1")}</p>
                  <p>{t("cookies_block_explorer")}</p>
                  <p>{t("cookies_block_safari")}</p>
                  <p>{t("cookies_block_chrome")}</p>
                  <p>{t("cookies_block_firefox")}</p>
                  <p>{t("cookies_block_opera")}</p>
                  <p>{t("cookies_block_android")}</p>
                  <p>{t("cookies_block_windowsphone")}</p>
                  <p>{t("cookies_block_blackberry")}</p>
                  <p>{t("cookies_block_ios")}</p>
                  <p>{t("cookies_block_androidchrome")}</p>
                  <p>{t("cookies_block_androidfirefox")}</p>
                  <p>{t("cookies_block_content_2")}</p>
                  <p>{t("cookies_block_content_3")}</p>
                </AccordionDetails>
              </CustomAccordion>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <h3 style={{ color: "#8389B1", marginBottom: 0 }}>
                {t("terms_title")}
              </h3>
              <p>{t("terms_validation")}</p>

              <CustomAccordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{t("terms_copyright_title")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{t("terms_copyright_content_1")}</p>
                  <p>{t("terms_copyright_content_2")}</p>
                  <p>{t("terms_copyright_content_3")}</p>
                  <p>{t("terms_copyright_content_4")}</p>
                  <p>{t("terms_copyright_content_5")}</p>
                  <p>{t("terms_copyright_content_6")}</p>
                </AccordionDetails>
              </CustomAccordion>
              <CustomAccordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{t("terms_hiperlink_title")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{t("terms_hiperlink_content_1")}</p>
                  <p>{t("terms_hiperlink_content_2")}</p>
                </AccordionDetails>
              </CustomAccordion>
              <CustomAccordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{t("terms_use_title")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{t("terms_use_content_1")}</p>
                  <p>{t("terms_use_content_2")}</p>
                  <p>{t("terms_use_content_3")}</p>
                </AccordionDetails>
              </CustomAccordion>
              <CustomAccordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{t("terms_action_title")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{t("terms_action_content_1")}</p>
                  <p>{t("terms_action_content_2")}</p>
                  <p>{t("terms_action_content_3")}</p>
                </AccordionDetails>
              </CustomAccordion>
              <CustomAccordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{t("terms_legal_title")}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{t("terms_legal_content")}</p>
                </AccordionDetails>
              </CustomAccordion>
            </TabPanel>
          </div>
        </PoliticsWrapper>
      </Wrapper>
    </PageBackground>
  );
}

export default PrivacyPolicies;
