import { useMediaQuery } from "@mui/material";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import TeamBG from "../../resources/images/about/politic_bg.png";
import Circle from "../../resources/images/hero/circle.svg";
import CapacityBG from "../../resources/images/people/capacity_bg.png";
import OpportunitiesBG from "../../resources/images/people/opportunities_bg.png";
import PeopleBG from "../../resources/images/people/people_hero.png";
import {
  PageBackground,
  PageHero,
  Parallax,
} from "../about/styles/aboutStyles";
import { Wrapper } from "../home/styles/home_styled";
import {
  Caption,
  ColumnTitle,
  ExpandTrigger,
  ExpandableBackground,
  ImageFloat,
  ImageTextFloat,
} from "./styles/peopleStyles";

function People() {
  // const [expand, setExpand] = useState(true);
  const ExpandRef = useRef();
  const mobile = useMediaQuery("(max-width:431px)");
  const tablet = useMediaQuery("(max-width:1280px)");
  const { t } = useTranslation();
  // useEffect(() => {
  //   if (!expand) {
  //     const observer = new IntersectionObserver((entries, observer) => {
  //       const entry = entries[0];
  //       setExpand(entry.isIntersecting);
  //     });
  //     observer.observe(ExpandRef.current);
  //   }
  //   return;
  // }, [expand]);
  return (
    <>
      <PageHero>
        <Parallax image={PeopleBG} />
        <Wrapper>
          <Caption>
            <h1>{t("people")}</h1>
            <p>{t("people_hero_sub")}</p>
          </Caption>
        </Wrapper>
      </PageHero>
      <PageBackground
        style={
          mobile
            ? { minHeight: "35vh" }
            : tablet
            ? { minheight: "25vh" }
            : { minHeight: "45vh" }
        }
      >
        <ExpandableBackground className={"active"} />
        <Wrapper>
          <ColumnTitle className={"active"}>
            <div className="rowcontent">
              <div className="textwrapper">
                <div
                  className="text-lines"
                  id="left"
                  style={{
                    translate: "0 20px",
                  }}
                >
                  {t("people_sub1")}
                  <div className="column">
                    <div className="horizontal"></div>
                    <div
                      className="vertical"
                      style={{
                        height: "40px",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div
                className="textwrapper"
                style={{
                  width: tablet && "45%",
                }}
              >
                <div className="text-lines" id="bottom">
                  <div className="column">
                    <div
                      className="horizontal"
                      style={{ width: tablet ? "20px" : "45px" }}
                    ></div>
                    <div
                      className="vertical"
                      style={{ height: tablet ? "40px" : "60px" }}
                    ></div>
                  </div>
                  <p style={{ alignSelf: "flex-start" }}>{t("people_sub2")}</p>
                </div>
              </div>
            </div>
            <h2
              style={{
                fontSize: mobile ? "28px" : tablet ? "42px" : "64px",
                margin: 0,
              }}
            >
              {t("people_title")}
              <b>{t("people_title2")}</b>
              {t("people_title3")}
              <b>{t("people_title4")}</b>
              {t("people_title5")}
              <b>{t("people_title6")}</b>
            </h2>
            <div className="rowcontent">
              <div
                className="textwrapper"
                id="bottom"
                style={{ width: tablet && "70%", marginLeft: tablet && "auto" }}
              >
                <div className="text-lines" id="bottom">
                  <div className="column">
                    <div className="vertical"></div>
                    <div
                      className="horizontal"
                      style={{ width: tablet ? "20px" : "45px" }}
                    ></div>
                  </div>
                  <p>{t("people_sub3")}</p>
                </div>
              </div>
            </div>
          </ColumnTitle>
        </Wrapper>
        <ExpandTrigger ref={ExpandRef} />
      </PageBackground>
      <PageBackground
        style={{
          minHeight: "60vh",
          display: tablet && "flex",
          alignItems: tablet && "center",
          justifyContent: tablet && "center",
        }}
      >
        <img
          src={Circle}
          alt=""
          style={{
            position: "absolute",
            top: "-30%",
            right: "-20%",
            height: "700px",
          }}
        />
        <ImageFloat className="left gradient">
          <img src={TeamBG} alt="" />
        </ImageFloat>
        <ImageTextFloat
          className="right"
          style={{
            height: mobile ? "fit-content" : tablet ? "inherit" : "",
            width: mobile ? "100%" : tablet ? "55%" : "",
          }}
        >
          <div className="textcontent">
            <h4 style={{ fontSize: tablet && "28px" }}>
              {t("people_sec1_title")}
            </h4>
            <p style={{ color: "#071463", fontSize: tablet ? "16px" : "22px" }}>
              {t("people_sec1_text")}
            </p>
          </div>
        </ImageTextFloat>
      </PageBackground>
      <PageBackground
        style={{
          minHeight: "60vh",
          marginTop: "80px",
          display: tablet && "flex",
          alignItems: tablet && "center",
          justifyContent: tablet && "center",
        }}
      >
        <img
          src={Circle}
          alt=""
          style={{
            position: "absolute",
            top: "0",
            left: "-20%",
            height: "700px",
          }}
        />
        <ImageFloat className="right gradient-left">
          <img src={CapacityBG} alt="" />
        </ImageFloat>
        <ImageTextFloat
          style={{
            height: mobile ? "fit-content" : tablet ? "inherit" : "",
            width: mobile ? "100%" : tablet ? "55%" : "",
          }}
        >
          <div className="textcontent">
            <h4 style={{ fontSize: tablet && "28px" }}>
              {t("people_sec2_title")}
            </h4>
            <p style={{ color: "#071463", fontSize: tablet ? "16px" : "22px" }}>
              {t("people_sec2_text")}
            </p>
          </div>
        </ImageTextFloat>
      </PageBackground>
      <PageBackground
        style={{
          minHeight: "60vh",
          marginTop: "80px",
          display: tablet && "flex",
          alignItems: tablet && "center",
          justifyContent: tablet && "center",
        }}
      >
        <ImageFloat className="left gradient">
          <img src={OpportunitiesBG} alt="" />
        </ImageFloat>
        <ImageTextFloat
          className="right centered"
          style={{
            height: mobile ? "fit-content" : tablet ? "50vh" : "",
            width: mobile ? "100%" : tablet ? "55%" : "",
          }}
        >
          <div className="textcontent">
            <h5
              style={{
                color: "#110E9E",
                fontSize: tablet ? "24px" : "28px",
                textAlign: mobile ? "center" : "left",
              }}
            >
              {t("people_sec3_text")}
            </h5>
          </div>
        </ImageTextFloat>
      </PageBackground>
    </>
  );
}

export default People;
