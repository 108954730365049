import { useEffect, useRef, useState } from "react";
import AnthonyLogo from "../../../../resources/icons/portfolio/anthony_icon.svg";
import BiomaskLogo from "../../../../resources/icons/portfolio/biomask_icon.svg";
import HWLLogo from "../../../../resources/icons/portfolio/hwl_icon.svg";
import IlhaPneuLogo from "../../../../resources/icons/portfolio/ilhapneu_icon.png";
import NMDSLogo from "../../../../resources/icons/portfolio/nmds_icon.png";
import BioReboot from "../../../../resources/icons/portfolio/bioreboot-logo.png";
import PneuzarcoLogo from "../../../../resources/icons/portfolio/pneuzarco_icon.svg";
import PoderLogo from "../../../../resources/icons/portfolio/poder_icon.png";
import SeehealthLogo from "../../../../resources/icons/portfolio/seehealth_icon.png";
import VCapital from "../../../../resources/icons/portfolio/vcapital-icon.svg";
import VipaconnectLogo from "../../../../resources/icons/portfolio/vipaconnect_icon.svg";
import VipaenergyLogo from "../../../../resources/icons/portfolio/vipaenergy_icon.svg";
import VipainternationalLogo from "../../../../resources/icons/portfolio/vipainternational_icon.svg";
import VipaskyLogo from "../../../../resources/icons/portfolio/vipasky_icon.svg";
import VipatelecomLogo from "../../../../resources/icons/portfolio/vipatelecom_icon.png";
import VipaCorpLogo from "../../../../resources/icons/vipacorp.svg";
import CustomBrand from "./customBrand";
import CustomEvent from "./customEvent";
import { EventsLine, TimelineRow } from "./timelineStyles";

function CustomTimeline() {
  const [isDragging, setIsDragging] = useState(false);
  const timelineRef = useRef(null);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    startX.current = e.pageX - timelineRef.current.offsetLeft;
    scrollLeft.current = timelineRef.current.scrollLeft;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - timelineRef.current.offsetLeft;
    const walk = (x - startX.current) * 1;
    timelineRef.current.scrollLeft = scrollLeft.current - walk;
  };

  useEffect(() => {
    const timelineElement = timelineRef.current;

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const scrollInterval = setInterval(() => {
            scrollLeft.current += 1;
            timelineElement.scrollLeft = scrollLeft.current;
          }, 20);

          const stopScrolling = () => {
            clearInterval(scrollInterval);
          };

          observer.unobserve(timelineElement);
          timelineElement.addEventListener("mousedown", stopScrolling);

          return () => {
            clearInterval(scrollInterval);
            timelineElement.removeEventListener("mousedown", stopScrolling);
          };
        }
      });
    };
    const observer = new IntersectionObserver(callback, options);
    observer.observe(timelineElement);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <TimelineRow
      ref={timelineRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      <EventsLine />
      <CustomEvent
        year={"2009"}
        brands={
          <>
            <CustomBrand
              link={"https://www.vipa.pt"}
              imgSrc={VipaCorpLogo}
              brandName={"Vipa"}
              brandSub={"Creating Bridges Building theFuture"}
            />
            <CustomBrand
              link={"https://www.vcapital.pt"}
              imgSrc={VCapital}
              brandName={"VCapital"}
              brandSub={"Empowering the Future"}
            />
            <CustomBrand
              link={"https://www.ilhapneu.pt"}
              imgSrc={IlhaPneuLogo}
              brandName={"Ilha Pneu"}
              brandSub={"A Escolha Certa"}
            />
          </>
        }
      />
      <CustomEvent
        year={"2010"}
        reverseCol={true}
        centered={true}
        brands={
          <>
            <CustomBrand
              link={"https://www.vipainternational.com"}
              imgSrc={VipainternationalLogo}
              brandName={"Vipa International"}
              brandSub={"Moving you Forward"}
            />
          </>
        }
      />
      <CustomEvent
        year={"2014"}
        centered={true}
        brands={
          <>
            <CustomBrand
              link={"https://www.vipatelecom.com"}
              imgSrc={VipatelecomLogo}
              brandName={"Vipa Telecom"}
              brandSub={"Stay Connected"}
            />
          </>
        }
      />
      <CustomEvent
        year={"2015"}
        reverseCol={true}
        centered={true}
        brands={
          <>
            <CustomBrand
              link={"https://www.pneuzarco.pt"}
              imgSrc={PneuzarcoLogo}
              brandName={"Pneuzarco"}
              brandSub={"O Especialista do pneu"}
            />
          </>
        }
      />
      <CustomEvent
        year={"2017"}
        centered={true}
        brands={
          <>
            <CustomBrand imgSrc={AnthonyLogo} brandName={"St Anthony"} />
          </>
        }
      />
      <CustomEvent
        year={"2018"}
        centered={true}
        reverseCol={true}
        brands={
          <>
            <CustomBrand
              link={"https://www.poderenegocios.pt"}
              imgSrc={PoderLogo}
              brandName={"Poder & Negócios"}
            />
          </>
        }
      />
      <CustomEvent
        year={"2019"}
        brands={
          <>
            <CustomBrand
              link={"https://vipa.pt"}
              imgSrc={VipaconnectLogo}
              brandName={"Vipa.pt"}
              brandSub={"Laboratório de Software"}
            />
            <CustomBrand
              link={"https://www.hwl.pt"}
              imgSrc={HWLLogo}
              brandName={"Homes With Love"}
              brandSub={"Invista numa casa com amor"}
            />
          </>
        }
      />
      <CustomEvent
        year={"2020"}
        reverseCol={true}
        brands={
          <>
            <CustomBrand
              link={"https://www.seehealth.pt"}
              imgSrc={SeehealthLogo}
              brandName={"See Health"}
              brandSub={"A olhar pela sua saúde"}
            />
            <CustomBrand
              link={"https://www.vipaenergy.com"}
              imgSrc={VipaenergyLogo}
              brandName={"Vipa Energy"}
              brandSub={"In motion for the future"}
            />
          </>
        }
      />
      <CustomEvent
        year={"2021"}
        brands={
          <>
            <CustomBrand
              link={"https://www.nmds.pt"}
              imgSrc={NMDSLogo}
              brandName={"Números Distintos"}
              brandSub={"Gestão Financeira e Consultoria"}
            />
            <CustomBrand
              link={""}
              imgSrc={VipaskyLogo}
              brandName={"Vipa Sky"}
            />
            <CustomBrand
              link={"https://www.thebiomaskcompany.com"}
              imgSrc={BiomaskLogo}
              brandName={"Biomask"}
            />
          </>
        }
      />
      <CustomEvent
        year={"2023"}
        reverseCol={true}
        brands={
          <>
            <CustomBrand link={""} imgSrc={BioReboot} brandName={"BioReboot"} />
          </>
        }
      />
    </TimelineRow>
  );
}

export default CustomTimeline;
