import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BackgroundWrapper, Wrapper } from "./styles/home_styled";
import NewsCard from "./components/newsCard";
import { NavigationBar } from "../about/styles/aboutStyles";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import news from "../../resources/news";
import moment from "moment";

function NewsPage() {
  const { t } = useTranslation();
  const mobile = useMediaQuery("(max-width:768px)");
  const tablet = useMediaQuery("(max-width:1024px)");

  return (
    <>
      <NavigationBar>
        <Wrapper>
          <div className="navigationrow">
            <Link to={"/"}>
              <ArrowBackIosIcon />
              {t("homepage")}
            </Link>
          </div>
        </Wrapper>
      </NavigationBar>
      <BackgroundWrapper
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          backgroundColor: "#FAFCFF",
          zIndex: 0,
          paddingTop: "10px",
        }}
      >
        <Wrapper>
          <h2
            style={{
              textTransform: "none",
              color: "rgba(7, 20, 99, 0.50)",
              margin: 0,
              textAlign: mobile && "center",
            }}
          >
            {t("news_home")}
          </h2>
          <p
            style={{
              color: "#071463",
              fontSize: "1.5rem",
              fontWeight: 500,
              marginTop: 0,
            }}
          >
            {t("news_home_subtitle")}
          </p>
          <div
            style={{
              width: "100%",
              padding: !mobile && "0px 10px",
            }}
          >
            <div
              style={{
                background: "rgba(7, 20, 99, 0.20)",
                height: "10px",
                width: "100%",
                maxWidth: "100px",
              }}
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: tablet ? "column" : "row",
                marginTop: "20px",
                justifyContent: "space-between",
                alignItems: tablet && "center",
                gap: tablet ? "0px" : "20px",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: mobile ? "100%" : "50% 50%",
                  gap: "20px",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                {[...news]
                  .sort((a, b) => {
                    if (
                      moment(a.date, "DD/MM/YYYY").isBefore(
                        moment(b.date, "DD/MM/YYYY")
                      )
                    )
                      return 1;
                    return -1;
                  })
                  .map((el, i) => {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        <NewsCard
                          title={el.title}
                          descriptions={el.descriptions}
                          img={el.img}
                          date={el.date}
                          link={el.link}
                          author={el.author}
                          big
                        />
                        <div
                          style={{
                            height: "1px",
                            background: "#D3D3D3",
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </Wrapper>
      </BackgroundWrapper>
    </>
  );
}

export default NewsPage;
