import { Accordion, Tabs, Tab } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const PageHero = styled("div")`
  position: relative;
`;
export const Wrapper = styled("div")`
  width: 1440px;
  margin: 0 auto;
  height: 100%;
  position: relative;

  @media (max-width: 1440px) {
    width: 90%;
  }
`;
export const Parallax = styled("div")`
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => props.image});
  width: 100%;
  height: 500px;
  padding-bottom: 40px;
`;
export const Caption = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 70%;
  justify-content: flex-end;
  position: absolute;
  top: -40px;
  color: #fff;
  & h1 {
    margin-bottom: 0;
  }
`;
export const PageBackground = styled("div")`
  position: relative;
  width: 100%;
  overflow: hidden;
  img {
    position: absolute;
  }

  & .header {
    margin-top: 0;
    color: #07146380;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      height: 6px;
      width: 80px;
      bottom: -10px;
      left: 0;
      background-color: #07146333;
      display: block;
    }
  }
  @media (max-width: 1024px) {
    height: auto !important;
  }
`;
export const RowBetween = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 50px;
  gap: 40px;

  & .text {
    width: 50%;
    text-align: justify;
    color: #071463;
  }
  & .navigation {
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    & .item {
      display: flex;
      background: linear-gradient(to top, #110e9e 50%, #fff 50%);
      background-size: 100% 200%;
      background-position: top center;
      transition: background-position 0.3s ease-in-out;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
      justify-content: space-between;
      align-items: center;
      color: #110e9e;
      text-decoration: none;
      font-weight: 500;
      box-sizing: border-box;
      padding: 16px;
      cursor: pointer;

      &:hover {
        background-position: bottom center;
        color: #fff;
      }
    }
  }
  & .pdf_file {
    color: #110e9e;
    text-decoration: underline;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;

    & svg {
      color: #07146333;
    }
    &:hover {
      color: #ff7f37;
      & svg {
        color: #ff7f37;
      }
    }
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 40px;
    & .text {
      width: 100%;
    }
    & .navigation {
      max-width: unset;
    }
  }
`;
export const TeamSection = styled("div")`
  position: relative;
  width: 100%;
  margin: 100px 0;
`;
export const TeamWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
`;
export const TeamCard = styled("div")`
  width: calc(100% / 4 - 20px);
  display: flex;
  flex-direction: column;

  & .imgwrapper {
    height: 450px;
    width: 100%;
    & img {
      position: relative;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  & h4 {
    margin: 0;
    color: #110e9e;
  }
  & p {
    margin: 0;
    color: #07146380;
    font-weight: 600;
    text-transform: uppercase;
  }
  @media (min-width: 481px) and (max-width: 1024px) {
    width: calc(100% / 2 - 20px);

    & .imgwrapper {
      & img {
        position: relative;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  @media (max-width: 480px) {
    width: 100%;

    & .imgwrapper {
      height: 250px;
      & img {
        position: relative;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
`;
export const NavigationBar = styled("div")`
  width: 100%;
  background-color: #fafbfd;
  height: 100px;
  margin-top: 75px;
  position: relative;
  top: 0;
  z-index: 2;

  & .navigationrow {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;

    & a {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-weight: 500;
      color: #071463;
    }
  }
  @media (max-width: 431px) {
    width: 100%;
    height: 90px;

    & .navigationrow {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      & a {
        font-size: 14px;
        &:nth-child(2) {
          align-self: flex-end;
        }
      }
    }
  }
`;
export const ImageText = styled("div")`
  display: flex;
  align-items: center;
  height: 100%;
  &.history {
    & .textcontent {
      background-color: transparent;
      color: #fff;
      text-align: left;
      & .header {
        color: #ffffff80;

        &::after {
          background-color: #ffffff33;
        }
      }
    }
  }
  & .textcontent {
    margin-left: auto;
    width: 50%;
    height: 100%;
    background-color: #fff;
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 432px) and (max-width: 1024px) {
      width: 80%;
      position: relative;
      display: block;
      padding: 20px 0;
      background-color: transparent;
    }
    @media (max-width: 431px) {
      width: 100%;
      position: relative;
      display: block;
      padding: 20px 0;
      background-color: transparent;
    }
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    &.history {
      & .textcontent {
        width: 50%;
      }
    }
  }
  @media (max-width: 768px) {
    &.history {
      padding: 20px 0;
      & .textcontent {
        width: 100%;
        color: #071463;

        & .header {
          color: #07146380;

          &::after {
            background-color: #07146333;
          }
        }
      }
    }
  }
`;
export const AboutImageWrap = styled("div")`
  width: 45%;
  height: 100%;
  position: absolute;
  &.history {
    width: 100%;

    & img {
      object-fit: cover;
    }
  }
  & img {
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  & svg {
    position: absolute;
    top: 50%;
    left: 25%;
    translate: -25% -50%;
    fill: "#fff";
    width: 250px;
    height: 250px;
  }
  &.right {
    top: 0;
    right: 0;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 50vh;
    position: relative;

    & img {
      object-fit: cover;
    }
    & svg {
      width: 150px;
      height: 150px;
      left: 50%;
      translate: -50% -50%;
    }
    &.right {
      position: absolute;
      z-index: -1;
      bottom: 0;
      top: unset;
      opacity: 0.5;
    }
  }
`;
export const MissionGrid = styled("div")`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
  & .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 2 - 20px);
    gap: 20px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    & .column {
      width: 100%;
    }
  }
`;
export const MissionAccordion = styled(Accordion)`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06) !important;
  border-top: 0;
  transition: 0.3s ease-in-out;
  font-size: 16px;
  box-sizing: border-box;
  padding: 0 16px;
  & .text {
    font-size: 14px;
    font-weight: 400;
  }
  &.Mui-expanded {
    background-color: #110e9e;
    color: #ffffff;
    & svg {
      color: #ffffff;
    }
  }
  &.MuiAccordion-root {
    &::before {
      background-color: transparent;
    }
  }
  & .MuiAccordionSummary-content {
    align-items: center;
    gap: 20px;
    & .svgwrapper {
      width: 32px;
      height: 32px;
      & svg {
        object-fit: contain;
        width: 100%;
        height: 100%;
        fill: #c5c8d8;
      }
      & #different {
        stroke: #c5c8d8;
        stroke-width: 3;
      }
    }
  }
  &:hover {
    color: #ff7f37;

    & svg {
      color: #ff7f37;
    }
    & .MuiAccordionSummary-content {
      align-items: center;
      gap: 20px;
      & svg {
        width: 32px;
        height: 32px;
        fill: #ff7f37;
      }
    }
    &.Mui-expanded {
      color: #ffffff;
      & svg {
        color: #ffffff;
      }
    }
  }
`;
export const ImageTextFloat = styled("div")`
  position: absolute;
  left: 0;
  top: 50%;
  translate: 0 -50%;
  width: 50vw;
  background-color: #fff;
  z-index: 1;
  padding: 24px 40px 24px 24px;
  box-sizing: border-box;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
  & .textcontent {
    position: relative;
    margin-left: calc(100% - 1400px / 2);
    & p {
      text-align: justify;
    }
    & h4 {
      margin-top: 0;
    }
  }
  &.right {
    left: unset;
    right: 0;
    padding: 24px 24px 24px 40px;
    & .textcontent {
      margin-left: unset;
      margin-right: calc(100% - 1400px / 2);
      & h4 {
        position: relative;
        margin-bottom: 50px;
        &::after {
          content: "";
          display: block;
          height: 6px;
          width: 100px;
          background-color: #07146333;
          position: absolute;
          left: 0;
          bottom: -20px;
        }
      }
    }
  }
  @media (min-width: 769px) and (max-width: 1450px) {
    & .textcontent {
      margin: unset;
    }
    &.right {
      & .textcontent {
        margin: unset;
      }
    }
  }
  @media (max-width: 768px) {
    position: relative;
    top: unset;
    left: unset;
    width: 100%;
    padding: 0;
    translate: unset;
    & .textcontent {
      width: 90%;
      margin: 0 auto;
      padding: 20px 0;
    }
    &.right {
      width: 100%;
      padding: 0;
      translate: unset;
      & .textcontent {
        width: 90%;
        margin: 0 auto;
        padding: 20px 0;
      }
    }
  }
`;
export const ImageFloat = styled("div")`
  position: absolute;
  right: 0;
  top: 0;
  width: 60%;
  height: 100%;
  background-color: #fff;
  z-index: 0;
  &.left {
    right: unset;
    left: 0;
    width: 70%;
  }
  & img {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1024px) {
    position: relative;
    width: 100%;

    &.left {
      width: 100%;
    }
  }
`;
export const CardWrapperAbout = styled("div")`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  padding-top: 50px;
  padding-bottom: 100px;
  @media (max-width: 428px) {
    flex-direction: column;
  }
  @media (min-width: 430px) and (max-width: 1024px) {
    flex-direction: row;
  }
`;
export const CardAbout = styled(Link)`
  width: calc(80% / 3 - 20px);
  height: 300px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  z-index: 0;
  transition: background-color 0.3s ease-in;
  & h4 {
    margin: 0;
    position: relative;
    z-index: 1;
    &::after {
      content: "";
      display: block;
      height: 5px;
      width: 24px;
      position: absolute;
      bottom: -20px;
      left: 0;
      z-index: 1;
    }
  }
  & p {
    margin-top: 40px;
    color: #fff;
    opacity: 0;
    transition: opacity 0.3s ease-in;
    position: relative;
    z-index: 1;
  }
  & svg {
    z-index: -1;
    transition: fill 0.3s ease-in-out;
  }
  & .linkicon {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 0;
    & .icon {
      position: relative;
      fill: #fff;
      font-size: 32px;
      display: block;
      top: 0;
      left: 0;
    }
  }
  &.color_1 {
    & h4 {
      color: #110e9e;
      &::after {
        background-color: #a09fd8;
      }
    }
    & svg {
      position: absolute;
      bottom: 0;
      fill: #2926a8;
      opacity: 0.1;
    }
  }

  &.color_2 {
    & h4 {
      color: #ff8f5f;
      &::after {
        background-color: #ffbc9f;
      }
    }
    & svg {
      position: absolute;
      right: 0;
      translate: -50% 0;
      bottom: -20px;
      fill: #ff9a6f;
      opacity: 0.1;
    }
  }

  &.color_3 {
    & h4 {
      color: #f6e58a;
      &::after {
        background-color: #fbf5d0;
      }
    }
    & svg {
      position: absolute;
      bottom: -20px;
      right: -20px;
      fill: #f8e78e;
      opacity: 0.1;
    }
  }
  &:hover {
    & .linkicon {
      display: block;
    }
    & h4 {
      color: #fff;
    }
    & p {
      opacity: 1;
    }
    &.color_1 {
      background-color: #110e9e;
      & svg {
        opacity: 1;
      }
    }
    &.color_2 {
      background-color: #ff8f5f;
      & svg {
        opacity: 1;
      }
    }
    &.color_3 {
      background-color: #f7e481;
      & svg {
        opacity: 1;
      }
    }
  }
  @media (max-width: 428px) {
    width: 100%;
    & .linkicon {
      display: block;
    }
    & h4 {
      color: #fff;
    }
    & p {
      opacity: 1;
    }
    &.color_1 {
      & h4 {
        color: #fff;
      }
      background-color: #110e9e;
      & svg {
        opacity: 1;
      }
    }
    &.color_2 {
      & h4 {
        color: #fff;
      }
      background-color: #ff8f5f;
      & svg {
        opacity: 1;
      }
    }
    &.color_3 {
      & h4 {
        color: #fff;
      }
      background-color: #f7e481;
      & svg {
        opacity: 1;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    width: calc(100% / 2 - 20px);
  }
  @media (min-width: 430px) and (max-width: 1024px) {
    width: calc(100% / 2 - 20px);
    & .linkicon {
      display: block;
    }
    & h4 {
      color: #fff;
    }
    & p {
      opacity: 1;
    }
    &.color_1 {
      background-color: #110e9e;
      & h4 {
        color: #fff;
      }
      & svg {
        opacity: 1;
      }
    }
    &.color_2 {
      background-color: #ff8f5f;
      & h4 {
        color: #fff;
      }
      & svg {
        opacity: 1;
      }
    }
    &.color_3 {
      background-color: #f7e481;
      & h4 {
        color: #fff;
      }
      & svg {
        opacity: 1;
      }
    }
  }
`;
export const HistoryTabs = styled(Tabs)`
  display: flex;
  width: 100%;
  min-height: 120px !important;
  justify-content: flex-end;
  position: relative;
  margin: 50px 0;
  &::after {
    display: block;
    width: 90%;
    content: "";
    border-bottom: 2px solid #fff;
    position: absolute;
    bottom: 9px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 0;
    @media (max-width: 431px) {
      width: 75%;
    }
  }
  & .MuiTabs-indicator {
    display: none;
  }
  & .MuiTabScrollButton-root {
    align-self: flex-end;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
`;
export const HistoryTab = styled(Tab)`
  display: flex;
  flex-direction: column;
  height: 120px;
  width: calc(100% / 9);
  max-width: unset !important;
  align-items: center;
  justify-content: space-between !important;
  padding: 0 !important;
  gap: 20px;
  font-weight: 600;
  color: #fff !important;
  position: relative;
  & .MuiTouchRipple-root {
    display: none;
  }
  &.Mui-selected {
    color: #ff7f37 !important;

    &::after {
      background-color: #ff7f37;
    }
  }
  &::after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    z-index: 1;
  }

  &::before {
    content: "";
    display: block;
    height: calc(100% - 50px);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    border-left: 1px dashed #ffffff80;
  }
`;

export const HistoryContent = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 100%;
  color: #ffffff;
  text-align: center;
  & .row-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  & p {
    width: 60%;
  }
  & .logowrapper {
    width: 100px;
    height: 100px;
    background-color: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      position: relative;
    }

    & h4 {
      color: #07146380;
      font-size: 50px;
    }
  }

  & h5 {
    position: relative;
    font-weight: 500;
    &::after {
      content: "";
      height: 6px;
      width: 20px;
      position: absolute;
      bottom: -10px;
      background-color: #ffffff33;
      left: 50%;
      translate: -50% 0;
    }
  }
`;
