import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as AnthonyLogo } from "../../../resources/icons/portfolio/anthony_icon.svg";
import { ReactComponent as HWLLogo } from "../../../resources/icons/portfolio/hwl_icon.svg";
import IlhaPneuLogo from "../../../resources/icons/portfolio/ilhapneu_icon.png";
import { PortfolioCard } from "../styles/portfolioStyles";
// import { ReactComponent as NMDSLogo } from "../../../resources/icons/portfolio/nmds-icon.svg";
import EventplaceLogo from "../../../resources/icons/portfolio/eventplace_icon.svg";
import { ReactComponent as GovgoLogo } from "../../../resources/icons/portfolio/govgo_icon.svg";
import { ReactComponent as InventoryLogo } from "../../../resources/icons/portfolio/inventory_icon.svg";
import MadeiraSafeLogo from "../../../resources/icons/portfolio/madeirasafe_icon.png";
import { ReactComponent as MyehealthLogo } from "../../../resources/icons/portfolio/myehealth_icon.svg";
import NMDSLogoPng from "../../../resources/icons/portfolio/nmds_icon.png";
import PneuzarcoLogo from "../../../resources/icons/portfolio/pneuzarco.png";
import PoderLogo from "../../../resources/icons/portfolio/poder_icon.png";
import { ReactComponent as SafeLogo } from "../../../resources/icons/portfolio/safe_icon.svg";
import { ReactComponent as SeeappLogo } from "../../../resources/icons/portfolio/seeapp_icon.svg";
import SeehealthLogo from "../../../resources/icons/portfolio/seehealth_icon.png";
import { ReactComponent as VCapital } from "../../../resources/icons/portfolio/vcapital-icon.svg";
import { ReactComponent as VipaconnectLogo } from "../../../resources/icons/portfolio/vipaconnect_icon.svg";
import { ReactComponent as VipaenergyLogo } from "../../../resources/icons/portfolio/vipaenergy_icon.svg";
import { ReactComponent as VipainfinityLogo } from "../../../resources/icons/portfolio/vipainfinity_icon.svg";
import { ReactComponent as VipainternationalLogo } from "../../../resources/icons/portfolio/vipainternational_icon.svg";
import { ReactComponent as VipainvoiceLogo } from "../../../resources/icons/portfolio/vipainvoice_icon.svg";
import { ReactComponent as VipalabLogo } from "../../../resources/icons/portfolio/vipalab_icon.svg";
import { ReactComponent as VipaskyLogo } from "../../../resources/icons/portfolio/vipasky_icon.svg";
import VipatelecomLogo from "../../../resources/icons/portfolio/vipatelecom_icon.png";
import { ReactComponent as BiomaskLogo } from "../../../resources/images/portfolio/biomask-logo.svg";
import BioReboot from "../../../resources/images/portfolio/bioreboot-logo.png";

export function Card({ company }) {
  const [brand, setBrand] = useState(null);
  const [brandname, setBrandName] = useState(null);
  const [slogan, setSlogan] = useState(null);
  const [text, setText] = useState(null);
  const [link, setLink] = useState(null);
  const [logo, setLogo] = useState(null);
  const [header, setHeader] = useState(null);
  const [colorbg, setColorbg] = useState(null);
  const [bgimg, setBGIMG] = useState(null);
  const { t, i18n } = useTranslation();
  function HandleBrand(brand) {
    switch (brand) {
      case "bioreboot":
        setColorbg("#1C3A1E");
        setBrandName("BioReboot");
        setSlogan(t("portfolio_tab10_sub"));
        setText(t("portfolio_tab10_text1"));
        // setLink("https://www.vipaconnect.com/");
        break;
      case "vipaconnect":
        setColorbg("#7790ED");
        setBrandName("Vipa.pt");
        setSlogan("Laboratório de software");
        setText(t("portfolio_tab1_text"));
        setLink("https://www.vipa.pt/");
        break;
      case "vipalab":
        setColorbg("#7790ED");
        setBrandName("Vipa Lab");
        setSlogan("Be Innovative");
        setText(t("portfolio_tab1_text2"));
        setLink("https://www.vipalab.eu/");
        break;

      case "anthony":
        setColorbg("#F089A4");
        setBrandName("St. Anthony");
        setText(t("portfolio_tab8_text1"));
        setLink("");
        break;

      case "biomask":
        setColorbg("#6DC1E8");
        setBrandName("The Biomask Company");
        setText(t("portfolio_tab2_text1"));
        setLink("https://www.thebiomaskcompany.com/");
        break;

      case "hwl":
        setColorbg("#D4363C");
        setBrandName("Homes With Love");
        setSlogan("Invista numa Casa com Amor");
        setText(t("portfolio_tab9_text1"));
        setLink("https://www.hwl.pt/");
        break;

      case "nmds":
        setColorbg("#FBC65F");
        setBrandName("Números Distintos");
        setSlogan(t("portfolio_tab6_sub"));
        setText(t("portfolio_tab6_text1"));
        setLink("https://www.nmds.pt/");
        break;

      case "ilhapneu":
        setLogo(IlhaPneuLogo);

        setColorbg("#A3E294");
        setBrandName("Ilha Pneu");
        setSlogan(t("portfolio_tab4_sub"));
        setText(t("portfolio_tab4_text1"));
        setLink("https://www.ilhapneu.pt/");
        break;

      case "pneuzarco":
        setColorbg("linear-gradient(48.4deg, #5FD4BB 0%, #A3E294 100%)");
        setBrandName("Pneuzarco");
        setSlogan(t("portfolio_tab3_sub"));
        setText(t("portfolio_tab3_text2"));
        setLink("https://www.pneuzarco.pt/");
        break;

      case "poder":
        setLogo(PoderLogo);

        setColorbg("#FBC65F");
        setBrandName("Poder & Negócios Publicações");
        setText(t("portfolio_tab6_text2"));
        setLink("");
        break;

      case "seehealth":
        setColorbg("linear-gradient(48.4deg, #7790ED 0%, #6DC1E8 100%)");
        setBrandName("See Health");
        setSlogan("Connecting your health");
        setText(t("portfolio_tab1_text4"));
        setLink("https://www.seehealth.pt/");
        break;

      case "vipaenergy":
        setColorbg("#5FD4BB");
        setBrandName("Vipa Energy");
        setSlogan("In motion for the future");
        setText(t("portfolio_tab3_text1"));
        setLink("https://www.vipaenergy.com/");
        break;

      case "vipainternational":
        HandleLogo(brand);

        setColorbg("#F6EC8A");
        setBrandName("Vipa International");
        setSlogan("Moving you Forward");
        setText(t("portfolio_tab5_text1"));
        setLink("https://www.vipainternational.com/");
        break;

      case "vipasky":
        setColorbg("#7790ED");
        setBrandName("Vipa Sky");
        setText(t("portfolio_tab1_text3"));
        setLink("");
        break;

      case "vipatelecom":
        setLogo(VipatelecomLogo);
        setColorbg("#FF8F5F");
        setBrandName("Vipa Telecom");
        setSlogan("Stay Connected");
        setText(t("portfolio_tab7_text1"));
        setLink("https://www.vipatelecom.com/");
        break;

      case "ps":
        setHeader("PS");
        setColorbg("#F6EC8A");
        setBrandName("Pesquisa Subtil");
        setText(t("portfolio_tab5_text2"));
        setLink("");
        break;

      case "rj":
        setHeader("RJ");
        setColorbg("linear-gradient(48.4deg, #F6EC8A 0%, #FBC65F 100%)");
        setBrandName("Ritmo Janota");
        setText(t("portfolio_tab5_text3"));
        setLink("");
        break;

      case "oe":
        setHeader("OE");
        setColorbg("#FBC65F");
        setBrandName("Objetivo Expectável");
        setText(t("portfolio_tab6_text3"));
        setLink("");
        break;
      case "madeirasafe":
        setLogo(MadeiraSafeLogo);

        setColorbg("#110E9E");
        setBrandName("Madeira Safe");
        setSlogan(t("portfolio_product1_sub"));
        setText(t("portfolio_product1_text"));
        setLink("");
        break;
      case "govgo":
        setColorbg("#110E9E");
        setBrandName("govgo");
        setSlogan(t("portfolio_product2_sub"));
        setText(t("portfolio_product2_text"));
        setLink("https://www.govgo.pt/");
        break;
      case "vipainvoice":
        setColorbg("#110E9E");
        setBrandName("VipaInvoice");
        setText(t("portfolio_product3_text"));
        setLink("https://www.vipainvoice.com/");
        break;
      case "vipainfinity":
        setColorbg("#110E9E");
        setBrandName("VipaInfinity");
        setText(t("portfolio_product4_text"));
        setLink("https://vipainfinity.com/");
        break;
      case "eventplace":
        setLogo(EventplaceLogo);
        setColorbg("#110E9E");
        setBrandName("Event Place");
        setText(t("portfolio_product5_text"));
        setLink("");
        setBGIMG(true);
        break;
      case "inventario":
        // setLogo(InventoryLogo);
        setColorbg("#110E9E");
        setBrandName("Patrigest");
        setText(t("portfolio_product6_text"));
        setLink("https://www.patrigest.pt/");
        // setBGIMG(true);
        break;
      case "myehealth":
        setColorbg("#110E9E");
        setBrandName("My eHealth");
        setSlogan(t("portfolio_project1_sub"));
        setText(t("portfolio_project1_text"));
        setLink("https://www.myehealth.pt/");
        break;
      case "seeapp":
        setColorbg("#110E9E");
        setBrandName("See App");
        setSlogan(t("portfolio_project2_sub"));
        setText(t("portfolio_project2_text"));
        setLink("https://www.seeapp.pt/");
        break;
      case "biomaskapp":
        setColorbg("#110E9E");
        setBrandName("BioMASK");
        setText(t("portfolio_project3_text"));
        setLink("https://www.thebiomaskcompany.com/");
        break;
      case "safe":
        setColorbg("#110E9E");
        setBrandName("Safe Football Entrance");
        setText(t("portfolio_project4_text"));
        setLink("https://www.futsafe.com/");
        break;
      case "vcapital":
        setColorbg("#5FD4BB");
        setBrandName("V Capital");
        setSlogan(t("portfolio_project5_sub"));
        setText(t("portfolio_project5_text"));
        setLink("https://www.vcapital.pt");
        break;

      default:
        break;
    }
  }
  function HandleLogo(brand) {
    switch (brand) {
      case "vipaconnect":
        return <VipaconnectLogo width={"305px"} />;
      case "vipalab":
        return <VipalabLogo />;
      case "pneuzarco":
        return <img src={PneuzarcoLogo} alt="pneuzarco" />;
      case "anthony":
        return <AnthonyLogo />;
      case "biomask":
        return <BiomaskLogo style={{ width: "80%" }} />;
      case "hwl":
        return <HWLLogo />;
      case "nmds":
        return <img src={NMDSLogoPng} alt="NMDS logo" />;
      case "seehealth":
        return (
          <img
            src={SeehealthLogo}
            style={{ width: "80%" }}
            alt="seehealth logo"
          />
        );
      case "vipaenergy":
        return <VipaenergyLogo />;

      case "vipainternational":
        return <VipainternationalLogo />;

      case "vipasky":
        return <VipaskyLogo />;
      case "govgo":
        return <GovgoLogo />;

      case "vipainvoice":
        return <VipainvoiceLogo />;

      case "inventario":
        return <InventoryLogo />;

      case "vipainfinity":
        return <VipainfinityLogo />;

      case "myehealth":
        return <MyehealthLogo />;

      case "seeapp":
        return <SeeappLogo />;

      case "biomaskapp":
        return <BiomaskLogo style={{ width: "80%" }} />;

      case "safe":
        return <SafeLogo />;

      case "vcapital":
        return <VCapital />;

      case "bioreboot":
        return (
          <img src={BioReboot} alt="BioReboot logo" style={{ width: "80%" }} />
        );

      default:
        break;
    }
  }
  useEffect(() => {
    setBrand(company);
    HandleBrand(brand);
    // eslint-disable-next-line
  }, [company, brand]);

  useEffect(() => {
    HandleBrand(brand);
    // eslint-disable-next-line
  }, [i18n.language, brand]);
  return (
    <PortfolioCard color={colorbg}>
      <div className="face face1">
        {bgimg ? (
          <img src={logo} alt="" />
        ) : (
          <div className="content">
            {header ? (
              <h3>{header}</h3>
            ) : logo ? (
              <img src={logo} alt="" />
            ) : (
              HandleLogo(company)
            )}
          </div>
        )}
        <div className="face face2">
          <div className="content">
            <div className="header">
              <h4>{brandname}</h4>
              {slogan && <h5>{slogan}</h5>}
            </div>
            <p>{text}</p>
            {link ? (
              <a href={link} target="_blank" rel="noreferrer">
                {t("visit_website")}
              </a>
            ) : (
              <p className="link">{t("website_under_maintenance")}</p>
            )}
          </div>
        </div>
      </div>
    </PortfolioCard>
  );
}
