import "./App.css";
import Layout from "./resources/components/layout";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./routes/home/home";
import NavBar from "./resources/components/navbar";
import CookiesConsent from "./resources/components/cookieconsent";
import Footer from "./resources/components/footer";
import About from "./routes/about/about";
import History from "./routes/about/history";
import Mission from "./routes/about/mission";
import Contribution from "./routes/about/contribution";
import Government from "./routes/government/government";
import Structure from "./routes/government/structure";
import ScrollToTop from "./resources/components/scrolltop";
import Regulations from "./routes/government/regulations";
import Portfolio from "./routes/portfolio/portfolio";
import People from "./routes/people/people";
import Contacts from "./routes/contacts/contacts";
import PrivacyPolicies from "./routes/policies";
import Denuncias from "./routes/policies/denuncias";
import NewsPage from "./routes/home/newsPage";

function App() {
  return (
    <Layout>
      <Router>
        <ScrollToTop />
        <NavBar />
        <CookiesConsent />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/about/history" element={<History />} />
          <Route path="/about/mission" element={<Mission />} />
          <Route path="/about/contribution" element={<Contribution />} />
          <Route path="/government" element={<Government />} />
          <Route path="/government/structure" element={<Structure />} />
          <Route path="/government/regulations" element={<Regulations />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/people" element={<People />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/privacy" element={<PrivacyPolicies />} />
          <Route path="/denuncias" element={<Denuncias />} />
          <Route path="/news" element={<NewsPage />} />
        </Routes>
        <Footer />
      </Router>
    </Layout>
  );
}

export default App;
