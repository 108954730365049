import React from "react";
import {
  Caption,
  Wrapper,
  PageHero,
  Parallax,
  PageBackground,
  RowBetween,
} from "./styles/aboutStyles";

import AboutBG from "../../resources/images/about/about_hero.png";
import Circle from "../../resources/images/hero/circle.svg";
import CircleLeft from "../../resources/images/calltoaction/circle_left.svg";
import CircleRight from "../../resources/images/calltoaction/circle_right.svg";
import Blob from "../../resources/images/calltoaction/blob.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Link } from "react-router-dom";
import { BackgroundWrapper, CenteredContent } from "../home/styles/home_styled";
import { useTranslation } from "react-i18next";

function About() {
  const {
    t,
    i18n: { language: activeLang },
  } = useTranslation();

  /* <TeamSection>
            <h2 className="header">A Equipa</h2>
            <TeamWrapper>
              <TeamCard>
                <div className="imgwrapper">
                  <img src={Person1} alt="" />
                </div>
                <h4>Nome Apelido</h4>
                <p>Cargo</p>
              </TeamCard>
              <TeamCard>
                <div className="imgwrapper">
                  <img src={Person1} alt="" />
                </div>
                <h4>Nome Apelido</h4>
                <p>Cargo</p>
              </TeamCard>
              <TeamCard>
                <div className="imgwrapper">
                  <img src={Person1} alt="" />
                </div>
                <h4>Nome Apelido</h4>
                <p>Cargo</p>
              </TeamCard>
            </TeamWrapper>
          </TeamSection> */

  return (
    <>
      <PageHero>
        <Parallax image={AboutBG} />
        <Wrapper>
          <Caption>
            <h1>{t("about_hero")}</h1>
          </Caption>
        </Wrapper>
      </PageHero>
      <PageBackground>
        <img
          src={Circle}
          style={{ left: "-50%", top: "0", width: "70%" }}
          alt=""
        />
        <img src={Circle} style={{ right: "-30%", top: "-10%" }} alt="" />
        <Wrapper>
          <RowBetween style={{ marginBottom: "50px" }}>
            <div className="text">
              <h2 className="header">{t("who")}</h2>
              <p>{t("who_text1")}</p>
              <p>{t("who_text2")}</p>
              <p>{t("who_text3")}</p>
              <p>{t("who_text4")}</p>
              <p>{t("who_text5")}</p>
              <p className="text_small">{t("who_pdf")}</p>
              <a
                href={
                  activeLang === "pt"
                    ? "https://vipacorp-docs.s3.eu-central-1.amazonaws.com/dossier.pdf"
                    : "https://vipacorp-docs.s3.eu-central-1.amazonaws.com/dossier_en.pdf"
                }
                target="_blank"
                className="pdf_file"
                rel="noreferrer noopenner"
                alt="Dossier de Apresentação PDF"
              >
                <FileCopyIcon />
                {t("who_pdf_link")}
              </a>
            </div>
            <div className="navigation">
              <Link
                to={"history"}
                className="item"
                onClick={() => this.forceUpdate}
              >
                {t("history")} <ArrowForwardIosIcon />
              </Link>
              <Link
                to={"mission"}
                className="item"
                onClick={() => this.forceUpdate}
              >
                {t("mission")} <ArrowForwardIosIcon />
              </Link>
              <Link
                to={"contribution"}
                className="item"
                onClick={() => this.forceUpdate}
              >
                {t("contribution")} <ArrowForwardIosIcon />
              </Link>
            </div>
          </RowBetween>
        </Wrapper>
        <BackgroundWrapper hide={true} className="calltoaction">
          <img
            src={CircleLeft}
            alt=""
            style={{ top: "-50%", left: "0" }}
            className={"hide"}
          />
          <img
            src={Blob}
            alt=""
            style={{ top: "-10%", left: "10%" }}
            className={"hide"}
          />
          <img src={CircleRight} alt="" style={{ top: "0", right: "0" }} />
          <Wrapper>
            <CenteredContent className="calltoaction">
              <h3 style={{ fontWeight: 500, color: "#fff" }}>
                {t("who_banner")}
              </h3>
            </CenteredContent>
          </Wrapper>
        </BackgroundWrapper>
      </PageBackground>
    </>
  );
}

export default About;
